/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { FlexboxContainer, FlexboxItem, TextField, Scrollable, MultiList, DatePicker } from 'ui-components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import KeyValueInput from '../../../../components/keyValueInput/KeyValueInput';
import { getMultiTermText, getDataForDependentFeatures, flattenMultiList } from '../../../../utils/commonUtils';
import './TrialPeriodsAccordion.css';
import {
    ITrialPeriod,
    ITrialPeriodAccordionData,
    ITrialPeriodsPutData
} from '../../../../@types/trialPeriods';
import { getDependentFeatures } from '../../../Features/slice/Feature';
import { IMultiListArrayType } from '../../../../@types/customers';
import { IFeaturesData } from '../../../../@types/features';
import { patchTrialPeriod, removeErrorMessages } from '../../slice';

interface ITrialPeriodsAccordion {
    trialPeriodData: ITrialPeriod;
}

const TrialPeriodsAccordion = ({ trialPeriodData }: ITrialPeriodsAccordion) => {
    const dispatch = useAppDispatch();
    const { dependentFeaturesData } = useAppSelector((state) => state.features);
    const { errorMessages } = useAppSelector((state) => state.trialPeriods);
    const [accordionData, setAccordionData] = useState<ITrialPeriodAccordionData>();
    const [childDependentIds] = useState<Array<number>>([]);

    useEffect(() => {
        dispatch(removeErrorMessages(['validFrom', 'validTo']))
        if (dependentFeaturesData.length === 0) {
            dispatch(getDependentFeatures());
        }
    }, []);

    useEffect(() => {
        if (trialPeriodData.productionSiteId) {
            setAccordionData(trialPeriodData);
        }
    }, [trialPeriodData]);

    const updateAccordionData = <T,>(value: T, name: string) => {
        setAccordionData((prev) => {
            let tempAccordionData: ITrialPeriodAccordionData = { ...prev };
            if (tempAccordionData) {
                tempAccordionData = {
                    ...tempAccordionData,
                    [name]: value
                };
            }
            const payload: ITrialPeriodsPutData = {
                ...tempAccordionData,
                features: tempAccordionData.features?.map((feature) => feature.id),
                existingValidFrom: trialPeriodData.validFrom,
                existingValidTo: trialPeriodData.validTo
            };
            dispatch(patchTrialPeriod(payload));
            return tempAccordionData;
        });
    };

    const handleDateChange = (selectedDate: string, name: string) => updateAccordionData(selectedDate, name);
    const onHandleMultiListChange = (data: Array<IMultiListArrayType>) => {
        const selectedFeatures = flattenMultiList(data)
            .filter((eachData) => eachData.checked)
            .map<IFeaturesData>((filteredData) => ({
                id: filteredData.id, // Only id is relevant here
                sapId: '',
                name: '',
                dependentOn: [],
                description: '',
                enabled: true
            }));
        updateAccordionData(selectedFeatures, 'features');
    };

    return (
        <FlexboxContainer className="Accordion_wrapper Full_width" justifyContent="start" alignItems="stretch">
            <FlexboxItem className="Each_side_wrapper" flexGrow="1">
                <FlexboxContainer flexDirection="column" justifyContent="start" alignItems="start" gap="0.625rem">
                    <FlexboxItem className="Full_width">
                        <KeyValueInput
                            title={getMultiTermText({ id: '99032599', text: 'Production site ID' })}
                            isTitleRequired
                            value={
                                <TextField
                                    disabled
                                    inPlaceEdit
                                    className="Full_width"
                                    value={accordionData?.productionSiteId}
                                />
                            }
                        />
                    </FlexboxItem>
                    <FlexboxItem className="Full_width">
                        <KeyValueInput
                            title={getMultiTermText({ id: '9000326', text: 'Customer' })}
                            value={
                                <TextField
                                    disabled
                                    inPlaceEdit
                                    className="Full_width"
                                    value={accordionData?.customerName}
                                />
                            }
                        />
                    </FlexboxItem>
                    <FlexboxItem className="Full_width">
                        <KeyValueInput
                            title={getMultiTermText({ id: '99025706', text: 'Production site' })}
                            value={
                                <TextField
                                    disabled
                                    inPlaceEdit
                                    className="Full_width"
                                    value={accordionData?.productionSiteName}
                                />
                            }
                        />
                    </FlexboxItem>
                    <FlexboxItem className="Full_width">
                        <KeyValueInput
                            title={getMultiTermText({ id: '99032450', text: 'Machine serial number' })}
                            value={
                                <TextField
                                    disabled
                                    inPlaceEdit
                                    className="Full_width"
                                    value={accordionData?.machineSerial}
                                />
                            }
                        />
                    </FlexboxItem>
                    {accordionData?.validTo && accordionData?.validFrom && (
                        <>
                            <FlexboxItem className="Full_width">
                                <KeyValueInput
                                    title={getMultiTermText({ id: '99032451', text: 'Available from' })}
                                    value={
                                        <>
                                            <DatePicker
                                                isError
                                                errorText="invalid date"
                                                onChange={(selectedDate: string) =>
                                                    handleDateChange(selectedDate, 'validFrom')
                                                }
                                                userpassedDate={accordionData?.validFrom}
                                            />
                                            <span className="Error_Text">{errorMessages.validFrom}</span>
                                        </>
                                    }
                                />
                            </FlexboxItem>
                            <FlexboxItem className="Full_width">
                                <KeyValueInput
                                    title={getMultiTermText({ id: '99032452', text: 'Available to' })}
                                    value={
                                        <FlexboxContainer flexDirection="column" alignItems="flex-start" gap="0.196rem">
                                            <DatePicker
                                                onChange={(selectedDate: string) =>
                                                    handleDateChange(selectedDate, 'validTo')
                                                }
                                                userpassedDate={accordionData?.validTo}
                                            />
                                            <span className="Error_Text">{errorMessages.validTo}</span>
                                        </FlexboxContainer>
                                    }
                                />
                            </FlexboxItem>
                        </>
                    )}
                </FlexboxContainer>
            </FlexboxItem>
            <FlexboxItem className="Vertical_separator" />
            <FlexboxItem className="Each_side_wrapper" flexGrow="1">
                {dependentFeaturesData.length > 0 && (
                    <Scrollable>
                        <MultiList
                            onChange={onHandleMultiListChange}
                            title={getMultiTermText({ id: '99032454', text: 'Available features' })}
                            showToggleForSelectedOnly
                            data={getDataForDependentFeatures(
                                dependentFeaturesData,
                                trialPeriodData.features.map((feature) => feature.id),
                                trialPeriodData?.productionSiteId,
                                childDependentIds
                            )}
                        />
                    </Scrollable>
                )}
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default TrialPeriodsAccordion;
