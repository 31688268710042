/* eslint-disable camelcase */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiBody, MimeTypes } from 'lisecutilityfunctions/lib/openapi';

import { IFeaturesData } from '../../../@types/features';
import { IDependentFeatures } from '../../../@types/customers';
import { IErrorState, setError } from '../../../slice/ErrorHandling';
import OpenApiClient_customer_management_v2 from '../../../api/openapiclient_customer_management_v2';
import { modalType } from '../../../utils/commonUtils';

export interface IFeaturesInitialState {
    isLoading: boolean;
    featuresData: Array<IFeaturesData>;
    featureModalType: string;
    featureModalData: IFeaturesData;
    dependentFeaturesData: Array<IDependentFeatures>;
    latestFeatureId: number;
    featureIdToDelete: number;
    multitermId?: number;
    postFeatureResponseData: any
}

const initialModalData = {
    sapId: '',
    name: '',
    description: '',
    commercial: false,
    dependentOn: [],
    enabled: true,
    id: 0
};

const initialState: IFeaturesInitialState = {
    isLoading: false,
    featuresData: [],
    featureModalType: modalType.CLOSED,
    featureModalData: initialModalData,
    dependentFeaturesData: [],
    latestFeatureId: 0,
    featureIdToDelete: 0,
    postFeatureResponseData:{}
};

/**
 * @function getFeatures
 * @description Function to fetch all features list
 */
export const getFeatures = createAsyncThunk('getFeatures', async (_, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_Features();
        if (response?.status === 204) {
            return [];
        }
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data);
        return responseData;
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in getFeatures '
        };
        return dispatch(setError(errorValue));
    }
});

/**
 * @function getDependentFeatures
 * @description Function to fetch all getDependentFeatures list
 */
export const getDependentFeatures = createAsyncThunk('getDependentFeatures', async (_, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_Features_DependentFeatures();
        if (response?.status === 204) {
            return [];
        }
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data);
        return responseData;
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in getFeatures '
        };
        return dispatch(setError(errorValue));
    }
});

/**
 * @function getLatestFeatureId
 * @description Function to fetch all features list
 */
export const getLatestFeatureId = createAsyncThunk('getLatestFeatureId', async (_, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_Features(null, 1, null, '-id', [
            'id'
        ]);
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data)[0].id;
        return responseData;
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in getFeatures '
        };
        return dispatch(setError(errorValue));
    }
});

/**
 * @function postFeature
 * @description Function to do post feature
 */
export const postFeature = createAsyncThunk('postFeature', async (postFeatureBody: IFeaturesData, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).POST_Features(
            new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, postFeatureBody)
        );
        if (response?.status !== 201) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data);
        dispatch(getDependentFeatures());
        dispatch(getFeatures());
        return responseData;
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in getFeatures '
        };
        return dispatch(setError(errorValue));
    }
});

/**
 * @function putFeature
 * @description Function to put feature
 */
export const putFeature = createAsyncThunk(
    'putFeature',
    async ({ body, featureId }: { body: IFeaturesData; featureId: string }, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).PUT_Features_id(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, body),
                featureId
            );
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            dispatch(getDependentFeatures());
            return dispatch(getFeatures());
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'Error in getFeatures '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function deleteFeature
 * @description Function to delete feature
 */
export const deleteFeature = createAsyncThunk('deleteFeature', async (featureId: number, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).DELETE_Features_id(featureId);
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        dispatch(getDependentFeatures());
        return dispatch(getFeatures());
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'Error in getFeatures '
        };
        return dispatch(setError(errorValue));
    }
});

/**
 * @function patchFeature
 * @description Function to patch feature
 */
export const patchFeature = createAsyncThunk(
    'patchFeature',
    async ({ body, featureId }: { body: any; featureId: number }, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).PATCH_Features_id(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, body),
                featureId
            );
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            dispatch(getDependentFeatures());
            return dispatch(getFeatures());
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'Error in getFeatures '
            };
            return dispatch(setError(errorValue));
        }
    }
);

const featuresSlice = createSlice({
    name: 'features',
    initialState,
    reducers: {
        setFeatureModalType: (state, action: PayloadAction<string>) => {
            state.featureModalType = action.payload;
            if (action.payload === modalType.CLOSED) {
                state.featureModalData = initialModalData;
            }
        },

        setFeatureModalData: (state, action: PayloadAction<any>) => {
            state.featureModalData = action.payload;
        },
        clearPostFeatureResponseData: (state, action: PayloadAction<any>) => {
            state.postFeatureResponseData = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getFeatures.pending, (state: IFeaturesInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(
            getFeatures.fulfilled,
            (state: IFeaturesInitialState, action: PayloadAction<Array<IFeaturesData>>) => {
                state.isLoading = false;
                state.featuresData = action.payload;
            }
        );
        builder.addCase(getFeatures.rejected, (state: IFeaturesInitialState) => {
            state.isLoading = false;
        });

        builder.addCase(getDependentFeatures.pending, (state: IFeaturesInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(
            getDependentFeatures.fulfilled,
            (state: IFeaturesInitialState, action: PayloadAction<Array<IDependentFeatures>>) => {
                state.isLoading = false;
                state.dependentFeaturesData = action.payload;
            }
        );
        builder.addCase(getDependentFeatures.rejected, (state: IFeaturesInitialState) => {
            state.isLoading = false;
        });

        builder.addCase(getLatestFeatureId.pending, (state: IFeaturesInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(getLatestFeatureId.fulfilled, (state: IFeaturesInitialState, action: PayloadAction<number>) => {
            state.isLoading = false;
            state.latestFeatureId = action.payload;
        });
        builder.addCase(getLatestFeatureId.rejected, (state: IFeaturesInitialState) => {
            state.isLoading = false;
        });

        builder.addCase(postFeature.pending, (state: IFeaturesInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(postFeature.fulfilled, (state: IFeaturesInitialState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.postFeatureResponseData = action.payload
            state.featureModalType = modalType.CLOSED;
            state.featureModalData = initialModalData;
            state.dependentFeaturesData = [];
        });
        builder.addCase(postFeature.rejected, (state: IFeaturesInitialState) => {
            state.isLoading = false;
        });

        builder.addCase(putFeature.pending, (state: IFeaturesInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(putFeature.fulfilled, (state: IFeaturesInitialState) => {
            state.isLoading = false;
            state.featureModalType = modalType.CLOSED;
            state.featureModalData = initialModalData;
        });
        builder.addCase(putFeature.rejected, (state: IFeaturesInitialState) => {
            state.isLoading = false;
        });

        builder.addCase(patchFeature.pending, (state: IFeaturesInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(patchFeature.fulfilled, (state: IFeaturesInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(patchFeature.rejected, (state: IFeaturesInitialState) => {
            state.isLoading = false;
        });

        builder.addCase(deleteFeature.pending, (state: IFeaturesInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(deleteFeature.fulfilled, (state: IFeaturesInitialState) => {
            state.isLoading = false;
            state.featureModalType = modalType.CLOSED;
            state.featureIdToDelete = 0;
        });

        builder.addCase(deleteFeature.rejected, (state: IFeaturesInitialState) => {
            state.isLoading = false;
        });
    }
});

export const { setFeatureModalType, setFeatureModalData, clearPostFeatureResponseData} = featuresSlice.actions;
export default featuresSlice.reducer;
