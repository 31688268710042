export const accordionLevel1 = '1';
export const accordionLevel2 = '2';
export const accordionLevel3 = '3';

export const featureType = 'features';
export const customerType = 'customers';
export const productType = 'Products';
export const trialPeriodsType = 'TrialPeriods';
export const loginHistoryType = 'LoginHistoryType';
export const hardlocks = 'Hardlocks';
export const hardlockTypes = 'HardlockTypes';
