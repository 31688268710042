import { getMultiTermText, getLabelOptions } from '../../../utils/commonUtils';

export default [
    {
        _key: 'name',
        label: getMultiTermText({ id: '99002657', text: 'Short name' }),
        searching: true,
        sorting: true,
        width: '35%',
        isEditable: true,
        columnDataClassName: 'Font_bold'
    },
    {
        _key: 'description',
        label: getMultiTermText({ id: '99018122', text: 'Name' }),
        searching: true,
        sorting: true,
        width: '35%',
        isEditable: true,
    },
    {
        _key: 'status',
        label: getMultiTermText({ id: '3000144', text: 'Status' }),
        searching: false,
        sorting: true,
        width: '15%',
        isEditable: true,
        _component: 'label',
        filterOptions: [
            {
                id: 1,
                name: getMultiTermText({ id: '99000784', text: 'Enabled' })
            },
            {
                id: 2,
                name: getMultiTermText({ id: '99000809', text: 'Disabled' })
            }
        ],
        labelOptions: [
            {
                id: 1,
                text: getLabelOptions({ id: '99000784', text: 'Enabled', className: 'Status_green' })
            },
            {
                id: 2,
                text: getLabelOptions({ id: '99000809', text: 'Disabled', className: 'Status_gray' })
            }
        ]
    },
    {
        _key: 'id',
        label: getMultiTermText({ id: '99008287', text: 'ID#' }),
        searching: true,
        sorting: true,
        width: '15%',
        isEditable: false,
    }
];
