import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SubOptionListComponent } from 'ui-components';
import { getMultiTermText } from '../../../../utils/commonUtils';

interface ITableOptions {
    onClickEdit: () => void;
}

const ProductTableOptions = ({ onClickEdit }: ITableOptions) => {
    const optionList = {
        list: [
            {
                title: getMultiTermText({ text: 'Edit', id: '99006184' }),
                click: onClickEdit,
                key: 'edit'
            },
        ]
    };
    return <SubOptionListComponent optionList={optionList} />;
};

export default ProductTableOptions;
