/* eslint-disable react/require-default-props */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SubOptionListComponent } from 'ui-components';
import { getMultiTermText } from '../../../../utils/commonUtils';

interface ITableOptions {
    onClickEdit: () => void;
}

const TrialPeriodsTableOptions = ({ onClickEdit }: ITableOptions) => {
    const optionList = {
        list: [
            {
                title: getMultiTermText({ text: 'Edit', id: '99006184' }),
                click: onClickEdit,
                key: 'edit'
            }
        ]
    };
    return <SubOptionListComponent optionList={optionList} />;
};

export default TrialPeriodsTableOptions;
