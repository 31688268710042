/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import { FlexboxContainer, FlexboxItem, Typography, TextField, DatePicker } from 'ui-components';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { modalType } from '../../../../utils/commonUtils';
import { setApiTokenModalData } from '../../slice/index';
import '../../../../styles/global.css';
import './ApiTokenModal.css';

const ApiTokenModal = () => {
    const { apiTokenModalType, apiTokenModalData, selectedCustomerProductionSites } = useAppSelector(
        (state) => state.customers
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (apiTokenModalType === modalType.ADD_VERSION) {
            let tempModalData = { ...apiTokenModalData };
            tempModalData = {
                ...tempModalData,
                [tempModalData.idp]: tempModalData.idp ? tempModalData.idp : 'lisec.com'
            };
            dispatch(setApiTokenModalData(tempModalData));
        }
    }, []);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempModalData = { ...apiTokenModalData };
        tempModalData = {
            ...tempModalData,
            [event.target.name]: event.target.value
        };
        dispatch(setApiTokenModalData(tempModalData));
    };

    const onChangeDatePicker = (value: string) => {
        const convertedDateFormat = conversionLib.dateTimeFormatConversion(value, 'datetime', 'isoUtcDateTime');
        const tempObj = { ...apiTokenModalData };
        tempObj.expires = convertedDateFormat;
        dispatch(setApiTokenModalData(tempObj));
    };

    return (
        <FlexboxContainer flexDirection="column" alignItems="start">
            <FlexboxItem className="ApiToken_popup_header_block">
                <Typography type="H1">
                    {apiTokenModalType === modalType.ADD_VERSION ? (
                        <LiMultiterm textId="99033590" textVal="Add API Token Version" />
                    ) : (
                        <LiMultiterm textId="99030963" textVal="Add API Token" />
                    )}
                </Typography>
            </FlexboxItem>
            <FlexboxItem className="Main_seprator Full_width" />
            <FlexboxItem>
                <FlexboxContainer alignItems="start" justifyContent="start">
                    <FlexboxContainer
                        flexDirection="column"
                        alignItems="start"
                        className="ApiToken_popup_component_wrapper_left"
                    >
                        <FlexboxItem className="ApiToken_popup_component_textfield_wrapper">
                            <TextField
                                className="Name_input"
                                title={<LiMultiterm textId="99018122" textVal="Name" />}
                                isRequired
                                disabled={apiTokenModalType === modalType.ADD_VERSION}
                                onChange={handleOnChange}
                                name="name"
                                errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                                value={apiTokenModalData?.name}
                            />
                        </FlexboxItem>
                        <FlexboxItem className="ApiToken_popup_component_textfield_wrapper">
                            <TextField
                                className="Audience_input"
                                title={<LiMultiterm textId="99030967" textVal="Audience" />}
                                isRequired
                                disabled={apiTokenModalType === modalType.ADD_VERSION}
                                onChange={handleOnChange}
                                name="audience"
                                errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                                value={apiTokenModalData?.audience}
                            />
                        </FlexboxItem>
                        <FlexboxItem className="ApiToken_popup_component_textfield_wrapper">
                            <TextField
                                className="Subject_input"
                                title={<LiMultiterm textId="99032201" textVal="Subject" />}
                                isRequired
                                disabled
                                onChange={handleOnChange}
                                name="subject"
                                errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                                value={selectedCustomerProductionSites?.id}
                            />
                        </FlexboxItem>
                    </FlexboxContainer>
                    <FlexboxContainer className="ApiToken_popup_component_wrapper_right">
                        <FlexboxItem className="Full_width" style={{ width: '200px', marginTop: '20px' }}>
                            <DatePicker
                                className="Full_width Expiry_date_input"
                                firstDayOfWeek={0}
                                isPreviousDatesDisabled
                                title={
                                    <>
                                        <LiMultiterm textId="99018614" textVal="Expiry date" />
                                        <span className="Required-asterisk">*</span>
                                    </>
                                }
                                onChange={onChangeDatePicker}
                            />
                        </FlexboxItem>
                    </FlexboxContainer>
                </FlexboxContainer>
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default ApiTokenModal;
