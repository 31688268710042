import React from 'react';
import { FlexboxItem, SymbolButton, Popover } from 'ui-components';

import IconPlusWhite from '../../assets/Icons/IconPlusWhite.svg';
import { getMultiTermText } from '../../utils/commonUtils';

interface IFloatingButton {
    multiTermId: string;
    multiTermText: string;
    onClick: () => void;
}

const FloatingButton = ({ multiTermId, multiTermText, onClick }: IFloatingButton) => (
    <FlexboxItem className="Bottom_floating_plus_button_wrapper">
        <SymbolButton
            style={{
                background: 'var(--bordeaux-dark-1)'
            }}
            buttonSize="2rem"
            buttonImage={IconPlusWhite}
            onClick={onClick}
        />
        <Popover className="Component_toolTip_floating_plusButton" mode="tooltip">
            <span>{getMultiTermText({ text: multiTermText, id: multiTermId })}</span>
        </Popover>
    </FlexboxItem>
);

export default FloatingButton;
