import { getMultiTermText } from "../../../utils/commonUtils";

export enum hardlocksModalType {
    CLOSED = 'CLOSED',
    ADD = 'ADD',
    EDIT = 'EDIT',
    SYNC = 'SYNCHRONIZE_HARDLOCK'
}

export default hardlocksModalType;

export const hardLockChangeLocationTypes = {
    DEPARTMENT: 3,
    CUSTOMER: 2,
    USER: 1
};

export const changeLocationRadioGroup = [
    {
        val: hardLockChangeLocationTypes.DEPARTMENT,
        radioSuffix: getMultiTermText({id: "99020906", text: "Department"}) 
    },
    {
        val: hardLockChangeLocationTypes.USER,
        radioSuffix: getMultiTermText({id: "99001942", text: "User"}) 
    },
    {
        val: hardLockChangeLocationTypes.CUSTOMER,
        radioSuffix: getMultiTermText({id: "99007885", text: "Customer"})  
    }
];


export const departmentDropdownData = [
    {
        id: 14,
        name: 'Australia'
    },
    {
        id: 2,
        name:'Austria'
    },
    {
        id: 4,
        name:'China'
    },
    {
        id: 15,
        name:'Czechia'
    },
    {
        id: 18,
        name:'France'
    },
    {
        id: 16,
        name:'Germany'
    },
    {
        id: 17,
        name:'Iran'
    },
    {
        id: 6,
        name:'Italy'
    },
    {
        id: 7,
        name:'Latin America'
    },
    {
        id: 21,
        name:'LiSEC South East Asia SDN. BHD'
    },
    {
        id: 20,
        name:'Lithuania'
    },
    {
        id: 19,
        name:'Polen'
    },
    {
        id: 9,
        name:'Romania'
    },
    {
        id: 10,
        name:'Russia'
    },
    {
        id: 11,
        name:'South Africa'
    },
    {
        id: 5,
        name:'Spain'
    },
    {
        id: 3,
        name:'UAE (Dubai)'
    },
    {
        id: 12,
        name:'UK'
    },
    {
        id: 13,
        name:'USA'
    },
];