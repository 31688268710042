import React, { useEffect, useState } from 'react';
import {
    getCustomers,
    setcustomerModalType,
    postCustomer,
    getCustomerBasedOnId,
    setProductionSitesModalType,
    postProductionSites,
    setcustomerModalData,
    putCustomer,
    patchCustomer,
    putProductionSites,
    setApiTokenModalType,
    postApiToken,
    setOpenApiTokenClipBoardPopup,
    clearPostCustomerResponseData
} from './slice/index';
import { isObjectEntriesSame, setAccordionOpenedId, modalType } from '../../utils/commonUtils';
import { IAccordionObject } from '../../@types/common';
import { ICustomersData, IPayLoadProductionSites } from '../../@types/customers';
import DeleteModalContent from '../../components/deleteModalContent/DeleteModalContent';
import { useAppDispatch, useAppSelector } from '../../hooks';
import OverviewScreen from '../../components/overviewScreen/OverviewScreen';
import headerData from './constants/HeaderData';
import ActionModal from '../../components/actionModal/ActionModal';
import CustomerModal from './components/customerModal/CustomerModal';
import { accordionLevel1, customerType } from '../../utils/appConstants';
import CustomerAccordion from './components/customerAccordion/CustomerAccordion';
import ProductionSitesModal from './components/productionSitesModal/ProductionSitesModal';
import CustomerTableOptions from './components/customerTableOptions/CustomerTableOptions';
import ApitokenActionModal from './components/apiTokenActionModal/ApiTokenActionModal';
import ApiTokenModal from './components/apiTokenModal/ApiTokenModal';
import ApiTokenClipBoardPopup from './components/apiTokenClipBoardPopup/ApiTokenClipBoardPopup';

const Customers = ({ globalContext }: any) => {
    const dispatch = useAppDispatch();
    const [inRowPatchData, setInRowPatchData] = useState<any>({});
    const [showEmptyPatchWarningMessage, setShowEmptyPatchWarningMessage] = useState<boolean>(false);

    const {
        isLoading,
        customersData,
        customerModalType,
        customerModalData,
        productionSitesModalType,
        customerIdToDelete,
        selectedCustomerData,
        apiTokenModalType,
        selectedCustomerProductionSites,
        apiTokenModalData,
        apiTokenClipBoardPopup,
        generatedToken,
        showLicenseSettings,
        postCustomerResponseData
    } = useAppSelector((state) => state.customers);

    useEffect(() => {
        dispatch(getCustomers());
    }, []);

    const { setLoader } = globalContext;

    useEffect(() => {
        setLoader({
            loading: isLoading
        });
    }, [isLoading]);

    const onAddCustomer = () => {
        dispatch(setcustomerModalType(modalType.ADD));
    };

    const onHandlePatchRowData = () => {
        const patchData = customersData.find((eachData) => eachData.id === inRowPatchData.id);
        if (inRowPatchData?.name === '') {
            setShowEmptyPatchWarningMessage(true);
            return;
        }
        const patchPreviousData = {
            name: patchData?.name,
            description: patchData?.description,
            modified: patchData?.modified,
            enabled: patchData?.enabled
        };
        const patchUpdatedData = {
            name: inRowPatchData?.name,
            description: inRowPatchData?.description,
            modified: patchData?.modified,
            enabled: inRowPatchData?.status === 1
        };
        if (!isObjectEntriesSame(patchUpdatedData, patchPreviousData) && patchData?.id) {
            dispatch(patchCustomer({ body: patchUpdatedData, customerId: patchData?.id }));
        }
    };

    useEffect(() => {
        onHandlePatchRowData();
    }, [inRowPatchData]);

    const onClickCustomerModalAction = () => {
        switch (customerModalType) {
            case modalType.ADD: {
                dispatch(postCustomer(customerModalData));
                break;
            }
            case modalType.EDIT: {
                const inputData = {
                    ...customerModalData
                };
                dispatch(putCustomer({ body: inputData, customerId: `${customerModalData.id}` }));
                break;
            }
            default:
                break;
        }
    };

    const onAddProductionSites = () => {
        const featuresId = selectedCustomerProductionSites?.customerFeatures
            ? selectedCustomerProductionSites?.customerFeatures?.map((products: any) => products.featureId)
            : [];
        const productVersionId = selectedCustomerProductionSites?.customerProducts
            ? selectedCustomerProductionSites?.customerProducts?.map((products: any) => products.productVersionId)
            : [];
        // Remove duplicates using Set
        const uniqueFeatureIds = [...new Set(featuresId)];
        const uniqueProductVersionIds = [...new Set(productVersionId)];
        const licenseSettings = {
            ...selectedCustomerProductionSites.licenseSettings,
            productionSiteId: selectedCustomerProductionSites.id
        };

        const productionSites: IPayLoadProductionSites = {
            licenseSettings: showLicenseSettings ? licenseSettings : null,
            customerId: selectedCustomerProductionSites.customerId,
            productVersionIds: uniqueProductVersionIds,
            featureIds: uniqueFeatureIds,
            modified: new Date().toISOString(),
            name: selectedCustomerProductionSites.name,
            sapId: selectedCustomerProductionSites.sapId,
            description: selectedCustomerProductionSites.description,
            enabled: selectedCustomerProductionSites.enabled,
            lastSynchronisation: new Date().toISOString()
        };

        switch (productionSitesModalType) {
            case modalType.ADD: {
                dispatch(postProductionSites(productionSites));
                break;
            }
            case modalType.EDIT: {
                const productionSitesPayload = {
                    ...productionSites,
                    modified: selectedCustomerProductionSites.modified
                };
                dispatch(
                    putProductionSites({
                        body: productionSitesPayload,
                        productionSitesId: `${selectedCustomerProductionSites.id}`
                    })
                );
                break;
            }

            default:
                break;
        }
    };

    const onClickApiTokenModalAction = () => {
        const apiToken = {
            audience: apiTokenModalData.audience,
            name: apiTokenModalData.name,
            subject: selectedCustomerProductionSites.id,
            idp: 'default',
            expires: apiTokenModalData.expires ? apiTokenModalData.expires : '2025-01-16T06:54:53.521Z',
            modalType: apiTokenModalType
        };
        dispatch(postApiToken(apiToken));
    };

    const getCustomerTableData = (customerData: Array<ICustomersData>) => {
        const accordionOpenedData = sessionStorage.getItem('accordionOpened');
        let openedAccordion: IAccordionObject = {};
        if (accordionOpenedData) {
            openedAccordion = JSON.parse(accordionOpenedData);
        }
        return customerData?.map((eachData) => {
            let isAccordionOpened = false;
            if (openedAccordion && openedAccordion[customerType] && openedAccordion[customerType][accordionLevel1]) {
                const openedData = openedAccordion[customerType][accordionLevel1].map((id) => id?.toString());
                if (openedData.includes(eachData.id.toString())) {
                    isAccordionOpened = true;
                }
            }
            return {
                _key: eachData.id,
                _accordionContent: <CustomerAccordion customerData={eachData} />,
                _option: (
                    <CustomerTableOptions
                        onClickEdit={() => {
                            dispatch(setcustomerModalType(modalType.EDIT));
                            dispatch(setcustomerModalData(eachData));
                        }}
                    />
                ),
                dependentOn: eachData.dependentOn?.toString(),
                id: eachData.id,
                description: eachData.description,
                name: eachData.name,
                status: eachData.enabled ? 1 : 2,
                isAccordionOpen: isAccordionOpened
            };
        });
    };

    const onCloseCustomerModal = () => {
        dispatch(setcustomerModalType(modalType.CLOSED));
    };

    const onCloseProductionSitesModal = () => {
        dispatch(setProductionSitesModalType(modalType.CLOSED));
    };

    const onCloseApiTokenModal = () => {
        const apiTokenModalStatus = {
            modalStatus: modalType.CLOSED,
            type: apiTokenModalType === modalType.ADD_VERSION ? 'ADD_VERSION' : 'ADD_TOKEN'
        };
        dispatch(setApiTokenModalType(apiTokenModalStatus));
    };

    const onHandleAccordionToggle = (data: any) => {
        const obj = JSON.stringify(setAccordionOpenedId(accordionLevel1, data.id, customerType, data));
            dispatch(clearPostCustomerResponseData({}))
        sessionStorage.setItem('accordionOpened', obj);
    };

    useEffect(()=>{
        if (postCustomerResponseData && postCustomerResponseData.id )
        onHandleAccordionToggle(postCustomerResponseData)
    },[postCustomerResponseData])

    const onClickCopyAction = () => {
        const copyText = generatedToken;
        const textAreaTemp = document.createElement('textarea');
        textAreaTemp.value = copyText;
        document.body.appendChild(textAreaTemp);
        textAreaTemp.select();
        document.execCommand('copy');
        document.body.removeChild(textAreaTemp);
    };

    const onClosePatchWarningMessage = () => {
        setShowEmptyPatchWarningMessage(false);
        dispatch(getCustomers());
    };

    return (
        <>
            <OverviewScreen
                pageHeaderMultiTermId="99002847"
                pageHeaderMultiTermText="Customers"
                tableClassName="customers_table"
                headerData={headerData}
                rowsData={getCustomerTableData(customersData)}
                initialColumnSortData={[
                    {
                        order: 'ascending',
                        columnKey: 'id'
                    }
                ]}
                tableId="customersTable"
                addButtonMultiTermId="99025143"
                addButtonMultiTermText="Add customer"
                showEmptyPatchWarningMessage={showEmptyPatchWarningMessage}
                addButtonOnClick={onAddCustomer}
                onPatchRowData={(rowData) => setInRowPatchData(rowData)}
                onAccordionToggle={onHandleAccordionToggle}
                onClosePatchWarningMessage={onClosePatchWarningMessage}
                onScrollToId={postCustomerResponseData?.id}
            />
            {productionSitesModalType !== modalType.CLOSED && (
                <ActionModal
                    onClickAction={onAddProductionSites}
                    onClickCancelClose={onCloseProductionSitesModal}
                    actionType={productionSitesModalType}
                    modalColumnNumber={2}
                    ModalContent={<ProductionSitesModal />}
                    actionDisabled={
                        productionSitesModalType !== modalType.DELETE
                            ? !selectedCustomerProductionSites?.id ||
                              !selectedCustomerProductionSites?.name ||
                              !selectedCustomerProductionSites?.description ||
                              (showLicenseSettings &&
                                  !selectedCustomerProductionSites?.licenseSettings?.noConnEscalation1Time) ||
                              (showLicenseSettings &&
                                  !selectedCustomerProductionSites?.licenseSettings?.noConnEscalation2Time) ||
                              (showLicenseSettings &&
                                  !selectedCustomerProductionSites?.licenseSettings?.noConnEscalationFrequency) ||
                              (showLicenseSettings &&
                                  !selectedCustomerProductionSites?.licenseSettings?.noConnEscalationEMail1)
                            : false
                    }
                />
            )}
            {customerModalType !== modalType.CLOSED && (
                <ActionModal
                    onClickAction={onClickCustomerModalAction}
                    onClickCancelClose={onCloseCustomerModal}
                    actionType={customerModalType}
                    modalColumnNumber={2}
                    ModalContent={
                        customerModalType !== modalType.DELETE ? (
                            <CustomerModal />
                        ) : (
                            <DeleteModalContent isDeletePermanentMessage={false} />
                        )
                    }
                    actionDisabled={
                        customerModalType !== modalType.DELETE
                            ? !customerModalData?.id || !customerModalData?.name
                            : false
                    }
                />
            )}
            {apiTokenModalType !== modalType.CLOSED && (
                <ActionModal
                    onClickAction={onClickApiTokenModalAction}
                    onClickCancelClose={onCloseApiTokenModal}
                    actionType={apiTokenModalType}
                    modalColumnNumber={2}
                    ModalContent={<ApiTokenModal />}
                    actionDisabled={
                        apiTokenModalType !== modalType.DELETE
                            ? !apiTokenModalData?.name || !apiTokenModalData.audience
                            : false
                    }
                />
            )}

            {apiTokenClipBoardPopup ? (
                <ApitokenActionModal
                    onClickCancelClose={() => {
                        dispatch(setOpenApiTokenClipBoardPopup(false));
                    }}
                    onClickCopyAction={onClickCopyAction}
                    actionType={apiTokenModalType}
                    modalColumnNumber={2}
                    ModalContent={<ApiTokenClipBoardPopup />}
                />
            ) : null}
        </>
    );
};

export default Customers;
