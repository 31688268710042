/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { dateTimeFormatConversion } from 'lisecutilityfunctions/lib/commonfunction';

import { FlexboxContainer, FlexboxItem, Table } from 'ui-components';
import { IAccordionObject } from '../../../../@types/common';
import { IHardlocksDetailTableRowData } from '../../../../@types/hardlocks';
import HardlocksDetailHeaderData from '../../constants/HardlocksDetailHeaderData';
import {HardlocksDetailsTableOptions} from '../hardlockTableOption/HaradlocksTableOptions';
import downloadFile, { hardLockStatus } from '../../utils/HardlocksUtil';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    setCurrentSelectedHardlock,
    getHardlocksActionHistory,
    getHardlockV2C,
    clearV2cDownloadedData
} from '../../slice/index';

interface IHardlocksAccordion {
    hardlockId: number;
}

const HardlocksAccordion = ({ hardlockId }: IHardlocksAccordion) => {
    const dispatch = useAppDispatch();
    const [selectedHardlockID, setSelectedHardlockID] = useState<number>();
    const {
        hardlocksActionHistoryData,v2cDownloadedData
    } = useAppSelector((state) => state.hardlocks);

    useEffect(() => {
            dispatch(setCurrentSelectedHardlock(hardlockId));
            dispatch(getHardlocksActionHistory(hardlockId));
    }, []);

    useEffect(() => {
        if (v2cDownloadedData === ''){
            setSelectedHardlockID(undefined)
            return; 
        }
        const xmlData = v2cDownloadedData;
        const fileName = `${selectedHardlockID}.v2c`;
        downloadFile(fileName,xmlData,'xml');
        dispatch(clearV2cDownloadedData());
    }, [v2cDownloadedData]);

    const getDownloadV2C = (hardlockActionHistoryId : number) => {
        dispatch(getHardlockV2C({hardlockActionHistoryId,hardlockId}));
    }
    
    const getHardlocksDetailsTableData = (hardlocksDetailData: any) => {
        const accordionOpenedData = sessionStorage.getItem('accordionOpened');
        let openedAccordion: IAccordionObject = {};
        if (accordionOpenedData) {
            openedAccordion = JSON.parse(accordionOpenedData);
        }
        return hardlocksDetailData?.[hardlockId]?.map((eachData: IHardlocksDetailTableRowData,) => (
                {
                    _key: eachData.id,
                    _option: (eachData.v2CFile ?
                        <HardlocksDetailsTableOptions
                        onDownloadClick={() => {
                            if (eachData.v2CFile){
                                getDownloadV2C(eachData.id);
                                setSelectedHardlockID(hardlockId)
                                // downloadFile(`${hardlockId}_${eachData.id}.v2c`, eachData.v2CFile)
                            }
                            }}
    
                        />
                        : []
                    ),
                    date: dateTimeFormatConversion(eachData.actionTimestamp, 'datetime'),
                    status: hardLockStatus[eachData.status],
                    location: eachData.locationName ?? eachData.location,
                    modifiedBy: eachData.modifiedBy,
                    remarks: eachData.remarks
                }
        ));
    };

    return (
        <FlexboxContainer
            flexDirection="column"
            gap="1rem"
            className="Accordion_wrapper Full_width"
            justifyContent="start"
            alignItems="stretch"
        >

            <FlexboxItem className="Full_width Inner_level_one_table">
                <Table
                    className="hardlocksDetail_table"
                    headerData={HardlocksDetailHeaderData}
                    tableHeaderClassNameForStyle="Table_inner_header"
                    isSingleSelectable={false}
                    rowsData={getHardlocksDetailsTableData(hardlocksActionHistoryData)}
                    tableHeaderRowStyles={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1
                    }}
                    isPrintable={false}
                    isMultiSelectable={false}
                    tableConfiguration={{
                        pagination: false,
                        pageOptions: [0],
                        showTableSettings: false,
                        showSearch: false,
                        sortAllRows: false,
                        showRowCount: false
                    }}
                    pagination={false}
                    tableId="hardlocksDetail_table"
                />
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default HardlocksAccordion;
