import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import { FlexboxContainer, FlexboxItem, Button, Typography, TextField } from 'ui-components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setOpenApiTokenClipBoardPopup } from '../../slice/index';
import './ApiTokenClipBoardpopup.css';
import { RootState } from '../../../../store';

const ApiTokenClipBoardPopup = () => {
    const { generatedToken } = useAppSelector((state: RootState) => state.customers);
    return (
        <FlexboxContainer flexDirection="column" alignItems="start">
            <FlexboxItem className="ApiToken_popup_header_block">
                <Typography type="H1">
                    <LiMultiterm textId="99028766" textVal="Server Response" />
                </Typography>
            </FlexboxItem>
            <FlexboxContainer
                alignItems="start"
                flexDirection="column"
                justifyContent="center"
                className="ApiToken_clipboard_popup_content_wrapper"
            >
                <FlexboxItem className="ApiToken_clipboard_popup_textfield">
                    <TextField
                        className="Generated_token_input"
                        title={<LiMultiterm textId="99030966" textVal="API tokens" />}
                        name="token"
                        value={generatedToken}
                    />
                </FlexboxItem>
                <Typography type="OL1" additionalClassForStyle="ApiToken_clipboard_popup_note">
                    <LiMultiterm
                        textId="99031220"
                        textVal="Successfully created API Token. Make sure to save it now, you won't be able to access it again!"
                    />
                </Typography>
            </FlexboxContainer>
        </FlexboxContainer>
    );
};

export default ApiTokenClipBoardPopup;
