import React, { useEffect } from 'react';
import { FlexboxContainer, FlexboxItem, Typography } from 'ui-components';
import { isAnyOfSubLevelChecked } from '../../utils/productionSitesUtils';

import './ProductListTree.css';

const ProductListTree = ({ data, level = 0 }: any) =>
    data?.map((products: any) => (
        <FlexboxItem key={products.id} className="Assigned_featurelist Full_width">
            {isAnyOfSubLevelChecked(products.sublevel) || products.checked ? (
                <FlexboxItem
                    key={products.id}
                    className={`MultiList_Products MulltiList_container level-${level} ${
                        level === 0 ? 'Parent_MultiLevel' : 'Child_MultiLevel'
                    } ${level === 0 ? 'MultiListTree_Container' : ''}`}
                >
                    <Typography
                        additionalClassForStyle={products.checked ? 'Text_Disabled' : ''}
                        type={level === 0 ? 'ST3' : 'BS1'}
                    >
                        {products.id} - {products.name}
                    </Typography>
                    {products.productVersions &&
                        products.productVersions.length > 0 &&
                        products.productVersions.map((productVersion: any) => (
                            <FlexboxItem key={productVersion.id} className="InnermultiList_Container">
                                <Typography type="L2">{productVersion.version}</Typography>
                            </FlexboxItem>
                        ))}
                </FlexboxItem>
            ) : null}
        </FlexboxItem>
    ));

export default ProductListTree;
