import React from 'react';
import { Popup } from 'ui-components';
import { getMultiTermText, modalType } from '../../utils/commonUtils';

interface IActionModal {
    onClickAction: () => void;
    onClickCancelClose: () => void;
    actionType: string;
    modalColumnNumber: number;
    ModalContent: JSX.Element;
    actionDisabled: boolean;
    customActionButtonArr?: any[];
    popupClassName?: string;
}

const ActionModal = ({
    onClickAction,
    onClickCancelClose,
    actionType,
    modalColumnNumber,
    ModalContent,
    actionDisabled,
    customActionButtonArr,
    popupClassName
}: IActionModal) => {
    const getActionButtonMultiTerm = () => {
        switch (actionType) {
            case modalType.ADD: {
                return getMultiTermText({ id: '06001696', text: 'Add' });
            }
            case modalType.EDIT: {
                return getMultiTermText({ id: '06007335', text: 'Save' });
            }
            case modalType.DELETE: {
                return getMultiTermText({ id: '99003381', text: 'Delete' });
            }
            default:
                return getMultiTermText({ id: '06007335', text: 'Save' });
        }
    };

    const rightSideButtonsArr = customActionButtonArr && customActionButtonArr.length > 0 ? customActionButtonArr : [
        {
            text: getMultiTermText({ id: '99002343', text: 'Cancel' }),
            onClick: onClickCancelClose,
            className: actionType === modalType.DELETE ? 'Primary_button' : ' '
        },
        {
            text: getActionButtonMultiTerm(),
            className: actionType !== modalType.DELETE ? 'Primary_button' : ' ',
            disabled: actionDisabled,
            onClick: onClickAction
        }
    ]
    return (
        <Popup
            onClose={onClickCancelClose}
            mode="modal"
            className={`${modalColumnNumber === 1 ? 'Single_column_modal ' : 'App_Two_column_modal '} ${popupClassName} `}
            disableResize
            buttonArr={{
                rightSideButtons: rightSideButtonsArr
            }}
        >
            {ModalContent}
        </Popup>
    );
};
ActionModal.defaultProps = {
	customActionButtonArr: [],
    popupClassName: ""
}

export default ActionModal;
