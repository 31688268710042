import React, { useEffect, useState } from 'react';
import { FlexboxContainer, FlexboxItem, Typography } from 'ui-components';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import IconFeatures from '../../../../assets/Icons/IconFeatures.svg';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getDependentFeatures } from '../../../Features/slice/Feature';
import { getDataForDependentFeatures } from '../../../../utils/commonUtils';
import AssignedFeatureList from '../../../Products/components/AssignedFeatureList/AssignedFeatureList';

interface ILoginHistoryAccordion {
    permissionsData: string;
}

const LoginHistoryAccordion = ({ permissionsData }: ILoginHistoryAccordion) => {
    const dispatch = useAppDispatch();
    const { dependentFeaturesData } = useAppSelector((state) => state.features);
    const [authorizedFeatures, setAuthorizedFeatures] = useState<Array<number>>([]);

    useEffect(() => {
        if (permissionsData.length) {
            const featuresListInString = permissionsData.split(',');
            setAuthorizedFeatures(featuresListInString.map((eachId) => +eachId));
        }
    }, [permissionsData]);

    useEffect(() => {
        if (!dependentFeaturesData.length) {
            dispatch(getDependentFeatures());
        }
    }, []);
    
    return (
        <FlexboxContainer
            className="Accordion_wrapper"
            justifyContent="start"
            alignItems="start"
            flexDirection="column"
            gap="0.25rem"
        >
            <FlexboxItem>
                <FlexboxContainer
                    flexDirection="row"
                    justifyContent="start"
                    alignContent="center"
                    className="SubHeader_Image "
                >
                    <img
                        alt="features-icon"
                        src={IconFeatures}
                        style={{ height: '30px', width: '30px', paddingRight: '5px' }}
                    />
                    <Typography type="B">
                        <LiMultiterm textId="99023864" textVal="Permissions" />
                    </Typography>
                </FlexboxContainer>
            </FlexboxItem>
            <FlexboxItem>
                <AssignedFeatureList
                    data={getDataForDependentFeatures(dependentFeaturesData, authorizedFeatures, 0, [])}
                />
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default LoginHistoryAccordion;
