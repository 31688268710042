import React from 'react';
import { Notification } from 'ui-components';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { clearError } from '../../slice/ErrorHandling';

const ErrorHandler: React.FC = () => {
    const { message, statusCode } = useAppSelector((state: RootState) => state.errorSlice);
    const dispatch = useAppDispatch();
    if (statusCode !== 0 && statusCode !== undefined && message !== null && message !== undefined)
        return (
            <Notification
                style={{
                    width: '21rem',
                    height: '11rem'
                }}
                type="error"
                title={statusCode}
                subtitle={message}
                onClose={() => {
                    dispatch(clearError());
                }}
            />
        );
    return null;
};

export default ErrorHandler;
