/* eslint-disable react/require-default-props */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SubOptionListComponent } from 'ui-components';
import { getMultiTermText } from '../../../../utils/commonUtils';

interface ITableOptionsAdmnin {
    onLocationChangeClick: () => void;
    onSyncHardlockClick: () => void;
    onDownloadClick: () => void;

}

interface ITableOptions {
    onSyncHardlockClick: () => void;
}

interface ITableDetailsOptions {
    onDownloadClick: () => void;
}

export const HardlocksTableOptionsAdminRights = ({ onLocationChangeClick, onSyncHardlockClick, onDownloadClick }: ITableOptionsAdmnin) => {
    const optionList = {
        list: [
            {
                title: getMultiTermText({ text: 'Location change', id: '99034255' }),
                click: onLocationChangeClick,
                key: 'locationChange'
            },
            {
                title: getMultiTermText({ text: 'Synchronise hardlock', id: '99034257' }),
                click: onSyncHardlockClick,
                key: 'syncHardlock'
            },
            {
                title: getMultiTermText({ text: 'Download', id: ''}),
                click: onDownloadClick,
                key: 'download'
            }
        ]
    };
    return <SubOptionListComponent optionList={optionList} />;
};

export const HardlocksTableOptions = ({ onSyncHardlockClick }: ITableOptions) => {
    const optionList = {
        list: [
            {
                title: getMultiTermText({ text: 'Synchronise hardlock', id: '99034257' }),
                click: onSyncHardlockClick,
                key: 'syncHardlock'
            }
        ]
    };
    return <SubOptionListComponent optionList={optionList} />;
};

export const HardlocksDetailsTableOptions = ({ onDownloadClick }: ITableDetailsOptions) => {
    const optionList = {
        list: [
            {
                title: getMultiTermText({ text: 'Download', id: '' }),
                click: onDownloadClick,
                key: 'download'
            },
        ]
    };
    return <SubOptionListComponent optionList={optionList} />;
};

