import { getMultiTermText, getLabelOptions } from '../../../utils/commonUtils';

export const productsHeaderData = [
    {
        _key: 'name',
        label: getMultiTermText({ id: '99002657', text: 'Short name' }),
        searching: true,
        sorting: true,
        width: '25%',
        isEditable: true,
        columnDataClassName: 'Font_bold'
    },
    {
        _key: 'description',
        label: getMultiTermText({ id: '99018122', text: 'Name' }),
        searching: true,
        sorting: true,
        width: '17%',
        isEditable: true,
    },
    {
        _key: 'productVersions',
        label: getMultiTermText({ id: '99032707', text: 'Current version' }),
        searching: true,
        sorting: true,
        width: '15%',
        isEditable: false,
    },
    {
        _key: 'isHardlockControlled',
        label: getMultiTermText({ id: '99034223', text: 'Hardlock controlled' }),
        searching: true,
        sorting: true,
        width: '15%',
        type: 'number',
        _component: 'checkbox',
        isEditable: true,
    },
    {
        _key: 'enabled',
        label: getMultiTermText({ id: '03000144', text: 'Status' }),
        searching: false,
        sorting: true,
        width: '10%',
        isEditable: true,
        _component: 'label',
        filterOptions: [
            {
                id: 1,
                name: getMultiTermText({ id: '99000784', text: 'Enabled' })
            },
            {
                id: 2,
                name: getMultiTermText({ id: '99000809', text: 'Disabled' })
            }
        ],
        labelOptions: [
            {
                id: 1,
                text: getLabelOptions({ id: '99000784', text: 'Enabled', className: 'Status_green' })
            },
            {
                id: 2,
                text: getLabelOptions({ id: '99000809', text: 'Disabled', className: 'Status_gray' })
            }
        ]
    },
    {
        _key: 'sapId',
        label: getMultiTermText({ id: '99031088', text: 'SAP ID' }),
        searching: true,
        sorting: true,
        width: '15%',
        isEditable: true,
    },
    {
        _key: 'id',
        label: getMultiTermText({ id: '99008287', text: 'ID#' }),
        searching: true,
        sorting: true,
        width: '5%',
        isEditable: false,
    }
];

export const associatedProductsHeaderData = [
    {
        _key: 'id',
        label: getMultiTermText({ id: '99008287', text: 'ID#' }),
        searching: true,
        sorting: true,
        width: '35%',
        isEditable: false,
    },
    {
        _key: 'productionSiteName',
        label: getMultiTermText({ id: '99018122', text: 'Name' }),
        searching: true,
        sorting: true,
        width: '35%',
        isEditable: false,
    },
    {
        _key: 'customerName',
        label: getMultiTermText({ id: '9000326', text: 'Customer' }),
        searching: true,
        sorting: true,
        width: '20%',
        isEditable: false,
    },
    {
        _key: 'enabled',
        label: getMultiTermText({ id: '99000784', text: 'Enabled' }),
        searching: true,
        sorting: true,
        width: '20%',
        isEditable: false,
    }
];