/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef } from 'react';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import {
    FlexboxContainer,
    FlexboxItem,
    Typography,
    TextField,
    MultiList,
    Scrollable,
    TextLabel,
    Checkbox
} from 'ui-components';
import { IMultiListArrayType } from '../../../../@types/customers';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    flattenMultiList,
    modalType,
    getDataForDependentFeatures,
    getDataForAssociatedProducts
} from '../../../../utils/commonUtils';
import {
    setSelectedCustomerProductionSites,
    getLatestProductionSitesId,
    getProductionSitesById,
    getProducts,
    getDependentFeatures,
    setShowLicenseSettings
} from '../../slice/index';
import IconDelete from '../../../../assets/Icons/IconDelete.svg';

import textLabelOptions from '../../constants/textLabelOptions';
import './ProductionSitesModal.css';
import '../../../../styles/global.css';
import AddIconAccordion from '../../../../components/addIconAccordion/AddIconAccordion';

const ProductionSitesModal = () => {
    const hasEffectRun = useRef(false);

    const {
        productionSitesModalType,
        productionSitesModalData,
        allProducts,
        selectedCustomerProductionSites,
        dependentFeaturesData,
        latestProductionSitesId,
        showLicenseSettings,
        defaultLicenseSettings
    } = useAppSelector((state) => state.customers);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (productionSitesModalType === modalType.ADD) {
            const tempModalData = { ...selectedCustomerProductionSites };
            tempModalData.id = latestProductionSitesId ? latestProductionSitesId + 1 : 1;
            dispatch(setSelectedCustomerProductionSites(tempModalData));
        }
    }, [latestProductionSitesId]);

    useEffect(() => {
        if (productionSitesModalType === modalType.ADD) {
            dispatch(getLatestProductionSitesId());
        }
        if (productionSitesModalType === modalType.EDIT) {
            dispatch(getProductionSitesById(productionSitesModalData?.id));
        }
        dispatch(getDependentFeatures());
        dispatch(getProducts());
    }, []);

    useEffect(() => {
        if (hasEffectRun.current || !selectedCustomerProductionSites?.id) {
            return;
        }
        if (
            !selectedCustomerProductionSites?.licenseSettings ||
            !selectedCustomerProductionSites?.licenseSettings?.noConnEscalation2Time
        ) {
            const licenseSettings = defaultLicenseSettings;
            const originalPayloadValue = selectedCustomerProductionSites;
            const updatedDefaultLicenseSettingsValue = { ...originalPayloadValue, licenseSettings };
            dispatch(setSelectedCustomerProductionSites(updatedDefaultLicenseSettingsValue));
            dispatch(setShowLicenseSettings(false));
            hasEffectRun.current = true;
        } else {
            dispatch(setShowLicenseSettings(true));
            hasEffectRun.current = true;
        }
    }, [selectedCustomerProductionSites]);

    useEffect(() => {
        if (productionSitesModalType === modalType.ADD) {
            const tempModalData = { ...selectedCustomerProductionSites };
            tempModalData.id = latestProductionSitesId ? latestProductionSitesId + 1 : 1;
            dispatch(setSelectedCustomerProductionSites(tempModalData));
        }
    }, []);

    const updateModalData = <T,>(value: T, name: string) => {
        let tempModalData: any = { ...selectedCustomerProductionSites };
        if (tempModalData) {
            tempModalData = {
                ...tempModalData,
                [name]: value
            };
            dispatch(setSelectedCustomerProductionSites(tempModalData));
        }
    };

    const handleProductMultiListChange = (data: Array<IMultiListArrayType>) => {
        const selectedFeatures = flattenMultiList(data)
            .filter((eachData) => eachData.checked)
            .map<any>((filteredData) => ({
                productVersionId: filteredData.id
            }));
        let tempModalData: any = { ...selectedCustomerProductionSites };
        if (tempModalData) {
            tempModalData = {
                ...tempModalData,
                customerProducts: selectedFeatures
            };
            dispatch(setSelectedCustomerProductionSites(tempModalData));
        }
    };

    const handleFeaturesMultiListChange = (data: Array<IMultiListArrayType>) => {
        const selectedFeatures = flattenMultiList(data)
            .filter((eachData) => eachData.checked)
            .map<any>((filteredData) => ({
                featureId: filteredData.id
            }));
        let tempModalData: any = { ...selectedCustomerProductionSites };
        if (tempModalData) {
            tempModalData = {
                ...tempModalData,
                customerFeatures: selectedFeatures
            };
            dispatch(setSelectedCustomerProductionSites(tempModalData));
        }
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        updateModalData(event.target.value, event.target.name);

    const handleOnLicenseSettingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempModalData = { ...selectedCustomerProductionSites };
        tempModalData = {
            ...tempModalData,
            licenseSettings: {
                ...tempModalData.licenseSettings,
                [event.target.name]: event.target.value.replace(/\D/g, '')
            }
        };
        dispatch(setSelectedCustomerProductionSites(tempModalData));
    };

    const handleOnEmailInputChange = (input: string, value: string) => {
        let tempModalData = { ...selectedCustomerProductionSites };
        tempModalData = {
            ...tempModalData,
            licenseSettings: {
                ...tempModalData.licenseSettings,
                [input]: value
            }
        };
        dispatch(setSelectedCustomerProductionSites(tempModalData));
    };

    const handleOnAddEmail = () => {
        let tempModalData = { ...selectedCustomerProductionSites };
        const filteredEmailKeys = Object.keys(selectedCustomerProductionSites.licenseSettings).filter((key) =>
            key.startsWith('noConnEscalationEMail')
        );
        const nextEmailField = `noConnEscalationEMail${
            Object.keys(selectedCustomerProductionSites.licenseSettings).filter((key) =>
                key.startsWith('noConnEscalationEMail')
            ).length + 1
        }`;
        if (filteredEmailKeys.length < 3) {
            tempModalData = {
                ...tempModalData,
                licenseSettings: {
                    ...tempModalData.licenseSettings,
                    [nextEmailField]: ''
                }
            };
            dispatch(setSelectedCustomerProductionSites(tempModalData));
        }
    };

    /**
     *function to show or hide license settings
     */
    const onChangeSettings = () => {
        dispatch(setShowLicenseSettings(!showLicenseSettings));
        let tempModalData = { ...selectedCustomerProductionSites };
        if (selectedCustomerProductionSites && selectedCustomerProductionSites.licenseSettings?.noConnEscalation2Time) {
            tempModalData = {
                ...tempModalData,
                licenseSettings: {
                    ...tempModalData.licenseSettings // This will avoid filling default value when enabling "Require" checkbox incase the exisiting value persists in state
                }
            };
        } else {
            tempModalData = {
                ...tempModalData,
                licenseSettings: {
                    ...tempModalData.licenseSettings,
                    ...defaultLicenseSettings // Fill the license settings with default value when enabling "Require" checkbox
                }
            };
        }
        if (showLicenseSettings) {
            dispatch(setSelectedCustomerProductionSites(tempModalData));
        }
    };
    const nonEscalationEmailLength =
        selectedCustomerProductionSites?.licenseSettings &&
        Object.keys(selectedCustomerProductionSites?.licenseSettings).length > 1
            ? Object.keys(selectedCustomerProductionSites?.licenseSettings)?.filter((key) =>
                  key.startsWith('noConnEscalationEMail')
              ).length
            : 1;

    /**
     * Delete existing email input
     * @returns
     */
    const onDeleteEmailInput = (propertyToDelete: string) => {
        const customerProductionSite = { ...selectedCustomerProductionSites };
        const filteredNestedObjects = Object.fromEntries(
            Object.keys(customerProductionSite.licenseSettings)
                .filter((key) => key !== propertyToDelete)
                .map((key) => [key, customerProductionSite.licenseSettings[key]])
        );
        // Create a new object with the updated nestedObject
        const updatedCustomerProductionSites = {
            ...customerProductionSite,
            licenseSettings: filteredNestedObjects
        };
        dispatch(setSelectedCustomerProductionSites(updatedCustomerProductionSites));
    };

    const multipleUserEmailTextFields = () => (
        <FlexboxItem className="Module_popup_component_textfield_wrapper">
            <FlexboxContainer flexDirection="column" alignItems="start" gap="0.625rem">
                {selectedCustomerProductionSites?.licenseSettings &&
                Object.keys(selectedCustomerProductionSites.licenseSettings).length > 1
                    ? Object.entries(selectedCustomerProductionSites.licenseSettings)
                          .filter(([key, value]) => key.includes('noConnEscalationEMail'))
                          .map(([key, value]) => (
                              <FlexboxItem className="Full_width" key={key}>
                                  <FlexboxContainer className="Email_modal_input_wrapper" justifyContent="end">
                                      <FlexboxItem flexGrow="1">
                                          <TextField
                                              className="Client_modal_text_field"
                                              isRequired={key === 'noConnEscalationEMail1'}
                                              title={
                                                  key === 'noConnEscalationEMail1' ? (
                                                      <LiMultiterm textId="99034222" textVal="E-Mail addresses" />
                                                  ) : null
                                              }
                                              errorText={
                                                  <LiMultiterm textId="99033565" textVal="This is a mandatory field" />
                                              }
                                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                  handleOnEmailInputChange(key, e.target.value)
                                              }
                                              value={value}
                                          />
                                          {key !== 'noConnEscalationEMail1' ? (
                                              <FlexboxItem
                                                  className={`Email_modal_delete_icon ${
                                                      key === 'noConnEscalationEMail1' &&
                                                      'Email_modal_delete_icon_adjust_height'
                                                  }`}
                                              >
                                                  <div
                                                      role="button"
                                                      onClick={() => onDeleteEmailInput(key)}
                                                      onKeyDown={() => onDeleteEmailInput(key)}
                                                      tabIndex={0}
                                                  >
                                                      <img
                                                          src={IconDelete}
                                                          data-testid="Product_release_add_icon"
                                                          className="Multiple_url_Delete_icon"
                                                          alt="Delete-icon"
                                                      />
                                                  </div>
                                              </FlexboxItem>
                                          ) : null}
                                      </FlexboxItem>
                                  </FlexboxContainer>
                              </FlexboxItem>
                          ))
                    : null}
                {nonEscalationEmailLength < 3 ? (
                    <FlexboxItem onClick={handleOnAddEmail}>
                        <AddIconAccordion dataTestId="Product_release_add_icon" />
                    </FlexboxItem>
                ) : null}
            </FlexboxContainer>
        </FlexboxItem>
    );
    return (
        <FlexboxContainer
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="0.5rem"
            className="Full_height"
        >
            <FlexboxItem>
                <Typography type="H1">
                    {productionSitesModalType === modalType.ADD ? (
                        <LiMultiterm textId="99032443" textVal="Add Production Site" />
                    ) : (
                        <LiMultiterm textId="99032445" textVal="Edit Production Site" />
                    )}
                </Typography>
            </FlexboxItem>
            <FlexboxContainer>
                <TextLabel
                    inPlaceEdit
                    optionList={textLabelOptions}
                    style={{ padding: '0px' }}
                    value={selectedCustomerProductionSites?.enabled ? textLabelOptions[0] : textLabelOptions[1]}
                    onChange={(statusId: any) => {
                        const status = statusId === 1;
                        const customerProductionSites = {
                            ...selectedCustomerProductionSites,
                            enabled: status
                        };
                        dispatch(setSelectedCustomerProductionSites(customerProductionSites));
                    }}
                />
            </FlexboxContainer>

            <FlexboxItem className="Main_seprator Full_width" />
            <FlexboxItem>
                <FlexboxContainer alignItems="start" justifyContent="start">
                    <FlexboxContainer
                        className="ProdSiteModal__LeftColumnWrapper"
                        flexDirection="column"
                        gap="1.25rem"
                        alignItems="start"
                        justifyContent="start"
                        style={{ marginTop: 10 }}
                    >
                        <FlexboxItem className="Full_width">
                            <TextField
                                className="Id_input"
                                title={<LiMultiterm textId="99008287" textVal="ID#" />}
                                isRequired
                                disabled={productionSitesModalType === modalType.EDIT}
                                onChange={handleOnChange}
                                name="id"
                                errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                                value={selectedCustomerProductionSites?.id}
                            />
                        </FlexboxItem>
                        <FlexboxItem className="Full_width">
                            <TextField
                                className="Name_input"
                                title={<LiMultiterm textId="6001104" textVal="Name" />}
                                isRequired
                                onChange={handleOnChange}
                                name="description"
                                value={selectedCustomerProductionSites?.description}
                                errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                            />
                        </FlexboxItem>
                        <FlexboxItem className="Full_width">
                            <TextField
                                className="Short_name_input"
                                title={<LiMultiterm textId="99002657" textVal="Short name" />}
                                isRequired
                                onChange={handleOnChange}
                                name="name"
                                value={selectedCustomerProductionSites?.name}
                                errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                            />
                        </FlexboxItem>

                        <FlexboxItem className="Full_width">
                            <TextField
                                className="SAPID_input"
                                title={<LiMultiterm textId="99031088" textVal="SAP ID" />}
                                onChange={handleOnChange}
                                name="sapId"
                                value={selectedCustomerProductionSites?.sapId}
                            />
                        </FlexboxItem>

                        <FlexboxItem>
                            <Typography type="ST1">
                                <LiMultiterm textId="99033469" textVal="Synchronization error escalation settings" />
                            </Typography>
                            <FlexboxContainer
                                flexDirection="row"
                                gap="0.875rem"
                                justifyContent="space-between"
                                style={{ marginTop: 5 }}
                            >
                                <Typography type="ST6">
                                    <LiMultiterm textId="99026264" textVal="Required" />
                                </Typography>
                                <Checkbox onChange={onChangeSettings} theme="light" checked={showLicenseSettings} />
                            </FlexboxContainer>
                        </FlexboxItem>
                        {showLicenseSettings ? (
                            <>
                                <FlexboxContainer flexDirection="row" alignItems="center" gap="3px">
                                    <FlexboxItem className="Full_width">
                                        <TextField
                                            title={
                                                <LiMultiterm textId="99033472" textVal="Send e-mail notifications" />
                                            }
                                            className="Text_Field"
                                            isRequired
                                            onChange={handleOnLicenseSettingsChange}
                                            name="noConnEscalation1Time"
                                            value={
                                                selectedCustomerProductionSites?.licenseSettings?.noConnEscalation1Time
                                            }
                                            errorText={
                                                <LiMultiterm textId="99033565" textVal="This is a mandatory field" />
                                            }
                                        />
                                    </FlexboxItem>

                                    <FlexboxItem className="Half_width TextField_container">
                                        <Typography type="ST6">
                                            <LiMultiterm textId="99033471" textVal="Days after" />
                                        </Typography>
                                    </FlexboxItem>
                                </FlexboxContainer>

                                {multipleUserEmailTextFields()}

                                <FlexboxContainer flexDirection="row" gap="3px">
                                    <FlexboxItem>
                                        <TextField
                                            title={
                                                <LiMultiterm
                                                    textId="99033470"
                                                    textVal="Deactivate on-premises license delivery"
                                                />
                                            }
                                            isRequired
                                            onChange={handleOnLicenseSettingsChange}
                                            name="noConnEscalation2Time"
                                            value={
                                                selectedCustomerProductionSites?.licenseSettings?.noConnEscalation2Time
                                            }
                                            errorText={
                                                <LiMultiterm textId="99033565" textVal="This is a mandatory field" />
                                            }
                                        />
                                    </FlexboxItem>
                                    <FlexboxItem className="TextField_container">
                                        <Typography type="ST6">
                                            <LiMultiterm textId="99033471" textVal="Days after" />
                                        </Typography>
                                    </FlexboxItem>
                                </FlexboxContainer>

                                <FlexboxContainer flexDirection="row" alignItems="start" gap="3px">
                                    <FlexboxItem>
                                        <TextField
                                            title={
                                                <LiMultiterm textId="99034102" textVal="Send reminder emails every" />
                                            }
                                            isRequired
                                            className="Text_Field"
                                            onChange={handleOnLicenseSettingsChange}
                                            name="noConnEscalationFrequency"
                                            value={
                                                selectedCustomerProductionSites?.licenseSettings
                                                    ?.noConnEscalationFrequency
                                            }
                                            errorText={
                                                <LiMultiterm textId="99033565" textVal="This is a mandatory field" />
                                            }
                                        />
                                    </FlexboxItem>

                                    <FlexboxItem className="Half_width TextField_container">
                                        <Typography type="ST6">
                                            <LiMultiterm textId="99033471" textVal="Days after" />
                                        </Typography>
                                    </FlexboxItem>
                                </FlexboxContainer>
                            </>
                        ) : null}
                    </FlexboxContainer>
                    <FlexboxItem className="Card_separator" />
                    <FlexboxItem className="Each_side_Modal_wrapper">
                        <Scrollable>
                            {allProducts?.length ? (
                                <MultiList
                                    onChange={(data: Array<IMultiListArrayType>) => handleProductMultiListChange(data)}
                                    title={<LiMultiterm textId="99032447" textVal="Available Products" />}
                                    showToggleForSelectedOnly
                                    data={getDataForAssociatedProducts(
                                        allProducts.map((productionSites: any) => ({
                                            ...productionSites,
                                            id: `p${productionSites.id}`
                                        })),
                                        selectedCustomerProductionSites.customerProducts?.map(
                                            (product: any) => product.productVersionId
                                        ) ?? [],
                                        selectedCustomerProductionSites.id
                                    )}
                                />
                            ) : null}
                        </Scrollable>
                    </FlexboxItem>
                    <FlexboxItem className="Card_separator" />
                    <FlexboxItem className="Each_side_Modal_wrapper">
                        <Scrollable>
                            {dependentFeaturesData?.length ? (
                                <MultiList
                                    onChange={(data: Array<IMultiListArrayType>) => handleFeaturesMultiListChange(data)}
                                    title={<LiMultiterm textId="99032454" textVal="Available features" />}
                                    showToggleForSelectedOnly
                                    data={
                                        selectedCustomerProductionSites
                                            ? getDataForDependentFeatures(
                                                  dependentFeaturesData,
                                                  selectedCustomerProductionSites?.customerFeatures?.map(
                                                      (feature: any) => feature.featureId
                                                  ) ?? [],
                                                  selectedCustomerProductionSites.id,
                                                  []
                                              )
                                            : []
                                    }
                                />
                            ) : null}
                        </Scrollable>
                    </FlexboxItem>
                </FlexboxContainer>
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default ProductionSitesModal;
