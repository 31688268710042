/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Mainframe } from 'application-base';
import axios from 'axios';
// eslint-disable-next-line import/extensions
import { setLocalStorageValue, getLocalStorageValue } from 'lisecutilityfunctions/lib/commonfunction';
import { useAppSelector } from './hooks';
import { routes } from './configs/route';
import './styles/global.css';
import './App.css';

import './configs/config_dev';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line global-require
    require('./configs/config_dev');
} else {
    // eslint-disable-next-line global-require
    require('./configs/config_prod');
}

const rewriteDefaultConfiguration = () => {
    axios
        .get(`${process.env.PUBLIC_URL}/config_prod.json`)
        .then((response) => {
            if (response.data !== undefined) {
                const { config } = response.data;
                const responseURL = response.headers['x-basepath'] ? response.headers['x-basepath'] : '';
                const finalBaseName = responseURL;
                localStorage.setItem('core.app.basename', finalBaseName);
                if (config.baseURL && process.env.NODE_ENV !== 'development') {
                    window.config.configURL.baseURL = config.baseURL;
                }
                if (getLocalStorageValue('core.app.theme') === null) {
                    setLocalStorageValue('core.app.theme', config.theme);
                }
            }
        })
        .catch((error) => {
            console.error(error, 'Error in reading config file');
        });
};
const App = () => {
    useEffect(() => {
        rewriteDefaultConfiguration();
    }, []);

    const { breadCrumbsData } = useAppSelector((state) => state.breadCrumbsSlice);

    return <Mainframe routes={routes} appName="License management" breadCrumbsData={breadCrumbsData} />;
};

export default App;
