import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import OverviewScreen from '../../../../components/overviewScreen/OverviewScreen';
import { associatedProductsHeaderData } from '../../constants/HeaderData';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getAssociatedProducts } from '../../slice/AssociatedProducts';
import { IAssociatedProductVersion } from '../../../../@types/features';
import { setBreadCrumbsData } from '../../../../slice/BreadCrumbs';
import { getMultiTermText } from '../../../../utils/commonUtils';

const AssociatedProducts = ({ globalContext }: any) => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const { setLoader } = globalContext;
    const [associatedProductsRowData, setAssociatedProductsRowData] = useState<Array<IAssociatedProductVersion>>([]);
    const { isLoading, associatedProductsData } = useAppSelector((state) => state.associatedProducts);
    useEffect(() => {
        dispatch(getAssociatedProducts(+params.featureId!));
        dispatch(
            setBreadCrumbsData([
                {
                    label: getMultiTermText({ id: '99032233', text: 'Features' }),
                    key: 1,
                    navigateTo: `/features`
                },
                {
                    label: (
                        <>
                            {getMultiTermText({ id: '99032438', text: 'Associated products' })} : {params.featureId}
                        </>
                    ),
                    key: 2,
                    navigateTo: `/features/associatedProducts/${params.featureId}`
                }
            ])
        );
        return () => {
            dispatch(setBreadCrumbsData([]));
        };
    }, []);

    useEffect(() => {
        setLoader({
            loading: isLoading
        });
    }, [isLoading]);

    const getAssociatedProductsRowData = () => {
        const associatedProducts: any = [];
        if (associatedProductsData.length > 0) {
            associatedProductsData?.forEach((eachProductData) => {
                if (eachProductData.productVersions.length > 0) {
                    eachProductData.productVersions.forEach((eachVersion) => {
                        associatedProducts.push({
                            _key: eachVersion.version,
                            productId: eachProductData.id,
                            productName: eachProductData.name,
                            version: eachVersion.version,
                            enabled: eachVersion.enabled
                                ? getMultiTermText({ id: '99001613', text: 'Yes' })
                                : getMultiTermText({ id: '99000054', text: 'No' })
                        });
                    });
                    return;
                }
                associatedProducts.push({
                    _key: eachProductData.productVersions[0].version,
                    productId: eachProductData.id,
                    productName: eachProductData.name,
                    version: eachProductData.productVersions[0].version,
                    enabled: eachProductData.enabled
                        ? getMultiTermText({ id: '99001613', text: 'Yes' })
                        : getMultiTermText({ id: '99000054', text: 'No' })
                });
            });
        }
        setAssociatedProductsRowData(associatedProducts);
    };

    useEffect(() => {
        getAssociatedProductsRowData();
    }, [associatedProductsData]);

    return (
        <OverviewScreen
            pageHeaderMultiTermId="99032438"
            pageHeaderMultiTermText="Associated products"
            tableClassName="Associated_products_table"
            headerData={associatedProductsHeaderData}
            enableAccordion={false}
            rowsData={associatedProductsRowData}
            initialColumnSortData={[
                {
                    order: 'ascending',
                    columnKey: 'productId'
                }
            ]}
            tableId="associatedProductsTable"
        />
    );
};

export default AssociatedProducts;
