import { JSX } from 'react';
import { getMultiTermText } from '../../../utils/commonUtils';


const hardlockAdminHeaderData =  [
    {
        _key: 'lisecSerialNo',
        label: getMultiTermText({ id: '99015353', text: 'Serial no.' }),
        searching: true,
        sorting: true,
        width: '20%',
        isEditable: false,
    },
    {
        _key: 'id',
        label: getMultiTermText({ id: '99034251', text: 'Hardlock ID' }),
        searching: true,
        sorting: true,
        width: '20%',
        isEditable: false,
    },
    {
        _key: 'typeName',
        label: getMultiTermText({ id: '99034250', text: 'Hardlock type' }),
        searching: true,
        sorting: true,
        width: '20%',
        isEditable: false,
    },
    {
        _key: 'location',
        label: getMultiTermText({ id: '99034254', text: 'Current location' }),
        searching: true,
        sorting: true,
        width: '20%',
        isEditable: false,
    }
];

const hardlockAdminStatusColumn = [
    {
    _key: 'status',
    label: getMultiTermText({ id: '99034252', text: 'Current status' }),
    searching: true,
    sorting: false,
    width: '20%',
    isEditable: true,
    _component: "comboBox",
    optionItems: [
        {
            id: 0,
            name: getMultiTermText({ id: '99034270', text: 'Recycled' }),
        },
        {
            id: 1,
            name: getMultiTermText({ id: '99024851', text: 'Not used' }),
        },
        {
            id: 2,
            name: getMultiTermText({ id: '99019213', text: 'Used' }),
        },
        {
            id: 3,
            name: getMultiTermText({ id: '99034268', text: 'Lost or stolen' }),
        },
        {
            id: 4,
            name: getMultiTermText({ id: '99034269', text: 'Broken' }),
        },
    ],
},]

const hardlockNonAdminStatusColumn = [{
    _key: 'status',
    label: getMultiTermText({ id: '99034252', text: 'Current status' }),
    searching: true,
    sorting: false,
    width: '20%',
    isEditable: false,
    _component: "comboBox",
    optionItems: [
        {
            id: 0,
            name: getMultiTermText({ id: '99034270', text: 'Recycled' }),
        },
        {
            id: 1,
            name: getMultiTermText({ id: '99024851', text: 'Not used' }),
        },
        {
            id: 2,
            name: getMultiTermText({ id: '99019213', text: 'Used' }),
        },
        {
            id: 3,
            name: getMultiTermText({ id: '99034268', text: 'Lost or stolen' }),
        },
        {
            id: 4,
            name: getMultiTermText({ id: '99034269', text: 'Broken' }),
        },
    ],
},]


export const headerData: any =(isAdmin = false) => {
    let headerDataArr: { _key: string; label: JSX.Element; searching: boolean; sorting: boolean; width: string; isEditable: boolean; }[] = [];
    if (isAdmin){
        headerDataArr = hardlockAdminHeaderData.slice(0, 3).concat(hardlockAdminStatusColumn).concat(hardlockAdminHeaderData.slice(3));    }
    else {
        headerDataArr = hardlockAdminHeaderData.slice(0, 3).concat(hardlockNonAdminStatusColumn).concat(hardlockAdminHeaderData.slice(3));
    }
    return headerDataArr

};

export default headerData;
