import React from 'react';

function b64toBlob(b64Data: any, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export default function downloadFile(fileName: string, data: string, type?:string) {
    try{
    const fileData = data;
    // const blob = b64toBlob(fileData, 'application/octet-stream');
    const blob = type && type === 'xml' ? new Blob([data], { type: 'application/xml' }) : b64toBlob(fileData, 'application/octet-stream');
    // Create a URL for the Blob
    const blobUrl = URL.createObjectURL(blob);
    // Create an anchor element
    const link = document.createElement('a');
    // Set the anchor's href attribute to the URL
    link.href = blobUrl;
    // Set the anchor's download attribute to the file name
    link.download = fileName;
    document.body.appendChild(link);
    // Programmatically click the anchor to trigger the download
    link.click();
    // Clean up by revoking the URL
    document.body.removeChild(link);
}
catch(err){
    console.log("Error while downloading", err)
}
}

export const getLocationType = (locationTypeId: number) => {
    switch (locationTypeId) {
        case 0:
            return 'None';
        case 1:
            return 'User';
        case 2:
            return 'Customer';
        case 3:
            return 'Department';
        default:
            return 'None';
    }
};

export const hardLockStatus: { [key: string]: string } = {
    0: 'Recycled',
    1: 'Not Used',
    2: 'Used',
    3: 'Lost/Stolen',
    4: 'Broken'
};
