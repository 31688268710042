/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';

import { FlexboxContainer, FlexboxItem, Typography, Table, Popup } from 'ui-components';
import { ICustomersData, IProductionSitesData } from '../../../../@types/customers';
import { modalType, compareObjects } from '../../../../utils/commonUtils';
import selctedCustomerHeaderData from '../../constants/selctedCustomerHeaderData';
import IconInterfaces from '../../../../assets/Icons/IconInterfaces.svg';
import ProductionSitesAccordion from '../productionSitesAccordion/ProductionSitesAccordion';
import ProductionSitesTableOptions from '../productionSitesTableOptions/ProductionSitesTableOptions';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import AddIconAccordion from '../../../../components/addIconAccordion/AddIconAccordion';
import {
    getDependentFeatures,
    getCustomerBasedOnId,
    getProducts,
    setProductionSitesModalType,
    setProductionSitesModalData,
    patchProductionSites,
    setShowLicenseSettings,
    setSelectedCustomerProductionSites,
    fetchProductionSitesandApiToken,
    getCustomerProducts
} from '../../slice/index';

interface ICustomerAccordion {
    customerData: ICustomersData;
}

const CustomerAccordion = ({ customerData }: ICustomerAccordion) => {
    const dispatch = useAppDispatch();
    const { selectedCustomerData, allCustomersData, selectedCustomerProductionSites, productionSitesModalType } =
        useAppSelector((state) => state.customers);
    const [selectedCustomerTable, setSelectedCustomerTable] = useState([]);
    const [inRowPatchData, setInRowPatchData] = useState<any>({});
    const [active, setActive] = useState(false);
    const [showEmptyPatchWarningMessage, setShowEmptyPatchWarningMessage] = useState<boolean>(false);
    const navigateTo = useNavigate();

    const onHandlePatchRowData = () => {
        const patchData = allCustomersData?.[customerData.id]?.productionSites.find(
            (eachData: IProductionSitesData) => eachData.id === inRowPatchData.id
        );

        if (inRowPatchData?.name === '' || inRowPatchData?.description === '') {
            setShowEmptyPatchWarningMessage(true);
            return;
        }
        const featuresId = selectedCustomerProductionSites?.customerFeatures?.map(
            (products: any) => products.featureId
        );
        const productVersionId = selectedCustomerProductionSites?.customerProducts?.map(
            (products: any) => products.productVersionId
        );
        // Remove duplicates using Set
        const uniqueFeatureIds = [...new Set(featuresId)];
        const uniqueProductVersionIds = [...new Set(productVersionId)];

        const patchPreviousData = {
            id: patchData?.id,
            name: patchData?.name,
            description: patchData?.description,
            sapId: patchData?.sapId,
            modified: patchData?.modified,
            customerId: patchData?.customerId,
            status: patchData?.enabled ? 1 : 2,
            productVersionIds: uniqueProductVersionIds,
            featureIds: uniqueFeatureIds
        };

        const patchUpdatedData = {
            name: inRowPatchData?.name,
            description: inRowPatchData?.description,
            sapId: inRowPatchData?.sapId,
            lastSynchronisation: patchData?.lastSynchronisation,
            modified: patchData?.modified,
            customerId: patchData?.customerId,
            enabled: inRowPatchData?.status === 1
        };

        let isEqual = true;

        isEqual = compareObjects(patchPreviousData, inRowPatchData, [
            '_accordionContent',
            '_key',
            '_option',
            'isSelected',
            'isAccordionOpen',
            'modified',
            'lastSynchronisation',
            'productVersionIds',
            'customerId',
            'featureIds'
        ]);

        if (inRowPatchData.name && !isEqual && patchData?.id) {
            dispatch(patchProductionSites({ body: patchUpdatedData, productionSitesId: patchData?.id }));
        }
    };

    useEffect(() => {
        onHandlePatchRowData();
    }, [inRowPatchData]);

    useEffect(() => {
        dispatch(getCustomerBasedOnId(customerData.id));
    }, []);

    const onAddProductionSites = () => {
        let tempModalData = { ...selectedCustomerProductionSites };
        tempModalData.customerId = customerData.id;
        tempModalData = {
            ...tempModalData,
            licenseSettings: {
                ...tempModalData.licenseSettings,
                noConnEscalation1Time: 1,
                noConnEscalationEMail1: '',
                noConnEscalation2Time: 30,
                noConnEscalationFrequency: 1
            }
        };
        dispatch(setSelectedCustomerProductionSites(tempModalData));
        dispatch(setShowLicenseSettings(true));
        dispatch(setProductionSitesModalType(modalType.ADD));
        dispatch(getProducts());
        dispatch(getDependentFeatures());
    };

    useEffect(() => {
        let openedAccordion = sessionStorage.getItem('accordionOpened') || '{}';
        openedAccordion = JSON.parse(openedAccordion);
        const tempData = allCustomersData?.[customerData.id]?.productionSites.map((item: IProductionSitesData) => ({
            _key: item.id,
            _option: (
                <ProductionSitesTableOptions
                    onClickEdit={() => {
                        dispatch(setProductionSitesModalType(modalType.EDIT));
                        dispatch(setProductionSitesModalData(item));
                    }}
                    onClickTrialPeriods={() => {
                        navigateTo(`/trialPeriod?productionSite=${item.id}`);
                    }}
                />
            ),
            id: item.id,
            name: item.name,
            lastSynchronisation: conversionLib.dateTimeFormatConversion(item.lastSynchronisation, 'datetime'),
            description: item.description,
            sapId: item.sapId,
            status: item.enabled === true ? 1 : 2,
            _accordionContent: <ProductionSitesAccordion productSitesId={item.id} />
        }));
        setSelectedCustomerTable(tempData);
    }, [selectedCustomerData]);

    const onClosePatchWarningMessage = () => {
        setShowEmptyPatchWarningMessage(false);
        dispatch(getCustomerBasedOnId(customerData.id));
    };

    return (
        <FlexboxContainer
            flexDirection="column"
            gap="1rem"
            className="Accordion_wrapper Full_width"
            justifyContent="start"
            alignItems="stretch"
        >
            <FlexboxItem className="Full_width">
                <FlexboxContainer flexDirection="row" justifyContent="space-between">
                    <FlexboxItem>
                        <FlexboxContainer flexDirection="row" gap="0.313rem">
                            <FlexboxItem>
                                <img
                                    src={IconInterfaces}
                                    alt="icon-interface"
                                    className="Product_release_heading_icon"
                                    style={{ height: '30px', width: '30px' }}
                                />
                            </FlexboxItem>
                            <Typography type="B">
                                <LiMultiterm textId="99025706" textVal="Production site" />
                            </Typography>
                        </FlexboxContainer>
                    </FlexboxItem>
                    <FlexboxItem onClick={onAddProductionSites}>
                        <AddIconAccordion isModalActive={productionSitesModalType === modalType.ADD} />
                    </FlexboxItem>
                </FlexboxContainer>
            </FlexboxItem>

            <FlexboxItem className="Full_width Inner_level_one_table">
                <Table
                    className="customers_table"
                    accordion
                    isManualSortEnabled
                    columnSort={[
                        {
                            order: 'ascending',
                            columnKey: 'id'
                        }
                    ]}
                    headerData={selctedCustomerHeaderData}
                    tableHeaderClassNameForStyle="Table_inner_header"
                    isSingleSelectable={false}
                    rowsData={selectedCustomerTable}
                    tableHeaderRowStyles={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1
                    }}
                    isPrintable={false}
                    isMultiSelectable={false}
                    tableConfiguration={{
                        pagination: false,
                        pageOptions: [0],
                        showTableSettings: false,
                        showSearch: false,
                        sortAllRows: false,
                        showRowCount: false
                    }}
                    pagination={false}
                    tableId="selectedcustomersTable"
                    onDataChange={(rowData: any) => setInRowPatchData(rowData)}
                    onAccordionToggle={(data: any) => {
                        if (data?.isAccordionOpen === true) {
                            dispatch(fetchProductionSitesandApiToken(data?.id));
                            dispatch(getDependentFeatures());
                            dispatch(getProducts());
                        }
                    }}
                />
                {showEmptyPatchWarningMessage && (
                    <Popup
                        onClose={onClosePatchWarningMessage}
                        iconPopupStyle={{ padding: '0px' }}
                        mode="modal"
                        popupInnerStyle={{ padding: '5px' }}
                    >
                        <div style={{ padding: '20px' }}>
                            <LiMultiterm textId="99033195" textVal="This field cannot be empty" />
                        </div>
                    </Popup>
                )}
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default CustomerAccordion;
