import React from 'react';
import { FlexboxContainer, FlexboxItem, Typography } from 'ui-components';
import { isAnyOfSubLevelChecked } from '../../utils/productsUtils';
import './AssignedFeatureList.css';

const AssignedFeatureList = ({ data, level = 0 }: any) =>
    data?.map((feature: any) => (
        <FlexboxItem key={feature.id} className="Assigned_featurelist Full_width">
            {isAnyOfSubLevelChecked(feature.sublevel) || feature.checked ? (
                <FlexboxContainer
                    flexDirection="column"
                    alignItems="start"
                    justifyContent="start"
                    gap="0.5rem"
                    className="Full_height"
                >
                    <FlexboxItem
                        className={`Full_width Assigned_MultiList_TreeProducts level-${level} ${
                            level === 0 ? 'Assigned_Parent_MultiLevel' : ''
                        }`}
                    >
                        <Typography
                            additionalClassForStyle={feature.disabled || !feature.checked ? 'Disabled_text' : ''}
                            type={level === 0 ? 'ST3' : 'BS1'}
                        >
                            {feature.id} - {feature.description}
                        </Typography>
                        {feature.sublevel && feature.sublevel.length > 0 && (
                            <AssignedFeatureList data={feature.sublevel} level={level + 1} />
                        )}
                    </FlexboxItem>
                </FlexboxContainer>
            ) : null}
        </FlexboxItem>
    ));

export default AssignedFeatureList;
