import { ITableHeader } from '../../../@types/trialPeriods';
import { getMultiTermText } from '../../../utils/commonUtils';

const headerData: Array<ITableHeader> = [
    {
        _key: 'productionSiteId',
        label: getMultiTermText({ id: '99032599', text: 'Production site ID' }),
        searching: true,
        sorting: true,
        width: '10%',
        isEditable: false,
        columnDataClassName: 'Font_bold',
    },
    {
        _key: 'customer',
        label: getMultiTermText({ id: '9000326', text: 'Customer' }),
        searching: true,
        sorting: true,
        width: '15%',
        isEditable: false,
    },
    {
        _key: 'productionSite',
        label: getMultiTermText({ id: '99025706', text: 'Production site' }),
        searching: true,
        sorting: true,
        width: '10%',
        isEditable: false,
    },
    {
        _key: 'feature',
        label: getMultiTermText({ id: '99032449', text: 'Feature' }),
        searching: true,
        sorting: false,
        width: '15%',
        isEditable: false,
    },
    {
        _key: 'machineSerialNumber',
        label: getMultiTermText({ id: '99032450', text: 'Machine serial number' }),
        searching: true,
        sorting: true,
        width: '10%',
        isEditable: false,
    },
    {
        _key: 'availableFrom',
        label: getMultiTermText({ id: '99032451', text: 'Available from' }),
        searching: true,
        sorting: true,
        type: "date",
        width: '20%',
        isEditable: false,
    },
    {
        _key: 'availableTo',
        label: getMultiTermText({ id: '99032452', text: 'Available to' }),
        searching: true,
        sorting: true,
        type: "date",
        width: '20%',
        isEditable: false,
    }
];

export default headerData;
