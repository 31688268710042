import { getLabelOptions, getMultiTermText } from '../../../utils/commonUtils';
import { ILoginData, IAuthTokenData, ILoginHistoryTableData } from '../../../@types/login';

// Define a function to map and return data
export function hanndlingDataAsPerLogin(login: ILoginData) {
    return {
        ...login,
        authTokenId: login.authTokenId,
        creation: login.timestamp,
        tokenExpiry: '',
        type: 'Login performed',
        authorizations: login.allAuthorizations
            ? getMultiTermText({ id: '09000762', text: 'All' })
            : login.requestedAuthorizations.toString().replace(/,/g, ', ')
    };
}

// Define a function to map and return data
export function hanndlingDataAsPerAuthToken(authToken: IAuthTokenData) {
    return {
        ...authToken,
        authTokenId: authToken.id,
        creation: authToken.created,
        tokenExpiry: authToken.expires,
        machineSerial: '',
        type: 'Authentication token issued',
        authorizations: authToken.authorizations.toString().replace(/,/g, ', ')
    };
}

export const combinedLoginHistory = (array1: Array<ILoginHistoryTableData>, array2: Array<ILoginHistoryTableData>) => [
    ...array1.map((item: ILoginHistoryTableData) => ({ ...item, id: item.id })),
    ...array2.map((item: ILoginHistoryTableData) => ({ ...item, id: item.id + array1.length }))
];
