/* eslint-disable camelcase */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OpenApiBody, MimeTypes } from 'lisecutilityfunctions/lib/openapi';
import { convertToJSON } from 'lisecutilityfunctions/lib/commonfunction';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { IErrorState, setError } from '../../../slice/ErrorHandling';
import OpenApiClient_customer_management_v2 from '../../../api/openapiclient_customer_management_v2';
import {
    ICustomerListData,
    IErrorMessage,
    IHardlockLocationChangeEditData,
    IHardlocks,
    IHardlocksDetailTableData,
    IUsersListData
} from '../../../@types/hardlocks';
import { hardLockChangeLocationTypes, hardlocksModalType } from '../constants/HardlocksConstant';

interface IAddHardlocksModalData {
    c2VFile: string;
    lisecSerialNo: number;
}

interface IPutHardlocksModalData extends IAddHardlocksModalData {
    type: number;
}

const initialHardLockEditData = {
    id: 0,
    locationType: 0,
    location: ''
};

export interface IHardlocksInitialState {
    isLoading: boolean;
    hardlocksData: Array<IHardlocks>;
    hardlocksActionHistoryData: any;
    modalType: any;
    addHardlocksModalData: IAddHardlocksModalData;
    putHardlocksModalData: IPutHardlocksModalData;
    selectedHardlocksData: any;
    errorMessages: IErrorMessage;
    currentSelectedHardlock: number;
    usersListData: Array<IUsersListData>;
    customersListData: Array<ICustomerListData>;
    dropdownSearchLoading: boolean;
    selectedLocationType: number;
    selectedLocationId: number | string;
    selectedLocationName: string;
    locationChangeHardlockData: IHardlockLocationChangeEditData;
    postHardlocksResponseData: any,
    v2cDownloadedData: any,
    latestV2cDownloadedData: any
}

const initialState: IHardlocksInitialState = {
    isLoading: false,
    hardlocksData: [],
    hardlocksActionHistoryData: [],
    modalType: hardlocksModalType.CLOSED,
    addHardlocksModalData: {} as IAddHardlocksModalData,
    putHardlocksModalData: {} as IPutHardlocksModalData,
    selectedHardlocksData: {},
    errorMessages: {},
    currentSelectedHardlock: 0,
    usersListData: [],
    customersListData: [],
    dropdownSearchLoading: true,
    selectedLocationType: hardLockChangeLocationTypes.DEPARTMENT,
    selectedLocationId: 0,
    selectedLocationName: '',
    locationChangeHardlockData: initialHardLockEditData,
    postHardlocksResponseData: {},
    v2cDownloadedData: '',
    latestV2cDownloadedData: ''
};

const handleRejection = (error: any, dispatch: any) => {
    const errorMessage: { [key: string]: string } = {
        403 : "User is not authorized",
        500 : "Internal server error"
    }
    const errorValue: IErrorState = {
        statusCode: error.response?.status ?? 0,
        message: errorMessage?.[error.response?.status] ?? 'Failed to load Hardlocks'
    };
    dispatch(setError(errorValue));
}

/**
 * @function getHardlocks
 * @description Function to fetch all hardlocks data
 */
export const getHardlocks = createAsyncThunk('getHardlocks', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_Hardlock();
        if (response?.status === 204) {
            return [];
        }
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = convertToJSON(response.data);
        return responseData;
    } catch (error: any) {
        return rejectWithValue(handleRejection(error,dispatch))
    }
});

/**
 * @function getUsersData
 * @description Function to fetch list of users data
 */
export const getUsersData = createAsyncThunk('getUsersData', async (userInput: string) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_Users(userInput);
        if (response?.status === 204) {
            return [];
        }
        // if (response?.status !== 200) {
        //     const errorValue: IErrorState = {
        //         statusCode: response?.status,
        //         message: response?.statusText
        //     };
        //     return dispatch(setError(errorValue));
        // }
        const responseData = convertToJSON(response.data);
        return responseData;
    } catch (error: any) {
        // const errorValue: IErrorState = {
        //     statusCode: error.response?.status ?? 0,
        //     message: 'Failed to load Users'
        // };
        // dispatch(setError(errorValue));
        return [];
    }
});

/**
 * @function getCustomersData
 * @description Function to fetch list of users data
 */
export const getCustomersData = createAsyncThunk('getCustomersData', async (userInput: string, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_Customers(
            null,
            null,
            `name=likeci='${userInput}'`
        );
        if (response?.status === 204) {
            return [];
        }
        // if (response?.status !== 200) {
        //     const errorValue: IErrorState = {
        //         statusCode: response?.status,
        //         message: response?.statusText
        //     };
        //     return dispatch(setError(errorValue));
        // }
        const responseData = convertToJSON(response.data);
        return responseData;
    } catch (error: any) {
        // const errorValue: IErrorState = {
        //     statusCode: error.response?.status ?? 0,
        //     message: 'Failed to load Customers'
        // };
        // dispatch(setError(errorValue));
        return [];
    }
});

/**
 * @function getHardlocksActionHistory
 * @param hardlockId to pass as param to filter data based on hardlockId
 * @description Function to fetch hardlocks action history data per hardlockId
 */
export const getHardlocksActionHistory = createAsyncThunk(
    'getHardlocksActionHistory',
    async (hardlockId: number | null, { dispatch }) => {
        const filterData = `hardlockId==${hardlockId}`;

        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).GET_HardlockActionHistory(
                null,
                null,
                filterData,
                '-ActionTimestamp'
            );
            if (response?.status === 204) {
                return [];
            }
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            const responseData = convertToJSON(response.data);
            return responseData;
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'Failed to load Hardlocks ActionHistory'
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function postHardlocks
 * @description Function to do post Hardlocks using object body which contains @property c2VFile & @property lisecSerialNo
 */
export const postHardlocks = createAsyncThunk(
    'postHardlocks',
    async (postHardlocksBody: IAddHardlocksModalData, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).POST_Hardlock(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, postHardlocksBody)
            );
            if (response?.status !== 201) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            const responseData = conversionLib.convertToJSON(response.data);
            dispatch(getHardlocks());
            return responseData;
        } catch (error: any) {
            // Error message is hardcoded as per swagger
            const errorMessage: { [key: string]: string } = {
                400 : "Failed to update C2V File",
                403 : "User is not authorized",
                409 : "Failed to add Hardlock",
                412 : "Failed to add Hardlock",
                500 : "Internal server error"
            }
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: errorMessage?.[error.response?.status] ?? 'Failed to add Hardlock'
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function putHardlocks
 * @description Function to put product
 */
export const putHardlocks = createAsyncThunk(
    'putHardlocks',
    async ({ body, hardlockId }: { body: IPutHardlocksModalData; hardlockId: number }, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).PUT_Hardlock_id_C2VFile(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, body),
                hardlockId
            );
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            return dispatch(getHardlocksActionHistory(hardlockId));
        } catch (error: any) {
            // Error message is hardcoded as per swagger
            const errorMessage: { [key: string]: string } = {
                400 : "Failed to update c2v File",
                403 : "User is not authorized",
                404 : "Hardlock not found",
                409 : "Failed to update C2V File",
                412 : "Failed to update Hardlock",
                500 : "Internal server error"
            }
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: errorMessage?.[error.response?.status] ?? 'Failed to update Hardlock'
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function postHardlockActionHistory
 * @description To update the actionhistory 
 * @param locationChangeData to pass as param to hardlock location change data
 */
export const postHardlockActionHistory = createAsyncThunk(
    'postHardlockActionHistory',
    async (locationChangeData: any, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).POST_HardlockActionHistory(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, locationChangeData)
            );
            if (response?.status !== 200 && response?.status !== 201) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            dispatch(getHardlocksActionHistory(locationChangeData.hardlockId));
            return dispatch(getHardlocks());
        } catch (error: any) {
            // Error message is hardcoded as per swagger
            const errorMessage: { [key: string]: string } = {
                400 : "Failed to update",
                403 : "User is not authorized",
                404 : "Hardlock not found for given hardlock id",
                409 : "Failed to update C2V File",
                500 : "Internal server error"
            }
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: errorMessage?.[error.response?.status] ?? 'Failed to update'
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function getHardlockV2C
 * to download the current selecetd action history v2c file
 * @param hardlockActionHistoryData object contains hardlockID & hardlockActionHistoryId which is to pass as param of selected action history.
 */
 export const getHardlockV2C = createAsyncThunk(
    'getHardlockV2C',
    async (hardlockActionHistoryData: any, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).GET_HardlockActionHistory_id_V2CFile(hardlockActionHistoryData.hardlockActionHistoryId
            );
            if (response?.status !== 200 && response?.status !== 201) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            dispatch(getHardlocksActionHistory(hardlockActionHistoryData.hardlockId));
            const responseData = response?.data;
            return responseData;
        } catch (error: any) {
            // Error message is hardcoded as per swagger
            const errorMessage: { [key: string]: string } = {
                400 : "Failed to update",
                403 : "User is not authorized",
                404 : "Hardlock Action history not found for given id",
                500 : "Internal server error"
            }
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: errorMessage?.[error.response?.status] ?? 'Failed to update'
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function getLatestV2CDownload
 * to download the latest generated v2c file from selecetd hardlock entry
 * @param hardlockId hardlockID which is to pass as param of selected hardlock.
 */
 export const getLatestV2CDownload = createAsyncThunk(
    'getLatestV2CDownload',
    async (hardlockId: any, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).GET_Hardlock_id_LatestV2C(hardlockId);
            if (response?.status !== 200 && response?.status !== 201) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                dispatch(setError(errorValue));
                return ""
            }
            dispatch(getHardlocksActionHistory(hardlockId));
            const responseData = response?.data;
            return responseData;
        } catch (error: any) {
            // Error message is hardcoded as per swagger
            const errorMessage: { [key: string]: string } = {
                400 : "Failed to download",
                403 : "User is not authorized",
                404 : "Hardlock Action history not found for given id",
                500 : "Internal server error"
            }
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: errorMessage?.[error.response?.status] ?? 'Failed to download'
            };
            return dispatch(setError(errorValue));
        }
    }
);

const hardlocksSlice = createSlice({
    name: 'hardlocks',
    initialState,
    reducers: {
        setHardlocksData: (state, action: PayloadAction<Array<IHardlocks>>) => {
            state.hardlocksData = action.payload;
        },
        setHardlocksModalType: (state, action: PayloadAction<string>) => {
            state.modalType = action.payload;
            if (action.payload === hardlocksModalType.CLOSED) {
                state.addHardlocksModalData = {} as IAddHardlocksModalData;
                state.putHardlocksModalData = {} as IPutHardlocksModalData;
            }
        },
        setCurrentSelectedHardlock: (state, action: PayloadAction<number>) => {
            state.currentSelectedHardlock = action.payload;
        },
        setDropdownSearchLoading: (state, action: PayloadAction<boolean>) => {
            state.dropdownSearchLoading = action.payload;
        },
        setAddHardlocksModalData: (state, action: PayloadAction<IAddHardlocksModalData>) => {
            state.addHardlocksModalData = action.payload;
        },
        setPutHardlocksModalData: (state, action: PayloadAction<IPutHardlocksModalData>) => {
            state.putHardlocksModalData = action.payload;
        },
        setSelectedLocationType: (state, action: PayloadAction<number>) => {
            state.selectedLocationType = action.payload;
            state.selectedLocationId = ""
            state.selectedLocationName = ""
        },
        setSelectedLocationId: (state, action: PayloadAction<number>) => {
            state.selectedLocationId = action.payload;
        },
        setSelectedLocationName: (state, action: PayloadAction<string>) => {
            state.selectedLocationName = action.payload;
        },
        setLocationChangeHardlock: (state, action: PayloadAction<IHardlockLocationChangeEditData>) => {
            state.locationChangeHardlockData = action.payload;
            state.selectedLocationType = action.payload.locationType;
            state.selectedLocationId = action.payload.location;
        },
        clearPostHardlocksResponseData: (state, action: PayloadAction<any>) => {
            state.postHardlocksResponseData = {};
        },
        clearV2cDownloadedData: (state) => {
            state.latestV2cDownloadedData = '';
            state.v2cDownloadedData = '';
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getHardlocks.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getHardlocks.fulfilled, (state, action: PayloadAction<Array<IHardlocks>>) => {
            state.isLoading = false;
            state.hardlocksData = action.payload;
        });
        builder.addCase(getHardlocks.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getUsersData.pending, (state) => {
            state.dropdownSearchLoading = true;
        });
        builder.addCase(getUsersData.fulfilled, (state, action: PayloadAction<Array<IUsersListData>>) => {
            state.dropdownSearchLoading = false;
            state.usersListData = action.payload;
        });
        builder.addCase(getUsersData.rejected, (state) => {
            state.dropdownSearchLoading = false;
            state.usersListData = [];
        });
        builder.addCase(getCustomersData.pending, (state) => {
            state.dropdownSearchLoading = true;
        });
        builder.addCase(getCustomersData.fulfilled, (state, action: PayloadAction<Array<ICustomerListData>>) => {
            state.dropdownSearchLoading = false;
            state.customersListData = action.payload;
        });
        builder.addCase(getCustomersData.rejected, (state) => {
            state.dropdownSearchLoading = false;
            state.customersListData = [];
        });
        builder.addCase(postHardlockActionHistory.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(postHardlockActionHistory.fulfilled, (state) => {
            state.isLoading = false;
            state.modalType = hardlocksModalType.CLOSED;
        });
        builder.addCase(postHardlockActionHistory.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getHardlocksActionHistory.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(
            getHardlocksActionHistory.fulfilled,
            (state, action: PayloadAction<Array<IHardlocksDetailTableData>>) => {
                state.isLoading = false;
                const hardlocksActionHistoryDataObj = {
                    ...state.hardlocksActionHistoryData,
                    [state.currentSelectedHardlock]: action.payload
                };
                state.hardlocksActionHistoryData = hardlocksActionHistoryDataObj;
            }
        );
        builder.addCase(getHardlocksActionHistory.rejected, (state) => {
            state.isLoading = false;
        });

        builder.addCase(postHardlocks.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(postHardlocks.fulfilled, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.postHardlocksResponseData = action.payload;
            state.modalType = hardlocksModalType.CLOSED;
            state.addHardlocksModalData = {} as IAddHardlocksModalData;
        });
        builder.addCase(postHardlocks.rejected, (state) => {
            state.isLoading = false;
        });

        builder.addCase(putHardlocks.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(putHardlocks.fulfilled, (state) => {
            state.isLoading = false;
            state.modalType = hardlocksModalType.CLOSED;
            state.putHardlocksModalData = {} as IPutHardlocksModalData;
        });
        builder.addCase(putHardlocks.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getHardlockV2C.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getHardlockV2C.fulfilled, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.v2cDownloadedData = action.payload

        });
        builder.addCase(getHardlockV2C.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getLatestV2CDownload.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getLatestV2CDownload.fulfilled, (state,action: PayloadAction<any>) => {
            state.isLoading = false;
            state.latestV2cDownloadedData = action.payload
        });
        builder.addCase(getLatestV2CDownload.rejected, (state) => {
            state.isLoading = false;
        });
        
    }
});

export const {
    setHardlocksData,
    setHardlocksModalType,
    setCurrentSelectedHardlock,
    setAddHardlocksModalData,
    setPutHardlocksModalData,
    setSelectedLocationType,
    setSelectedLocationId,
    setSelectedLocationName,
    setLocationChangeHardlock,
    clearPostHardlocksResponseData,
    setDropdownSearchLoading,
    clearV2cDownloadedData
} = hardlocksSlice.actions;
export default hardlocksSlice.reducer;
