/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { TextLabel } from 'ui-components';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';

import {
    IDependentFeatures,
    IMultiListArrayType,
    IAssociatedProducts,
    IMultiListProductArrayType
} from '../@types/customers';

import { IAccordionObject, IRest } from '../@types/common';
import { IPostProductVersionData } from '../@types/products';

type EscalationObject = {
    [key: string]: string | number | undefined;
};

export const getMultiTermText = ({ id, text }: { id: string; text: string }) => (
    <LiMultiterm textId={id} textVal={text} />
);

export const flattenMultiList = (data: Array<IMultiListArrayType>) =>
    data.reduce((r: Array<IRest>, { sublevel, ...rest }) => {
        r.push(rest);
        if (sublevel) r.push(...flattenMultiList(sublevel));
        return r;
    }, []);

export const modalType = {
    CLOSED: 'CLOSED',
    ADD: 'ADD',
    EDIT: 'EDIT',
    DELETE: 'DELETE',
    COPY: 'COPY',
    ADD_VERSION: 'ADD_VERSION',
    ADD_TOKEN: 'ADD_TOKEN'
};

export const isObjectEntriesSame = (newObject: any, oldObject: any) =>
    Object.keys(newObject).every((eachKey) => newObject[eachKey] === oldObject[eachKey]);

export const setAccordionOpenedId = (key: string, value: string, type: string, data: any) => {
    const accordionData = sessionStorage.getItem('accordionOpened');
    let accordionObj: IAccordionObject = {};
    if (accordionData) {
        accordionObj = JSON.parse(accordionData);
    }
    let obj: IAccordionObject = {};
    let arrayValue: Array<string> = [...new Set([])];
    if (data?.isAccordionOpen === false && accordionObj && accordionObj[type] && accordionObj[type][key]) {
        const isValuePresent = accordionObj[type][key].includes(value);
        if (isValuePresent) {
            const updatedArray = accordionObj[type][key].filter((item: any) => item !== value);
            accordionObj[type][key] = updatedArray;
            obj = accordionObj;
            return obj;
        }
    }
    if (accordionObj) {
        if (Object.prototype.hasOwnProperty.call(accordionObj, type)) {
            if (Object.prototype.hasOwnProperty.call(accordionObj[type], key)) {
                if (!accordionObj[type][key].includes(value)) {
                    arrayValue = [...accordionObj[type][key], value];
                    accordionObj[type][key] = arrayValue;
                    obj = accordionObj;
                }
            } else {
                arrayValue = [value];
                accordionObj[type][key] = arrayValue;
                obj = accordionObj;
            }
        } else {
            arrayValue = [value];
            accordionObj[type] = {};
            accordionObj[type][key] = arrayValue;
            obj = accordionObj;
        }
    } else {
        arrayValue = [...arrayValue, value];
        obj[type] = {};
        obj[type][key] = arrayValue;
    }
    return obj;
};

export const getLabelOptions = ({ id, text, className }: { id: string; text: string; className: string }) => (
    <TextLabel className={className} text={<LiMultiterm textId={id} textVal={text} />} />
);

export function extractEmails(obj: EscalationObject): { id: string; email: string }[] {
    const emailEntries = Object.entries(obj)
        .filter(([key]) => key.startsWith('noConnEscalationEMail'))
        .map(([key, email]) => ({ id: key, email: email as string }));

    return emailEntries;
}

const getMultiListItemTooltipText = (
    featureData: IDependentFeatures,
    childDependentIds: Array<number>,
    featureId: number
) => {
    let toolTipText: JSX.Element | null = null;
    if (!featureData.enabled) {
        toolTipText = <LiMultiterm textId="99032735" textVal="Feature is disabled" />;
    } else if (featureData.id === featureId || childDependentIds.includes(featureData.id)) {
        toolTipText = (
            <LiMultiterm
                textId="99032756"
                textVal="Cannot select this feature as it would result in a self dependency"
            />
        );
    } else {
        toolTipText = null;
    }
    return toolTipText;
};

export const getDataForDependentFeatures = (
    dependentFeaturesData: Array<IDependentFeatures>,
    dependentOn: Array<number>,
    featureId: number,
    childDependentIds: Array<number>
): Array<IMultiListArrayType> => {
    const treeListConfigData = dependentFeaturesData?.map((featureData) => ({
        id: featureData.id,
        descriptionPrefix: `${featureData.id}`,
        disabled: !featureData.enabled || featureData.id === featureId || childDependentIds.includes(featureData.id),
        description: getMultiTermText({ id: `${featureData.multitermId}`, text: featureData.description }),
        sublevel: getDataForDependentFeatures(
            featureData.featureTrees ?? [],
            dependentOn,
            featureId,
            childDependentIds
        ),
        name: featureData.name,
        checked: dependentOn.includes(featureData.id),
        toolTipText: getMultiListItemTooltipText(featureData, childDependentIds, featureId)
    }));
    return treeListConfigData;
};

export const getDataForDependentProducts = (
    dependentFeaturesData: Array<IAssociatedProducts>,
    dependentOn: Array<number>,
    featureId: number,
    childDependentIds: Array<number>
): Array<IMultiListArrayType> =>
    dependentFeaturesData?.map((featureData) => ({
        id: featureData.id,
        descriptionPrefix: `${featureData.id}`,
        disabled: !featureData.enabled || featureData.id === featureId || childDependentIds.includes(featureData.id),
        description: getMultiTermText({ id: `${featureData.multitermId}`, text: featureData.description }),
        sublevel: getDataForDependentProducts(
            featureData.productVersions ?? [],
            dependentOn,
            featureId,
            childDependentIds
        ),
        name: featureData.name,
        checked: dependentOn.includes(featureData.id)
    }));

const getDataForAssociatedProductsVersions = (
    productVersions: Array<IAssociatedProducts>,
    dependentOn: Array<number>,
    featureId: number
) =>
    productVersions.map((version: any) => ({
        id: version.id,
        disabled: !version.enabled || version.id === featureId,
        description: version.version,
        descriptionPrefix: version.id,
        checked: dependentOn.includes(version.id),
        sublevel: [],
        name: version.name,
        toolTipText:
            !version.enabled || version.id === featureId ? (
                <LiMultiterm textId="99032734" textVal="Product is disabled" />
            ) : (
                ''
            )
    }));

export const getDataForAssociatedProducts = (
    dependentProductsData: Array<IAssociatedProducts>,
    dependentOn: Array<number>,
    featureId: number
): Array<IMultiListProductArrayType> =>
    dependentProductsData?.map((products: IAssociatedProducts) => ({
        id: products.id,
        disabled: true,
        descriptionPrefix: `${products.id}`,
        description: products.name,
        sublevel: getDataForAssociatedProductsVersions(products.productVersions ?? [], dependentOn, featureId),
        name: products.name,
        checked: dependentOn.includes(products.id)
    }));

export const getChildDependentData = (dependentFeatures: Array<IDependentFeatures>, featureId: number) => {
    const filterTreesData: Array<IDependentFeatures> = [];
    const filterDepData = (depData: Array<IDependentFeatures> = []) => {
        depData.forEach((eachData) => {
            if (eachData.id === featureId) {
                if (eachData.featureTrees?.length) {
                    filterTreesData.push(...eachData.featureTrees);
                }
            } else {
                filterDepData(eachData.featureTrees);
            }
        });
    };
    filterDepData(dependentFeatures);
    const filteredIds: Array<number> = [];
    const getFilteredIds = (dependentData: Array<IDependentFeatures> = []) => {
        dependentData.forEach((item) => {
            filteredIds.push(item.id);
            if (item.featureTrees?.length) {
                getFilteredIds(item.featureTrees);
            }
        });
    };
    getFilteredIds(filterTreesData);
    return [...new Set(filteredIds)];
};

export function transformProductVersionObj(
    arr: { checked: boolean; description: any; id: number }[],
    productId = 0
): IPostProductVersionData {
    const enabledFeatures = arr.filter((x) => x.checked).map((x) => x.id);
    const filteredEnabledFeatures = [...new Set(enabledFeatures)];
    const resultObj: IPostProductVersionData = {
        id: 0,
        productId,
        version: new Date().toISOString(),
        modified: new Date().toISOString(),
        enabled: true,
        productFeatures: filteredEnabledFeatures.map((id) => ({
            id: 0,
            productVersionId: 0,
            featureId: id,
            sequence: 0,
            version: new Date().toISOString(),
            modified: new Date().toISOString()
        }))
    };

    return resultObj;
}

/** @function compareObjects
 * @description Function to check if 2 objects key value is matching, returns falls if not.
 * @param obj1 & @param obj2 are 2 objects to compare @param excludedProperty is an array if any specific key(s) to be ignored while comparing.
 */

export function compareObjects(obj1: any, obj2: any, excludedProperty: any[]) {
    const keys1 = Object.keys(obj1).filter((key) => !excludedProperty.includes(key));
    const keys2 = Object.keys(obj2).filter((key) => !excludedProperty.includes(key));

    if (keys1.length !== keys2.length) {
        return false;
    }

    const keysEqual = keys1.every((key) => obj1[key] === obj2[key]);
    if (!keysEqual) {
        return false;
    }
    return true;
}

export const getToday = () => {
    const isoToday = new Date().toISOString();
    return isoToday.split('T')[0];
};

export const toBase64 = (file: Blob) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
