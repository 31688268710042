/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { FlexboxContainer, FlexboxItem } from 'ui-components';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import IconAlert from '../../assets/Icons/IconAlert.svg';

interface IDeleteModalContent {
    isDeletePermanentMessage: boolean
}

const DeleteModalContent = ({isDeletePermanentMessage}: IDeleteModalContent) => (
    <FlexboxContainer flexDirection="column" alignItems="start" gap="1.25rem">
        <FlexboxItem className="Full_width">
            <FlexboxContainer className="Full_width" justifyContent="center">
                <img alt="alert-icon" src={IconAlert} style={{ height: '70px', width: '70px' }} />
            </FlexboxContainer>
        </FlexboxItem>
        <FlexboxItem className="Full_width">
            <FlexboxContainer flexDirection="column" gap="1.25rem">
                <FlexboxItem>
                    <b>
                        <LiMultiterm textId="99017578" textVal="Do you really want to delete the item?" />
                    </b>
                </FlexboxItem>
                {isDeletePermanentMessage &&<FlexboxItem>
                    <LiMultiterm
                        textId="99033141"
                        textVal="It will be deleted immediately. This action cannot be reversed."
                    />
                </FlexboxItem>}
            </FlexboxContainer>
        </FlexboxItem>
    </FlexboxContainer>
);

export default DeleteModalContent;
