import React, { useEffect } from 'react';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import loginHistoryTableHeaderData from './constants/HeaderData';

import { useAppDispatch, useAppSelector } from '../../hooks';
import OverviewScreen from '../../components/overviewScreen/OverviewScreen';
import { fetchCombinedData } from './slice/LoginSlice';
import { ILoginHistoryTableData, ILoginsInitialState } from '../../@types/login';
import LoginHistoryAccordion from './components/loginHistoryAccordion/LoginHistoryAccordion';
import { RootState } from '../../store';
import { setAccordionOpenedId } from '../../utils/commonUtils';
import { accordionLevel1, loginHistoryType } from '../../utils/appConstants';
import { IAccordionObject } from '../../@types/common';

const LoginHistory = ({ globalContext }: any) => {
    const dispatch = useAppDispatch();
    const { isLoading, loginHistory } = useAppSelector((state: RootState) => state.loginHistory) as ILoginsInitialState;

    const { setLoader } = globalContext;

    useEffect(() => {
        setLoader({
            loading: isLoading
        });
    }, [isLoading]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(fetchCombinedData());
            } catch (error) {
                // Handle error as needed
            }
        };
        fetchData();
    }, [dispatch]);

    const getLoginHistoryTableData = (loginData: Array<ILoginHistoryTableData>) => {
        const accordionOpenedData = sessionStorage.getItem('accordionOpened');
        let openedAccordion: IAccordionObject = {};
        if (accordionOpenedData) {
            openedAccordion = JSON.parse(accordionOpenedData);
        }
        return loginData.map((eachData: ILoginHistoryTableData) => {
            let isAccordionOpened = false;
            if (
                openedAccordion &&
                openedAccordion[loginHistoryType] &&
                openedAccordion[loginHistoryType][accordionLevel1]
            ) {
                const openedData = openedAccordion[loginHistoryType][accordionLevel1].map((id) => id.toString());
                if (openedData.includes(eachData.id.toString())) {
                    isAccordionOpened = true;
                }
            }
            return {
                _key: eachData.id,
                authTokenId: eachData.authTokenId,
                created: conversionLib.dateTimeFormatConversion(eachData.creation, 'datetime'),
                tokenexpired: eachData.tokenExpiry
                    ? conversionLib.dateTimeFormatConversion(eachData.tokenExpiry, 'datetime')
                    : '',
                user: eachData.user,
                type: eachData.type,
                machineSerial: eachData.machineSerial,
                customer: eachData.customerId,
                authorizations: eachData.authorizations,
                isAccordionOpen: isAccordionOpened,
                _accordionContent: <LoginHistoryAccordion permissionsData={eachData.authorizations} />,
                _datesToSort: {
                    created: eachData.creation,
                    tokenexpired: eachData.tokenExpiry
                }
            };
        });
    };

    const onHandleAccordionToggle = (data: any) => {
        const obj = JSON.stringify(setAccordionOpenedId(accordionLevel1, data.authTokenId, loginHistoryType, data));
        sessionStorage.setItem('accordionOpened', obj);
    };

    return (
        <OverviewScreen
            pageHeaderMultiTermId="99034136"
            pageHeaderMultiTermText="Login History"
            tableClassName="login_table"
            headerData={loginHistoryTableHeaderData}
            rowsData={getLoginHistoryTableData(loginHistory)}
            initialColumnSortData={[
                {
                    order: 'descending',
                    columnKey: 'created'
                }
            ]}
            tableId="loginhistoryTable"
            enableAccordion
            onAccordionToggle={onHandleAccordionToggle}
        />
    );
};

export default LoginHistory;
