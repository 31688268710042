/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import {
    FlexboxContainer,
    FlexboxItem,
    Typography,
    TextField,
    ToggleSwitch,
} from 'ui-components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import modalType from '../../../../constants/appConstants';

import { getDependentFeatures } from '../../../Customers/slice';
import {
    getLatestProductId,
    setProductModalData,
} from '../../slice';

const ProductModal = () => {
    const {
        productModalType,
        productsModalData,
        latestProductId,
    } = useAppSelector((state) => state.products);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getDependentFeatures());
        dispatch(getLatestProductId());
    }, []);

    useEffect(() => {
        if (productModalType === modalType.ADD) {
            const tempModalData = { ...productsModalData };
            tempModalData.id = latestProductId ? latestProductId + 1 : 1;
            dispatch(setProductModalData(tempModalData));
        }
    }, [latestProductId]);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempModalData = { ...productsModalData };
        if (event.target.name === 'multitermId' || event.target.name === 'id') {
            tempModalData = {
                ...tempModalData,
                [event.target.name]: event.target.value.replace(/\D/g, '')
            };
            dispatch(setProductModalData(tempModalData));
        } else {
            tempModalData = {
                ...tempModalData,
                [event.target.name]: event.target.value
            };
            dispatch(setProductModalData(tempModalData));
        }
    };

    const handleOnToggleChange = (name: string, value: boolean) => {
        let tempModalData = { ...productsModalData };
        tempModalData = {
            ...tempModalData,
            [name]: value
        };
        dispatch(setProductModalData(tempModalData));
    };

    return (
        <FlexboxContainer
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="0.5rem"
            className="Full_height"
        >
            <FlexboxItem>
                <Typography type="H1">
                    {productModalType === modalType.ADD ? (
                        <LiMultiterm textId="99032583" textVal="Add product" />
                    ) : (
                        <LiMultiterm textId="99032440" textVal="Edit product" />
                    )}
                </Typography>
            </FlexboxItem>
            <FlexboxItem>
                <FlexboxItem className="Modal_each_side_wrapper">
                    <FlexboxContainer flexDirection="column" gap="1.25rem" alignItems="start">
                        <FlexboxItem className="Full_width">
                            <TextField
                                className="Id_input"
                                title={<LiMultiterm textId="99008287" textVal="ID#" />}
                                isRequired
                                disabled={productModalType === modalType.EDIT}
                                onChange={handleOnChange}
                                name="id"
                                errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                                value={productsModalData?.id}
                            />
                        </FlexboxItem>
                        <FlexboxItem className="Full_width">
                            <TextField
                                className="Sap_id_input"
                                title={<LiMultiterm textId="99031088" textVal="SAP ID" />}
                                onChange={handleOnChange}
                                name="sapId"
                                value={productsModalData?.sapId}
                            />
                        </FlexboxItem>
                        <FlexboxItem className="Full_width">
                            <TextField
                                className="Name_input"
                                title={<LiMultiterm textId="6001104" textVal="Name" />}
                                onChange={handleOnChange}
                                name="description"
                                value={productsModalData?.description}
                                errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                            />
                        </FlexboxItem>
                        <FlexboxItem className="Full_width">
                            <TextField
                                className="Short_name_input"
                                title={<LiMultiterm textId="99002657" textVal="Short name" />}
                                isRequired
                                onChange={handleOnChange}
                                name="name"
                                value={productsModalData?.name}
                                errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                            />
                        </FlexboxItem>
                        {/* <FlexboxItem className="Full_width">
                            <TextField
                                className="Id_input"
                                title={<LiMultiterm textId="99034224" textVal="Hardlock product Id" />}
                                isRequired
                                disabled={productModalType === modalType.EDIT}
                                onChange={handleOnChange}
                                name="hardlockProductId"
                                errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                                value={productsModalData?.hardlockProductId}
                            />
                        </FlexboxItem> */}
                        <FlexboxItem className="Full_width">
                            <ToggleSwitch
                                checked={productsModalData?.enabled}
                                value={productsModalData?.enabled}
                                className="Toggle_switch Enabled_toggle_switch"
                                onChange={(data: boolean) => handleOnToggleChange('enabled', data)}
                                switchPrefix={<LiMultiterm textId="99000784" textVal="Enabled" />}
                            />
                        </FlexboxItem>
                        {/* <FlexboxItem className="Full_width"> // Check - NG-20886
                            <ToggleSwitch
                                checked={productsModalData?.machineDependent}
                                value={productsModalData?.machineDependent}
                                className="Toggle_switch Commercial_toggle_switch"
                                onChange={(data: boolean) => handleOnToggleChange('commercial', data)}
                                switchPrefix={
                                    <LiMultiterm textId="99032437" textVal="Requires machine serial number" />
                                }
                            />
                        </FlexboxItem> */}
                        <FlexboxItem className="Full_width">
                            <ToggleSwitch
                                checked={productsModalData?.isHardlockControlled}
                                value={productsModalData?.isHardlockControlled}
                                className="Toggle_switch Commercial_toggle_switch"
                                onChange={(data: boolean) => handleOnToggleChange('isHardlockControlled', data)}
                                switchPrefix={<LiMultiterm textId="99034223" textVal="Hardlock controlled" />}
                            />
                        </FlexboxItem>
                    </FlexboxContainer>
                </FlexboxItem>
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default ProductModal;
