import { getMultiTermText } from '../../../utils/commonUtils';

export default [
    {
        _key: 'serialNo',
        label: getMultiTermText({ id: '99015353', text: 'Serial no.' }),
        searching: true,
        sorting: true,
        type: 'string',
        width: '25%',
        isEditable: false
    },
    {
        _key: 'hardlockId',
        label: getMultiTermText({ id: '99034251', text: 'Hardlock ID' }),
        searching: true,
        sorting: true,
        type: 'string',
        width: '30%',
        isEditable: false
    },
    {
        _key: 'hardlockType',
        label: getMultiTermText({ id: '99034169', text: 'Hardlock type' }),
        searching: true,
        sorting: true,
        type: 'string',
        width: '30%',
        isEditable: false
    },
    {
        _key: 'assignedProducts',
        label: getMultiTermText({ id: '99034264', text: 'Assigned products' }),
        searching: true,
        sorting: true,
        type: 'string',
        width: '15%',
        isEditable: false,
        showToolTip: true
    }
];
