import React, { useEffect, useState } from 'react';

import OverviewScreen from '../../components/overviewScreen/OverviewScreen';
import hardLockTypeHeaderData from './constants/HeaderData';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    getHardLockType,
    patchHardLockType,
    postHardLockType,
    putHardLockType,
    setHardLockModalData,
    setHardLockTypeModalType
} from './slice';
import { isObjectEntriesSame, modalType } from '../../utils/commonUtils';
import ActionModal from '../../components/actionModal/ActionModal';
import HardLockTypeModal from './components/hardLockTypeModal/HardLockTypeModal';
import HardLockTypeTableOptions from './components/hardLockTypeTableOptions/HardLockTypeTableOptions';

const HardLockTypes = ({ globalContext, useAuth }: any) => {
    const [inRowPatchData, setInRowPatchData] = useState<any>({});
    const [showEmptyPatchWarningMessage, setShowEmptyPatchWarningMessage] = useState<boolean>(false);
    const [hasAdminRights, setHasAdminRights] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const authData = useAuth();

    const { isLoading, hardLockTypeData, hardLockTypeModalType, hardLockTypeModalData } = useAppSelector(
        (state) => state.hardLockTypes
    );

    useEffect(() => {
        dispatch(getHardLockType());
        setHasAdminRights(authData.isFeatureAuthorized(75) ?? false);
    }, []);

    const { setLoader } = globalContext;

    useEffect(() => {
        setLoader({
            loading: isLoading
        });
    }, [isLoading]);

    const onHandlePatchRowData = () => {
        const patchData = hardLockTypeData.find((eachData) => eachData.id === inRowPatchData.id);
        if (inRowPatchData?.name === '') {
            setShowEmptyPatchWarningMessage(true);
            return;
        }
        const patchPreviousData = {
            name: patchData?.name,
            hasTimeFeature: patchData?.hasTimeFeature,
            hasNetFeature: patchData?.hasNetFeature,
            maxConcurrentUsers: patchData?.maxConcurrentUsers
        };
        const patchUpdatedData = {
            name: inRowPatchData?.name,
            hasTimeFeature: inRowPatchData?.hasTimeFeature,
            hasNetFeature: inRowPatchData?.hasNetFeature,
            maxConcurrentUsers: inRowPatchData?.maxConcurrentUsers
        };
        if (!isObjectEntriesSame(patchUpdatedData, patchPreviousData) && patchData?.id) {
            dispatch(patchHardLockType({ body: patchUpdatedData, hardLockTypeId: patchData?.id }));
        }
    };

    useEffect(() => {
        onHandlePatchRowData();
    }, [inRowPatchData]);

    const onClosePatchWarningMessage = () => {
        setShowEmptyPatchWarningMessage(false);
        dispatch(getHardLockType());
    };

    const getTableData = () =>
        hardLockTypeData.map((eachData) => ({
            _key: eachData.id,
            id: eachData.id,
            name: eachData.name,
            hasTimeFeature: eachData.hasTimeFeature,
            hasNetFeature: eachData.hasNetFeature,
            maxConcurrentUsers: eachData.maxConcurrentUsers,
            _option: hasAdminRights ? (
                <HardLockTypeTableOptions
                    onClickEdit={() => {
                        dispatch(setHardLockTypeModalType(modalType.EDIT));
                        dispatch(setHardLockModalData(eachData));
                    }}
                />
            ) : (
                []
            )
        }));

    const onAddHardLockTypeButtonClick = () => {
        dispatch(setHardLockTypeModalType(modalType.ADD));
    };

    const onCloseFeatureModal = () => {
        dispatch(setHardLockTypeModalType(modalType.CLOSED));
    };

    const onClickHardLockTypeAction = () => {
        switch (hardLockTypeModalType) {
            case modalType.ADD: {
                dispatch(postHardLockType(hardLockTypeModalData));
                break;
            }
            case modalType.EDIT: {
                dispatch(
                    putHardLockType({ body: hardLockTypeModalData, hardLockTypeId: `${hardLockTypeModalData.id}` })
                );
                break;
            }
            default:
                break;
        }
    };

    return (
        <>
            <OverviewScreen
                pageHeaderMultiTermId="99034169"
                pageHeaderMultiTermText="Hardlock type"
                tableClassName="Hard_lock_type_table"
                headerData={hardLockTypeHeaderData}
                rowsData={getTableData()}
                initialColumnSortData={[
                    {
                        order: 'ascending',
                        columnKey: 'id'
                    }
                ]}
                tableId="hardLockTypeTable"
                addButtonMultiTermId={hasAdminRights? "99034174" : ""}
                addButtonMultiTermText="Add hardlock type"
                enableAccordion={false}
                addButtonOnClick={onAddHardLockTypeButtonClick}
                showEmptyPatchWarningMessage={showEmptyPatchWarningMessage}
                onPatchRowData={(rowData) => setInRowPatchData(rowData)}
                onClosePatchWarningMessage={onClosePatchWarningMessage}
            />
            {hardLockTypeModalType !== modalType.CLOSED && (
                <ActionModal
                    onClickAction={onClickHardLockTypeAction}
                    onClickCancelClose={onCloseFeatureModal}
                    actionType={hardLockTypeModalType}
                    modalColumnNumber={1}
                    ModalContent={<HardLockTypeModal />}
                    actionDisabled={!hardLockTypeModalData.name}
                />
            )}
        </>
    );
};

export default HardLockTypes;
