/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import { FlexboxContainer, FlexboxItem, Dropdown } from 'ui-components';
import { patchCustomerProduct, putCustomerProduct } from '../../slice/index';
import { ICustomerProductsHardlock } from '../../../../@types/customers';
import { useAppDispatch } from '../../../../hooks';

interface IHardlockProductionSitesAccordion {
    customerProducts: Array<number>;
    allCustomerProducts: Array<ICustomerProductsHardlock>;
    hardlockId: number;
}

const HardlockProductionSitesAccordion = ({
    customerProducts,
    allCustomerProducts,
    hardlockId
}: IHardlockProductionSitesAccordion) => {
    const dispatch = useAppDispatch();
    const [dropdownItems, setDropdownItems] = useState<Array<any>>([]);
    const generateCustomersProductsDropdown = (customers: Array<any>, customerProduct: any) => {
        const selectedCustomerProducts = JSON.parse(JSON.stringify(customerProduct));
        selectedCustomerProducts?.forEach((products: any) => {
            products.name = `${products.productId} - ${products.productName} ${products.productVersionVerison}`;
            products.checked = customers.includes(products.id);
        });
        setDropdownItems(selectedCustomerProducts);
    };

    useEffect(() => {
        generateCustomersProductsDropdown(customerProducts, allCustomerProducts);
    }, [customerProducts]);

    /**
     * OnSelect and Deselect of customerProducts change
     * @param selectedProduct
     */
    const onCustomerProductChange = (selectedProduct: Array<ICustomerProductsHardlock>) => {
        const previouslySelected = dropdownItems.filter((item: any) => item.checked);
        if (previouslySelected.length > selectedProduct.length) {
            const deSelectedItem = previouslySelected.filter(
                (elem) =>
                    !selectedProduct.find(
                        (product) =>
                            elem.id === product.id && elem.productVersionVerison === product.productVersionVerison
                    )
            );

            const customerProduct = {
                productionSiteId: deSelectedItem[0]?.productionSiteId,
                productVersionId: deSelectedItem[0]?.productVersionId,
                hardlockId: null,
                modified: deSelectedItem[0].modified
            };
            const customerProductId = deSelectedItem[0]?.id;
            dispatch(putCustomerProduct({ body: customerProduct, customerProductId }));
        } else {
            const ID = 'id';
            const uniqueCustomerProducts = [...new Map(selectedProduct.map((item) => [item[ID], item])).values()];

            const selectedCustomerProduct =
                customerProducts?.length >= 1
                    ? uniqueCustomerProducts?.filter(
                          (product: ICustomerProductsHardlock) => !customerProducts.includes(product.id)
                      )
                    : uniqueCustomerProducts;
            const customerProduct = {
                productionSiteId: selectedCustomerProduct[0]?.productionSiteId,
                productVersionId: selectedCustomerProduct[0]?.productVersionId,
                hardlockId,
                modified: selectedCustomerProduct[0].modified
            };
            const customerProductId = selectedCustomerProduct[0]?.id;
            dispatch(patchCustomerProduct({ body: customerProduct, customerProductId }));
        }
    };

    return (
        <FlexboxContainer
            flexDirection="column"
            gap="1rem"
            className="Accordion_wrapper Full_width"
            justifyContent="start"
            alignItems="stretch"
        >
            <FlexboxItem className="Full_width">
                <FlexboxItem className="Half_width">
                    <Dropdown
                        changeSelected={onCustomerProductChange}
                        dropdownItems={dropdownItems}
                        multiSelectable
                        required
                        theme="light"
                        title={<LiMultiterm textId="99034264" textVal="Assigned products" />}
                    />
                </FlexboxItem>
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default HardlockProductionSitesAccordion;
