import React from 'react';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import { FlexboxContainer, FlexboxItem, Typography, TextField, ToggleSwitch } from 'ui-components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setHardLockModalData } from '../../slice';
import { modalType } from '../../../../utils/commonUtils';

const HardLockTypeModal = () => {
    const { hardLockTypeModalData, hardLockTypeModalType } = useAppSelector((state) => state.hardLockTypes);
    const dispatch = useAppDispatch();

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempModalData = { ...hardLockTypeModalData };
        if (event.target.name === 'maxConcurrentUsers') {
            tempModalData = {
                ...tempModalData,
                maxConcurrentUsers: event.target.value.replace(/\D/g, '') !== '' ? +event.target.value.replace(/\D/g, '') : ''
            };
            dispatch(setHardLockModalData(tempModalData));
        } else {
            tempModalData = {
                ...tempModalData,
                [event.target.name]: event.target.value
            };
            dispatch(setHardLockModalData(tempModalData));
        }
    };

    const handleOnToggleChange = (name: string, value: boolean) => {
        let tempModalData = { ...hardLockTypeModalData };
        tempModalData = {
            ...tempModalData,
            [name]: value
        };
        dispatch(setHardLockModalData(tempModalData));
    };

    return (
        <FlexboxContainer
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="0.5rem"
            className="Full_height"
        >
            <FlexboxItem>
                <Typography type="H1">
                    {hardLockTypeModalType === modalType.ADD ? (
                        <LiMultiterm textId="99034174" textVal="Add hardlock type" />
                    ) : (
                        <LiMultiterm textId="99034175" textVal="Edit hardlock type" />
                    )}
                </Typography>
            </FlexboxItem>
            <FlexboxItem>
                <FlexboxContainer alignItems="start" justifyContent="start">
                    <FlexboxItem className="Modal_each_side_wrapper">
                        <FlexboxContainer flexDirection="column" gap="1.25rem" alignItems="start">
                            {hardLockTypeModalType === modalType.EDIT && (
                                <FlexboxItem className="Full_width">
                                    <TextField
                                        className="Id_input"
                                        title={<LiMultiterm textId="9000439" textVal="ID" />}
                                        isRequired
                                        disabled={hardLockTypeModalType === modalType.EDIT}
                                        name="id"
                                        value={hardLockTypeModalData?.id}
                                    />
                                </FlexboxItem>
                            )}
                            <FlexboxItem className="Full_width">
                                <TextField
                                    className="Name_input"
                                    title={<LiMultiterm textId="99018122" textVal="Name" />}
                                    isRequired
                                    onChange={handleOnChange}
                                    name="name"
                                    errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                                    value={hardLockTypeModalData?.name}
                                />
                            </FlexboxItem>
                            <FlexboxItem className="Full_width">
                                <ToggleSwitch
                                    checked={hardLockTypeModalData?.hasTimeFeature}
                                    value={hardLockTypeModalData?.hasTimeFeature}
                                    className="Toggle_switch Has_time_feature_toggle_switch"
                                    onChange={(data: boolean) => handleOnToggleChange('hasTimeFeature', data)}
                                    switchPrefix={<LiMultiterm textId="99034172" textVal="Time feature" />}
                                />
                            </FlexboxItem>
                            <FlexboxItem className="Full_width">
                                <ToggleSwitch
                                    checked={hardLockTypeModalData?.hasNetFeature}
                                    value={hardLockTypeModalData?.hasNetFeature}
                                    className="Toggle_switch Has_network_feature_toggle_switch"
                                    onChange={(data: boolean) => handleOnToggleChange('hasNetFeature', data)}
                                    switchPrefix={<LiMultiterm textId="99034173" textVal="Network feature" />}
                                />
                            </FlexboxItem>
                            <FlexboxItem className="Full_width">
                                <TextField
                                    className="Maximum_concurrent_users_input"
                                    title={<LiMultiterm textId="99034170" textVal="Maximum concurrent users" />}
                                    onChange={handleOnChange}
                                    name="maxConcurrentUsers"
                                    value={hardLockTypeModalData?.maxConcurrentUsers}
                                />
                            </FlexboxItem>
                        </FlexboxContainer>
                    </FlexboxItem>
                </FlexboxContainer>
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default HardLockTypeModal;
