/* eslint-disable camelcase */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';

import { IAssociatedProductVersion } from '../../../@types/features';
import { IErrorState, setError } from '../../../slice/ErrorHandling';
import OpenApiClient_customer_management_v2 from '../../../api/openapiclient_customer_management_v2';

export interface IFeaturesInitialState {
    isLoading: boolean;
    associatedProductsData: Array<IAssociatedProductVersion>
}



const initialState: IFeaturesInitialState = {
    isLoading: false,
    associatedProductsData: [],
};

/**
 * @function getAssociatedProducts
 * @description Function to fetch all associated products of feature
 */
export const getAssociatedProducts = createAsyncThunk('getAssociatedProducts', async (featureId: number, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_Features_id_AssociatedProducts(featureId);
        if (response?.status === 204) {
            return [];
        }
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data);
        return responseData;
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in getFeatures '
        };
        return dispatch(setError(errorValue));
    }
});



const associatedProductsSlice = createSlice({
    name: 'associatedProducts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAssociatedProducts.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAssociatedProducts.fulfilled, (state, action: PayloadAction<Array<IAssociatedProductVersion>>) => {
            state.isLoading = false;
            state.associatedProductsData = action.payload
        });
        builder.addCase(getAssociatedProducts.rejected, (state) => {
            state.isLoading = false;
        });

    }
});

export default associatedProductsSlice.reducer;
