/* eslint-disable camelcase */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';

import { IAssociatedProductionSites, IProductsAssociatedInitialState } from '../../../@types/products';
import { IErrorState, setError } from '../../../slice/ErrorHandling';
import OpenApiClient_customer_management_v2 from '../../../api/openapiclient_customer_management_v2';

const initialState: IProductsAssociatedInitialState = {
    isLoading: false,
    associatedProductionSitesData: []
};

/**
 * @function getAssociatedProductionSites
 * @description Function to fetch all associated products of products
 */
export const getAssociatedProductionSites = createAsyncThunk(
    'getAssociatedProductionSites',
    async (productionVersionId: number, { dispatch }) => {
        try {
            const response =
                await OpenApiClient_customer_management_v2.getClient(
                    null
                ).GET_ProductVersions_id_AssociatedProductionSites(productionVersionId);
            if (response?.status === 204) {
                return [];
            }
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            const responseData = conversionLib.convertToJSON(response.data);
            return responseData;
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'error in Get Associated Production Sites '
            };
            return dispatch(setError(errorValue));
        }
    }
);

const associatedProductsSlice = createSlice({
    name: 'associatedProductionSites',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAssociatedProductionSites.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(
            getAssociatedProductionSites.fulfilled,
            (state, action: PayloadAction<Array<IAssociatedProductionSites>>) => {
                state.isLoading = false;
                state.associatedProductionSitesData = action.payload;
            }
        );
        builder.addCase(getAssociatedProductionSites.rejected, (state) => {
            state.isLoading = false;
        });
    }
});

export default associatedProductsSlice.reducer;
