import React from 'react';
import { TextLabel } from 'ui-components';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';

export default [
    {
        id: 1,
        text: <TextLabel className="Status_green" text={<LiMultiterm textId="99000784" textVal="Enabled" />} />
    },
    {
        id: 2,
        text: <TextLabel className="Status_gray" text={<LiMultiterm textId="99000809" textVal="Disabled" />} />
    }
];
