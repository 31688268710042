/* eslint-disable camelcase */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiBody, MimeTypes } from 'lisecutilityfunctions/lib/openapi';

import { IErrorState, setError } from '../../../slice/ErrorHandling';
import OpenApiClient_customer_management_v2 from '../../../api/openapiclient_customer_management_v2';
import { modalType } from '../../../utils/commonUtils';
import { IHardLockTypeData } from '../../../@types/hardLockTypes';

export interface IHardLockTypesInitialState {
    isLoading: boolean;
    hardLockTypeData: Array<IHardLockTypeData>;
    hardLockTypeModalType: string;
    hardLockTypeModalData: IHardLockTypeData;
}

const initialModalData = {
    id: 0,
    name: '',
    hasTimeFeature: true,
    hasNetFeature: true,
    maxConcurrentUsers: 0
};

const initialState: IHardLockTypesInitialState = {
    isLoading: false,
    hardLockTypeData: [],
    hardLockTypeModalType: modalType.CLOSED,
    hardLockTypeModalData: initialModalData
};


const handleRejection = (error: any, dispatch: any) => {
    const errorMessage: { [key: string]: string } = {
        403 : "User is not authorized",
        500 : "Internal server error"
    }
    const errorValue: IErrorState = {
        statusCode: error.response?.status ?? 0,
        message: errorMessage?.[error.response?.status] ?? 'Failed to load Hardlocks'
    };
    dispatch(setError(errorValue));
}
/**
 * @function getHardLockType
 * @description Function to fetch all hardLock Types
 */
export const getHardLockType = createAsyncThunk('getHardLockType', async (_, { dispatch, rejectWithValue }) => {

    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_HardlockType();
        if (response?.status === 204) {
            return [];
        }
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data);
        return responseData;
    } catch (error: any) {
            return rejectWithValue(handleRejection(error,dispatch))
    }
});

/**
 * @function postHardLockType
 * @description Function to do post hard lock type
 */
export const postHardLockType = createAsyncThunk(
    'postHardLockType',
    async (postHardLockTypeBody: IHardLockTypeData, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).POST_HardlockType(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, postHardLockTypeBody)
            );
            if (response?.status !== 201) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            return dispatch(getHardLockType());
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'error in postHardLockType '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function putFeature
 * @description Function to put hard lock type
 */
export const putHardLockType = createAsyncThunk(
    'putFeature',
    async ({ body, hardLockTypeId }: { body: IHardLockTypeData; hardLockTypeId: string }, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).PUT_HardlockType_id(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, body),
                hardLockTypeId
            );
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            return dispatch(getHardLockType());
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'Error in putHardLockType '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function patchHardLockType
 * @description Function to patch hard lock type
 */
export const patchHardLockType = createAsyncThunk(
    'patchHardLockType',
    async ({ body, hardLockTypeId }: { body: any; hardLockTypeId: any }, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).PATCH_HardlockType_id(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, body),
                hardLockTypeId
            );
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            return dispatch(getHardLockType());
        } catch (error: any) {
            const errorResponse = conversionLib.convertToJSON(error.response?.data);
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: errorResponse?.title ?? 'Error in patchHardLockType '
            };
            return dispatch(setError(errorValue));
        }
    }
);

const hardLockTypeSlice = createSlice({
    name: 'hardLockTypes',
    initialState,
    reducers: {
        setHardLockTypeModalType: (state, action: PayloadAction<string>) => {
            state.hardLockTypeModalType = action.payload;
            if (action.payload === modalType.CLOSED) {
                state.hardLockTypeModalData = initialModalData;
            }
        },
        setHardLockModalData: (state, action: PayloadAction<any>) => {
            state.hardLockTypeModalData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getHardLockType.pending, (state: IHardLockTypesInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(
            getHardLockType.fulfilled,
            (state: IHardLockTypesInitialState, action: PayloadAction<Array<IHardLockTypeData>>) => {
                state.isLoading = false;
                state.hardLockTypeData = action.payload;
            }
        );
        builder.addCase(getHardLockType.rejected, (state: IHardLockTypesInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(postHardLockType.pending, (state: IHardLockTypesInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(postHardLockType.fulfilled, (state: IHardLockTypesInitialState) => {
            state.isLoading = false;
            state.hardLockTypeModalType = modalType.CLOSED;
            state.hardLockTypeModalData = initialModalData;
        });
        builder.addCase(postHardLockType.rejected, (state: IHardLockTypesInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(putHardLockType.pending, (state: IHardLockTypesInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(putHardLockType.fulfilled, (state: IHardLockTypesInitialState) => {
            state.isLoading = false;
            state.hardLockTypeModalType = modalType.CLOSED;
            state.hardLockTypeModalData = initialModalData;
        });
        builder.addCase(putHardLockType.rejected, (state: IHardLockTypesInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(patchHardLockType.pending, (state: IHardLockTypesInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(patchHardLockType.fulfilled, (state: IHardLockTypesInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(patchHardLockType.rejected, (state: IHardLockTypesInitialState) => {
            state.isLoading = false;
        });
    }
});

export const { setHardLockTypeModalType, setHardLockModalData } = hardLockTypeSlice.actions;
export default hardLockTypeSlice.reducer;
