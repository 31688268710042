/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { FlexboxContainer, FlexboxItem, Typography } from "ui-components";

import "./KeyValueInput.css";

interface IKeyValueInput {
	title: React.JSX.Element,
	value: React.JSX.Element,
	isTitleRequired?: boolean
	className?: string,
}

const KeyValueInput = ({
	title,
	isTitleRequired,
	value,
	className,
}: IKeyValueInput) =>
	<FlexboxContainer
		gap="0.625rem"
		className={`Full_width Key_value_input_container ${className}`}
	>
		<FlexboxItem
			className="Key_styles Full_width"
			justifyContent="start"
		>
			{title &&
				<Typography type="OL1">
					{title} {" "}
					{isTitleRequired &&
						<span className="Required-asterisk">
							*
						</span>
					}
				</Typography>

			}
		</FlexboxItem>

		<FlexboxItem
			flex={1}
			className="Full_width Value_input_component_container"
			justifyContent="start"
		>
			{value}
		</FlexboxItem>
	</FlexboxContainer>

KeyValueInput.defaultProps = {
	isTitleRequired: false,
	className: "",
}

export default KeyValueInput;
