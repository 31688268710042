import React, { useEffect, useState } from 'react';
import { dateTimeFormatConversion } from 'lisecutilityfunctions/lib/commonfunction';
import { useSearchParams } from 'react-router-dom';
import {
    getTrialPeriods,
    postTrialPeriod,
    patchTrialPeriod,
    setIsInvalid,
    setTrialPeriodsModalData,
    setTrialPeriodsModalType,
    appendErrorMessages,
    removeErrorMessages,
    setSelectedTrialPeriodsData
} from './slice/index';
import { getMultiTermText, getToday, modalType, setAccordionOpenedId } from '../../utils/commonUtils';
import { IAccordionObject } from '../../@types/common';
import { useAppDispatch, useAppSelector } from '../../hooks';
import OverviewScreen from '../../components/overviewScreen/OverviewScreen';
import headerData from './constants/HeaderData';
import { accordionLevel1, trialPeriodsType } from '../../utils/appConstants';
import { ITableHeader, ITrialPeriod, ITrialPeriodsPutData } from '../../@types/trialPeriods';
import TrialPeriodsAccordion from './components/TrialPeriodAccordion/TrialPeriodsAccordion';
import ActionModal from '../../components/actionModal/ActionModal';
import TrialPeriodsModal from './components/TrialPeriodsModal/TrialPeriodsModal';
import DeleteModalContent from '../../components/deleteModalContent/DeleteModalContent';
import TrialPeriodsTableOptions from './components/TrialPeriodsTableOption/TrialPeriodsTableOptions';

const TrialPeriods = ({ globalContext }: any) => {
    const dispatch = useAppDispatch();
    const {
        isLoading,
        trialPeriodsData,
        trialPeriodModalType,
        trialPeriodsModalData,
        selectedTrialPeriodsData,
        isInvalid
    } = useAppSelector((state) => state.trialPeriods);

    const [searchParams, setSearchParams] = useSearchParams();
    const { setLoader } = globalContext;
    const [tableHeaders, setTableHeaders] = useState<Array<ITableHeader>>(headerData);
    const isDatesValid = (validFrom: string, validTo: string) => {
        const validFromDate = new Date(validFrom);
        const validToDate = new Date(validTo);
        const today = new Date(getToday());
        if (trialPeriodModalType === modalType.ADD && validFromDate < today) {
            dispatch(
                appendErrorMessages({
                    validFrom: getMultiTermText({ id: '99034158', text: 'Dates can not be in the past' })
                })
            );
        } else {
            dispatch(removeErrorMessages(['validFrom']));
        }

        if (validToDate <= validFromDate) {
            dispatch(
                appendErrorMessages({
                    validTo: getMultiTermText({
                        id: '99034159',
                        text: '"Available to" date cannot be before "Available from" date'
                    })
                })
            );
        } else {
            dispatch(removeErrorMessages(['validTo']));
        }
        return (trialPeriodModalType===modalType.ADD && validFromDate >= today || trialPeriodModalType === modalType.EDIT) && validToDate > validFromDate;
    };

    const getSessionStorageValue = (index: number) => index.toString()

    useEffect(() => {
        dispatch(getTrialPeriods(null));
        const prodSiteId = searchParams.get('productionSite');
        if (prodSiteId) {
            setTableHeaders((prevHeaders) =>
                prevHeaders.map((header) => {
                    if (header._key === 'productionSiteId') { // eslint-disable-line no-underscore-dangle
                        return { ...header, initialFilterValue: parseInt(prodSiteId, 10) };
                    }
                    return header;
                })
            );
        }
    }, []);

    useEffect(() => {
        const { customerId, productionSiteId, features, validFrom, validTo } = trialPeriodsModalData;
        if (isDatesValid(validFrom ?? getToday(), validTo ?? getToday())) {
            dispatch(removeErrorMessages(['validFrom', 'validTo']));
        }

        dispatch(
            setIsInvalid(
                !(
                    customerId &&
                    productionSiteId &&
                    features?.length &&
                    isDatesValid(validFrom ?? getToday(), validTo ?? getToday())
                )
            )
        );
    }, [trialPeriodsModalData]);

    useEffect(() => {
        setLoader({
            loading: isLoading
        });
    }, [isLoading]);

    const getTrialPeriodsTableData = (allTrialPeriodsData: Array<ITrialPeriod>) => {
        const accordionOpenedData = sessionStorage.getItem('accordionOpened');
        let openedAccordion: IAccordionObject = {};
        if (accordionOpenedData) {
            openedAccordion = JSON.parse(accordionOpenedData);
        }
        return allTrialPeriodsData.map((eachData, index) => {
            let isAccordionOpened = false;
            if (
                openedAccordion &&
                openedAccordion[trialPeriodsType] &&
                openedAccordion[trialPeriodsType][accordionLevel1]
            ) {
                const openedData = openedAccordion[trialPeriodsType][accordionLevel1].map((id) => id.toString());
                if (
                    openedData.includes(
                        getSessionStorageValue(
                            index
                        )
                    )
                ) {
                    isAccordionOpened = true;
                }
            }

            return {
                _key: index,
                _accordionContent: <TrialPeriodsAccordion trialPeriodData={eachData} />,
                _option: (
                    <TrialPeriodsTableOptions
                        onClickEdit={() => {
                            dispatch(setTrialPeriodsModalType(modalType.EDIT));
                            dispatch(setTrialPeriodsModalData(eachData));
                            dispatch(setSelectedTrialPeriodsData(eachData));
                        }}
                    />
                ),
                productionSiteId: eachData.productionSiteId,
                customer: eachData.customerName,
                productionSite: eachData.productionSiteName,
                feature: eachData.features.map((feature) => feature.id).join(', '),
                machineSerialNumber: eachData.machineSerial,
                availableFrom: dateTimeFormatConversion(eachData.validFrom, 'date'),
                isAccordionOpen: isAccordionOpened,
                availableTo: dateTimeFormatConversion(eachData.validTo, 'date'),
                _datesToSort: {
                    availableFrom: eachData.validFrom,
                    availableTo: eachData.validTo
                }
            };
        });
    };

    const onHandleAccordionToggle = (data: any) => {
        const accordionState = JSON.stringify(
            setAccordionOpenedId(
                accordionLevel1,
                getSessionStorageValue(data._key), // eslint-disable-line no-underscore-dangle
                trialPeriodsType,
                data
            )
        );
        sessionStorage.setItem('accordionOpened', accordionState);
    };

    const saveTrialPeriods = () => {
        const { customerId, features, productionSiteId, validFrom, validTo } = trialPeriodsModalData;
        if (!customerId || !features?.length || !productionSiteId) {
            return;
        }
        if (!isDatesValid(validFrom ?? getToday(), validTo ?? getToday())) {
            return;
        }
        const payload: ITrialPeriodsPutData = {
            ...trialPeriodsModalData,
            validFrom: trialPeriodsModalData.validFrom ?? getToday(),
            validTo: trialPeriodsModalData.validTo ?? getToday(),
            features: trialPeriodsModalData.features?.map((feature) => feature.id),
            existingValidFrom: selectedTrialPeriodsData.validFrom ?? '',
            existingValidTo: selectedTrialPeriodsData.validTo ?? ''
        };
        if (trialPeriodModalType === modalType.ADD) {
            dispatch(postTrialPeriod(payload));
        } else {
            dispatch(patchTrialPeriod(payload));
        }
    };

    const clearInitialFilterField = (isFilterFieldsOpen: boolean) => {
        if (!isFilterFieldsOpen) {
            setSearchParams({});
            setTableHeaders((prev) => {
                const newHeader = prev.map((header) => {
                    delete header.initialFilterValue;
                    return header;
                });
                return newHeader;
            });
        }
    };

    return (
        <>
            <OverviewScreen
                pageHeaderMultiTermId="99032446"
                pageHeaderMultiTermText="Trial periods"
                tableClassName="Trial_periods_table"
                headerData={tableHeaders}
                rowsData={getTrialPeriodsTableData(trialPeriodsData)}
                initialColumnSortData={[
                    {
                        order: 'ascending',
                        columnKey: 'productionSiteId'
                    }
                ]}
                tableId="TrialPeriodsTable"
                addButtonMultiTermId="99032448"
                addButtonMultiTermText="Add trial period"
                addButtonOnClick={() => dispatch(setTrialPeriodsModalType(modalType.ADD))}
                onAccordionToggle={onHandleAccordionToggle}
                onSearchIconClick={clearInitialFilterField}
            />
            {trialPeriodModalType !== modalType.CLOSED && (
                <ActionModal
                    onClickAction={saveTrialPeriods}
                    onClickCancelClose={() => {
                        dispatch(setTrialPeriodsModalType(modalType.CLOSED));
                    }}
                    actionType={trialPeriodModalType}
                    modalColumnNumber={2}
                    ModalContent={
                        trialPeriodModalType !== modalType.DELETE ? (
                            <TrialPeriodsModal />
                        ) : (
                            <DeleteModalContent isDeletePermanentMessage={false} />
                        )
                    }
                    actionDisabled={isInvalid}
                />
            )}
        </>
    );
};

export default TrialPeriods;
