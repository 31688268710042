import React from 'react';
import { Listing, Typography } from 'ui-components';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';

import './PageHeader.css';
import '../../styles/global.css';

interface IPageHeader {
    HeaderMultiTermId: string;
    HeaderTitle: string;
}

const PageHeader = ({ HeaderMultiTermId, HeaderTitle }: IPageHeader) => (
    <Listing
        className="Page_header"
        leftItemListing={[
            {
                children: null,
                id: 1
            }
        ]}
        leftListingTitle={
            <Typography type="H1">
                <LiMultiterm textId={HeaderMultiTermId} textVal={HeaderTitle} />
            </Typography>
        }
    />
);

export default PageHeader;
