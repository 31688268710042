import IconMenuComponents from '../assets/Icons/IconMenuComponents.svg';
import IconCustomers from '../assets/Icons/IconCustomers.svg';
import IconProducts from '../assets/Icons/IconProducts.svg';
import IconTrialPeriods from '../assets/Icons/IconTrialPeriods.svg';
import IconLoginHistory from '../assets/Icons/IconLoginHistory.svg';
import IconMenuHardLocks from '../assets/Icons/IconMenuHardLocks.svg';

import Features from '../pages/Features/Features';
import Customers from '../pages/Customers/Customers';
import Products from '../pages/Products/Products';
import LoginHistory from '../pages/LoginHistory/LoginHistory';
import AssociatedProducts from '../pages/Features/components/associatedProducts/AssociatedProducts';
import AssociatedProductionSites from '../pages/Products/components/associatedProductionSites/AssociatedProductionSites';
import { getMultiTermText } from '../utils/commonUtils';
import TrialPeriods from '../pages/TrialPeriods/TrialPeriods';
import HardLockTypes from '../pages/HardLockTypes/HardLockTypes';
import HardLocks from '../pages/HardLocks/HardLocks';
import React from 'react';
import { HealthCheck, Statistics, APITokens } from 'module-maintenance'

export const routes = [
    {
        path: '/features',
        name: getMultiTermText({ text: 'Features', id: '99032233' }),
        component: Features,
        icon: IconMenuComponents,
        roles: [47],
        children: [
            {
                path: '/features/associatedProducts/:featureId',
                component: AssociatedProducts
            }
        ]
    },
    {
        path: '/customers',
        name: getMultiTermText({ text: 'Customers', id: '99002847' }),
        component: Customers,
        icon: IconCustomers,
        roles: [47]
    },
    {
        path: '/sales-products',
        name: getMultiTermText({ text: 'Sales products', id: '99034157' }),
        component: Products,
        icon: IconProducts,
        roles: [47],
        children: [
            {
                path: '/sales-products/associatedProductionSites/:productVersionId',
                component: AssociatedProductionSites,
            }
        ]
    },
    {
        path: '/trialPeriod',
        name: getMultiTermText({ text: 'Trial Periods', id: '99032446' }),
        component: TrialPeriods,
        icon: IconTrialPeriods,
        roles: [47]
    },
    {
        path: '/loginhistory',
        name: getMultiTermText({ text: 'Login History', id: '99034136' }),
        component: LoginHistory,
        icon: IconLoginHistory,
        roles: [47]
    },
    {
        path: '/hardlocks/*',
        mainPath: "/hardlocks",
        name: getMultiTermText({ text: 'Hardlocks', id: '99034161' }),
        component: React.Fragment,
        icon: IconMenuHardLocks,
        routes: [
            {
                path: '/hardlocks-list/*',
                mainPath: '/hardlocks-list',
                name: getMultiTermText({ text: 'Hardlocks', id: '99034161' }),
                component: HardLocks,
                icon: IconMenuHardLocks,
                roles: [47],
                optionalPermissions: [75, 74]
            },
            {
                path: '/hardlock-types/*',
                mainPath: '/hardlock-types',
                name: getMultiTermText({ text: 'Hardlock types', id: '99034169' }),
                component: HardLockTypes,
                icon: IconMenuHardLocks,
                roles: [47],
                optionalPermissions: [75, 74]
            }
        ],
        roles: [47],
        optionalPermissions: [75, 74]
    },
    {
        path: '/maintenance',
        name: getMultiTermText({ text: 'Maintenance', id: '06007159' }),
        component: HealthCheck,
        icon: IconLoginHistory,
        routes: [
            {
                path: '/healthcheck',
                name: getMultiTermText({ text: 'Healthcheck', id: '99030737' }),
                component: HealthCheck,
                icon: IconLoginHistory,
                roles: [2, 49],
            },
            {
                path: '/statistics',
                name: getMultiTermText({ text: 'Statistics', id: '6000160' }),
                component: Statistics,
                icon: IconLoginHistory,
                roles: [2, 49],
            },
            {
                path: '/apitokens',
                name: getMultiTermText({ text: 'ApiTokens', id: '99030966' }),
                component: APITokens,
                icon: IconLoginHistory,
                roles: [2, 49],
            },
        ],
        roles: [2, 49],
    },
];
