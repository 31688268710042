import { getLabelOptions, getMultiTermText } from '../../../utils/commonUtils';

export default [
    {
        _key: 'authTokenId',
        label: getMultiTermText({ id: '99034140', text: 'Token ID' }),
        searching: true,
        sorting: true,
        width: '7%',
        isEditable: false,
    },
    {
        _key: 'created',
        label: getMultiTermText({ id: '99001796', text: 'Creation date' }),
        searching: true,
        sorting: true,
        type: 'date',
        width: '12%',
        isEditable: false,
    },
    {
        _key: 'tokenexpired',
        label: getMultiTermText({ id: '99034141', text: 'Token expiry date' }),
        searching: true,
        sorting: true,
        type: 'date',
        width: '13%',
        isEditable: false,
    },
    {
        _component: 'username',
        _key: 'user',
        label: getMultiTermText({ id: '99001942', text: 'User' }),
        searching: true,
        sorting: true,
        width: '23%',
        isEditable: false,
        type: 'string',
    },
    {
        _key: 'type',
        filterOptions: [
            {
                id: '1',
                name: 'Authentication token issued',
                value: 'Authentication token issued'
            },
            {
                id: '2',
                name: 'Login performed',
                value: 'Login performed'
            }
        ],
        label: getMultiTermText({ id: '99000026', text: 'Type' }),
        searching: true,
        sorting: true,
        width: '20%',
        isEditable: false,
    },
    {
        _key: 'machineSerial',
        label: getMultiTermText({ id: '99032450', text: 'Machine - serial number' }),
        searching: true,
        sorting: true,
        width: '10%',
        isEditable: false,
    },
    {
        _key: 'customer',
        label: getMultiTermText({ id: '99028795', text: 'Customer ID' }),
        searching: true,
        sorting: true,
        width: '10%',
        isEditable: false,
    },
    {
        _key: 'authorizations',
        label: getMultiTermText({ id: '99023864', text: 'Permissions' }),
        searching: true,
        sorting: true,
        width: '15%',
        isEditable: false,
        showToolTip: true
    }
];
