import React from 'react';
import { FlexboxContainer, FlexboxItem, Typography } from 'ui-components';
import { MultilevelComponentProps, IProductListArray } from '../../../../@types/customers';
import { isAnyOfSubLevelChecked } from '../../utils/productionSitesUtils';
import './MultiLevelTree.css';

const MultiLevelTree = ({ data, level = 0 }: any) =>
    data?.map((products: IProductListArray) => (
        <FlexboxItem key={products.id} className="Assigned_featurelist ProductionSite_featureList">
            {isAnyOfSubLevelChecked(products.sublevel) || products.checked ? (
                <FlexboxItem
                    className={`Full_width Assigned_MultiList_TreeProducts level-${level} ${
                        level === 0 ? 'Assigned_Parent_MultiLevel' : ''
                    }`}
                    key={products.id}
                >
                    <Typography
                        additionalClassForStyle={products.disabled || !products.checked ? 'Disabled_text' : ''}
                        type={level === 0 ? 'ST3' : 'BS1'}
                    >
                        {products.id} - {products.description}
                    </Typography>
                    {products.sublevel && products.sublevel.length > 0 && (
                        <MultiLevelTree data={products.sublevel} level={level + 1} />
                    )}
                </FlexboxItem>
            ) : null}
        </FlexboxItem>
    ));
export default MultiLevelTree;
