/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect,useState } from 'react';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import {
    FlexboxContainer,
    FlexboxItem,
    Typography,
    TextField,
    RadioButton,ProgressBar,FileUpload,Dropdown
} from 'ui-components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {hardlocksModalType} from '../../constants/HardlocksConstant';
import {toBase64} from '../../../../utils/commonUtils';
import {ILocationChangeDropdownData} from '../../../../@types/hardlocks';
import {
    setAddHardlocksModalData, setPutHardlocksModalData
} from '../../slice';
import './HardlocksModal.css';

const DUMMY_DEPT_DROPDOWNDATA = [
    {
    id: 1,
    name: "RGB",
    },
    {
        id: 2,
        name: "HEX",
    },
    
]

const HardlocksModal = () => {
    const [uploadedFileArr, setUploadedFileArr] = useState<any>({});
    
    const {
        hardlocksData,
        modalType,
        currentSelectedHardlock,
        addHardlocksModalData,
        putHardlocksModalData,
    } = useAppSelector((state) => state.hardlocks);

    const dispatch = useAppDispatch();

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempModalData = { ...addHardlocksModalData };
        tempModalData = {
            ...tempModalData,
            [event.target.name]: event.target.name === "lisecSerialNo" ? +event.target.value.replace(/\D/g, '') :event.target.value
        };
        if (modalType === hardlocksModalType.ADD){
            dispatch(setAddHardlocksModalData(tempModalData));
        }
    };

    const uploadFile = async (files: Blob[]) => {
        setUploadedFileArr(files[0])
		const file = await toBase64(files[0]);
    const input: any = file
    const base64Data: string = input.split(",")[1];

    if (modalType === hardlocksModalType.SYNC){
        const currentSelectedHardlocks = hardlocksData.filter(e=> e.id === currentSelectedHardlock )
        const hardlocksType = currentSelectedHardlocks[0].type;
        const hardlocksLisecSerialNo = currentSelectedHardlocks[0].lisecSerialNo;

        const tempPutModalData = {
            lisecSerialNo : hardlocksLisecSerialNo,
            c2VFile : base64Data,
            type : hardlocksType
        };

        dispatch(setPutHardlocksModalData(tempPutModalData))
    }
    else if (modalType === hardlocksModalType.ADD){
        let tempAddModalData = { ...addHardlocksModalData };
        tempAddModalData = {
            ...tempAddModalData,
            c2VFile : base64Data
        };
        dispatch(setAddHardlocksModalData(tempAddModalData));
    }
	};
    
    const HeadingContent = () =>{
        let content;
        if(modalType === hardlocksModalType.ADD) (
            content = <LiMultiterm textId="99034258" textVal="Add Hardlock" />
        ) 
        else if (modalType === hardlocksModalType.SYNC )(
            content = <LiMultiterm textId="99034257" textVal="Synchronise Hardlock" />
        )
        return content;
    }

    const ModalClassName = modalType !== hardlocksModalType.EDIT ? 'Full_height File_upload_modal' : 'Full_height File_upload_modal'
    return (
        <FlexboxContainer
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="0.5rem"
            className={ModalClassName}
        >
            <FlexboxItem>
                <Typography type="H1">
                    {HeadingContent()}
                    

                </Typography>
            </FlexboxItem>
            <FlexboxItem>
                <FlexboxItem className="Modal_each_side_wrapper Modal_changeLocation">
                    <FlexboxContainer flexDirection="column" gap="1.25rem" alignItems="start">
                    { modalType !== hardlocksModalType.SYNC ?
                    <FlexboxItem className="Full_width">
                        <TextField
                            className="Id_input"
                            title={<LiMultiterm textId="99015353" textVal="Serial no." />}
                            isRequired
                            disabled={modalType === hardlocksModalType.EDIT}
                            onChange={handleOnChange}
                            name="lisecSerialNo"
                            errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                            value={addHardlocksModalData?.lisecSerialNo}
                        />
                    </FlexboxItem>
                    :
                    null
                    }
                    <FileUpload
                    onChange={uploadFile}
                    supportedFormats={["c2v"]}
                    />
                <FlexboxItem className="Width_100 FileUpload_progress_bar_wrapper">
                {uploadedFileArr && uploadedFileArr.name && (
                        <ProgressBar
                        type="linear"
                        prefixIcon={null}
                        suffixIcon={null}
                        // onSuffixIconClick={() => removeFile(index)}
                        key="uploadedFile"
                        progressPercent={100} // Since upload to server is not available, progress is set to 100%
                        label={uploadedFileArr.name}
                        />
                )}
                </FlexboxItem>
                </FlexboxContainer>

                </FlexboxItem>
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default HardlocksModal;
