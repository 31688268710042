import { getMultiTermText } from '../../../utils/commonUtils';

export default [
    {
        _key: 'date',
        label: getMultiTermText({ id: '99019550', text: 'Timestamp' }),
        searching: false,
        sorting: false,
        width: '30%',
        type: "date",
        isEditable: false,
    },
    {
        _key: 'status',
        label: getMultiTermText({ id: '3000144', text: 'Status' }),
        searching: false,
        sorting: false,
        width: '17%',
        isEditable: false,
    },
    {
        _key: 'location',
        label: getMultiTermText({ id: '99034253', text: 'Location' }),
        searching: false,
        sorting: false,
        width: '17%',
        isEditable: false,
    },
    {
        _key: 'modifiedBy',
        label: getMultiTermText({ id: '99034260', text: 'Done by' }),
        searching: false,
        sorting: false,
        width: '17%',
        isEditable: false,
    },
    {
        _key: 'remarks',
        label: getMultiTermText({ id: '99006349', text: 'Remarks' }),
        searching: false,
        sorting: false,
        width: '20%',
        isEditable: false,
    }
];
