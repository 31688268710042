/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import { dateTimeFormatConversion } from 'lisecutilityfunctions/lib/commonfunction';

import { FlexboxContainer, FlexboxItem, Typography, Table, ToggleSwitch } from 'ui-components';
import KeyValueInput from '../../../../components/keyValueInput/KeyValueInput';

import { IAccordionObject } from '../../../../@types/common';
import { IProductsData, IProductVersionsTableData } from '../../../../@types/products';
import { setAccordionOpenedId, modalType, getMultiTermText } from '../../../../utils/commonUtils';
import ProductVersionTableOptions from '../ProductVersionTableOptions/ProductVersionTableOptions';
import selectedProductHeaderData from '../../constants/selectedProductHeaderData';
import IconVersions from '../../../../assets/Icons/IconVersions.svg';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    setProductVersionModalType,
    getDependentFeatures,
    getProductVersionById,
    putProduct,
    setEditVersionProductId
} from '../../slice/index';
import { accordionLevel2, productType } from '../../../../utils/appConstants';
import ProductVersionAccordion from '../ProductVersionAccordion/ProductVersionAccordion';
import AddIconAccordion from '../../../../components/addIconAccordion/AddIconAccordion';

interface IProductAccordion {
    productsData: IProductsData;
}

const ProductAccordion = ({ productsData }: IProductAccordion) => {
    const dispatch = useAppDispatch();
    const { postProductVersionData, productVersionModalType } = useAppSelector((state) => state.products);
    const navigate = useNavigate();

    const [selectedProductTable, setSelectedProductTable] = useState<IProductVersionsTableData[]>([]);
    const [accordionData, setAccordionData] = useState<IProductsData>({
        name: '',
        description: '',
        enabled: true,
        sapId: '',
        machineDependent: true,
        productVersions: [],
        isHardlockControlled: true,
        id: 0
    });

    const onAddNewVersion = () => {
        dispatch(getDependentFeatures());
        dispatch(setProductVersionModalType(modalType.ADD));
        dispatch(setEditVersionProductId(productsData?.id));
    };

    useEffect(() => {
        if (productsData?.id) {
            setAccordionData(productsData);
        }
    }, [productsData]);

    useEffect(() => {
        const accordionOpenedData = sessionStorage.getItem('accordionOpened');
        let openedAccordion: IAccordionObject = {};
        if (accordionOpenedData) {
            openedAccordion = JSON.parse(accordionOpenedData);
        }
        let isAccordionOpened = false;
        let openedData: any = [];
        if (openedAccordion && openedAccordion[productType] && openedAccordion[productType][accordionLevel2]) {
            openedData = openedAccordion[productType][accordionLevel2].map((id) => id.toString());
            isAccordionOpened = true;
        }
        const tempData = productsData?.productVersions.map((item: IProductVersionsTableData) => ({
            id: item.id,
            _key: item.id,
            _accordionContent: <ProductVersionAccordion versionId={item.id} />,
            enabled: item.enabled ? 1 : 2,
            modified: item.modified,
            version: dateTimeFormatConversion(item.version, 'datetime'),
            _option: (
                <ProductVersionTableOptions
                    onClickEdit={() => {
                        dispatch(setProductVersionModalType(modalType.EDIT));
                        dispatch(getDependentFeatures());
                        dispatch(getProductVersionById(item.id));
                        dispatch(setEditVersionProductId(productsData?.id));
                    }}
                    onClickAssociatedProducts={() => {
                        navigate(`/sales-products/associatedProductionSites/${item.id}`);
                    }}
                />
            ),
            isAccordionOpen: openedData.includes(item.id.toString())
        }));
        setSelectedProductTable(tempData);
    }, [productsData, postProductVersionData]);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempModalData = { ...accordionData };
        tempModalData = {
            ...tempModalData,
            [event.target.name]: event.target.value
        };
        setAccordionData(tempModalData);
    };

    const handleOnBlur = (name: string, value: string | number | undefined) => {
        if (productsData[name as keyof IProductsData] !== value) {
            if (name === 'name') {
                if (value === '') {
                    setAccordionData((prevData: IProductsData) => ({
                        ...prevData,
                        [name]: productsData[name]
                    }));
                    return;
                }
            }
            // This check added to check that previous value same as changed value
            dispatch(putProduct({ body: accordionData, productId: accordionData?.id }));
        }
    };

    const handleOnToggleChange = (name: string, value: boolean) => {
        let tempModalData = { ...accordionData };
        tempModalData = {
            ...tempModalData,
            [name]: value
        };
        dispatch(putProduct({ body: tempModalData, productId: tempModalData?.id }));
    };

    return (
        <FlexboxContainer
            flexDirection="column"
            gap="1rem"
            className="Accordion_wrapper Full_width"
            justifyContent="start"
            alignItems="stretch"
        >
            <FlexboxItem className="Full_width">
                <FlexboxItem className="Half_width">
                    <FlexboxContainer flexDirection="column" justifyContent="start" alignItems="start" gap="0.625rem">
                        {/* <FlexboxItem className="Full_width">
                            <KeyValueInput
                                title={getMultiTermText({ id: '99034224', text: 'Hardlock product Id' })}
                                isTitleRequired
                                value={
                                    <TextField
                                        inPlaceEdit
                                        className="Full_width SapId_input"
                                        value={accordionData?.hardlockProductId}
                                        onChange={handleOnChange}
                                        onBlur={() => handleOnBlur('hardlockProductId', accordionData?.sapId)}
                                        name="hardlockProductId"
                                    />
                                }
                            />
                        </FlexboxItem> */}
                        <FlexboxItem className="Full_width">
                            <KeyValueInput
                                title={getMultiTermText({ id: '99034223', text: 'Hardlock controlled' })}
                                value={
                                    <ToggleSwitch
                                        className="Toggle_switch Enabled_toggle_switch"
                                        checked={accordionData?.isHardlockControlled}
                                        value={accordionData?.isHardlockControlled}
                                        onChange={(value: boolean) =>
                                            handleOnToggleChange('isHardlockControlled', value)
                                        }
                                    />
                                }
                            />
                        </FlexboxItem>
                    </FlexboxContainer>
                </FlexboxItem>
                <FlexboxContainer flexDirection="row" justifyContent="space-between">
                    <FlexboxItem>
                        <FlexboxContainer flexDirection="row" gap="0.313rem">
                            <FlexboxItem>
                                <img
                                    src={IconVersions}
                                    alt="icon-interface"
                                    className="Product_release_heading_icon"
                                    style={{ height: '30px', width: '30px' }}
                                />
                            </FlexboxItem>
                            <Typography type="B">
                                <LiMultiterm textId="03001657" textVal="Versions" />
                            </Typography>
                        </FlexboxContainer>
                    </FlexboxItem>
                    <FlexboxItem onClick={onAddNewVersion}>
                        <AddIconAccordion
                            isModalActive={productVersionModalType === modalType.ADD}
                            dataTestId="Product_version_add_icon"
                        />
                    </FlexboxItem>
                </FlexboxContainer>
            </FlexboxItem>

            <FlexboxItem className="Full_width Inner_level_one_table">
                <Table
                    className="productVersion_table"
                    accordion
                    isManualSortEnabled
                    headerData={selectedProductHeaderData}
                    tableHeaderClassNameForStyle="Table_inner_header"
                    isSingleSelectable={false}
                    rowsData={selectedProductTable}
                    tableHeaderRowStyles={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1
                    }}
                    isPrintable={false}
                    isMultiSelectable={false}
                    tableConfiguration={{
                        pagination: false,
                        pageOptions: [0],
                        showTableSettings: false,
                        showSearch: false,
                        sortAllRows: false,
                        showRowCount: false
                    }}
                    pagination={false}
                    tableId="selectedProductsTable"
                    onAccordionToggle={(data: any) => {
                        const obj = JSON.stringify(setAccordionOpenedId(accordionLevel2, data.id, productType, data));
                        sessionStorage.setItem('accordionOpened', obj);
                    }}
                />
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default ProductAccordion;
