/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FlexboxContainer, FlexboxItem, Table, Popup } from 'ui-components';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import PageHeader from '../pageHeader/PageHeader';
import FloatingButton from '../floatingButton/FloatingButton';

interface IOverviewScreen {
    pageHeaderMultiTermId: string;
    pageHeaderMultiTermText: string;
    tableClassName: string;
    headerData: any;
    rowsData: any;
    initialColumnSortData?: any;
    tableId: string;
    addButtonMultiTermId?: string;
    addButtonMultiTermText?: string;
    enableAccordion?: boolean;
    addButtonOnClick?: () => void;
    onPatchRowData?: (rowData: any) => void;
    onAccordionToggle?: (data: any) => void;
    showEmptyPatchWarningMessage?: boolean;
    onClosePatchWarningMessage?: () => void;
    onSearchIconClick?: (isFilterFieldsOpen: boolean) => void
    onScrollToId?: number;
}

const OverviewScreen: React.FC<IOverviewScreen> = ({
    pageHeaderMultiTermId,
    pageHeaderMultiTermText,
    tableClassName,
    headerData,
    rowsData,
    initialColumnSortData,
    tableId,
    addButtonMultiTermId,
    addButtonMultiTermText,
    enableAccordion,
    addButtonOnClick,
    onPatchRowData,
    onAccordionToggle,
    showEmptyPatchWarningMessage,
    onClosePatchWarningMessage,
    onSearchIconClick,
    onScrollToId 
}) => {
    const [sortData, setSortData] = useState<any>(initialColumnSortData);

    useEffect(()=>{
        setTimeout(()=>{
            if(onScrollToId)
            document.getElementById(`TableRow_${onScrollToId}`)?.scrollIntoView()},1000)
    },[onScrollToId])

    return (
        <FlexboxContainer
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            className="Full_width Full_height Customer_management"
        >
            <FlexboxItem className="Full_width">
                <PageHeader HeaderMultiTermId={pageHeaderMultiTermId} HeaderTitle={pageHeaderMultiTermText} />
            </FlexboxItem>
            <FlexboxItem className="Full_width Main_Table_wrapper">
                <Table
                    className={tableClassName}
                    accordion={enableAccordion}
                    isManualSortEnabled
                    columnSort={sortData}
                    extendedSort={(data: any) => {
                        setSortData(data);
                    }}
                    headerData={headerData}
                    isMultiSelectable={false}
                    isSingleSelectable={false}
                    rowsData={rowsData}
                    tableHeaderRowStyles={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1
                    }}
                    tableConfiguration={{
                        pagination: true,
                        pageOptions: [20, 50, 100, 200],
                        showTableSettings: true,
                        showSearch: true,
                        sortAllRows: true,
                        showRowCount: true,
                        showExportButton: true
                    }}
                    pagination
                    tableId={tableId}
                    onDataChange={onPatchRowData}
                    onAccordionToggle={(data: any) => {
                        if (onAccordionToggle) onAccordionToggle(data);
                    }}
                    onSearchIconClick={onSearchIconClick}
                />
            </FlexboxItem>
            {addButtonMultiTermText && addButtonMultiTermId && addButtonOnClick && (
                <FloatingButton
                    multiTermId={addButtonMultiTermId}
                    multiTermText={addButtonMultiTermText}
                    onClick={addButtonOnClick}
                />
            )}
            {showEmptyPatchWarningMessage && (
                <Popup
                    onClose={onClosePatchWarningMessage}
                    iconPopupStyle={{ padding: '0px' }}
                    mode="modal"
                    popupInnerStyle={{ padding: '5px' }}
                >
                    <div style={{ padding: '20px' }}>
                        <LiMultiterm textId="99033195" textVal="This field cannot be empty" />
                    </div>
                </Popup>
            )}
        </FlexboxContainer>
    );
};

OverviewScreen.defaultProps = {
    addButtonMultiTermId: '',
    addButtonMultiTermText: '',
    enableAccordion: true,
    showEmptyPatchWarningMessage: false,
    addButtonOnClick: () => {},
    onAccordionToggle: () => {},
    onPatchRowData: () => {},
    onClosePatchWarningMessage: () => {},
    initialColumnSortData: {},
    onSearchIconClick: () => {},
    onScrollToId: undefined
};

export default OverviewScreen;
