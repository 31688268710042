import React, { useEffect, useCallback, useState } from 'react';
import { Table } from 'ui-components';

import hardlockByProductionSitesHeaderData from '../../constants/hardlockByProductionSitesHeaderData';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { RootState } from '../../../../store';
import { accordionLevel3, customerType } from '../../../../utils/appConstants';
import HardlockProductionSitesAccordion from '../hardlockProductionSitesAccordion/HardlockProductionSitesAccordion';
import {
    getCustomerProducts,
    patchHardlockProductEntitlement,
    getLatestV2C,
    setLatestFileDownloaded,
    clearV2cDownloadedData
} from '../../slice/index';
import { getMultiTermText } from '../../../../utils/commonUtils';

import { IHardlockProductionSites, ICustomerProductsHardlock } from '../../../../@types/customers';
import HardlockProductionSitesTableOptions from '../hardlockProductionSitesTableOptions/HardlockProductionSitesTableOptions';
import downloadFile from '../../utils/productionSitesUtils';

interface ICustomerAccordion {
    productSitesId: number;
}

const HardlockProductionSites = ({ productSitesId }: ICustomerAccordion) => {
    const dispatch = useAppDispatch();
    const { customerProductionSites, allCustomerProducts, hardlockProducts, latestV2C, latestV2CFileDownloaded } =
        useAppSelector((state: RootState) => state.customers);
    const { statusCode } = useAppSelector((state: RootState) => state.errorSlice);

    const [hardlockId, setHardlockId] = useState<number>();

    useEffect(() => {
        dispatch(getCustomerProducts({ ishardlockControlled: true, productionSiteId: productSitesId }));
    }, []);

    useEffect(() => {
        if (hardlockProducts?.id) {
            downloadFile(
                `${hardlockProducts.id}_${hardlockProducts.lisecSerialNo}.v2c`,
                hardlockProducts.v2CFile,
                'normal'
            );
        }
    }, [hardlockProducts]);

    useEffect(() => {
        if (!latestV2CFileDownloaded || statusCode === 204) {
            return;
        }
        const xmlData = latestV2C;
        const fileName = `${hardlockId}.v2c`;
        downloadFile(fileName, xmlData, 'xml');
        dispatch(clearV2cDownloadedData());
    }, [latestV2C]);

    const getHardlockByProductionSitesTableData = (
        hardlockByProductionSitesHistoryTable: Array<IHardlockProductionSites>
    ) => {
        const accordionOpenedData = sessionStorage.getItem('accordionOpened');
        let openedAccordion: any = {};
        if (accordionOpenedData) {
            openedAccordion = JSON.parse(accordionOpenedData);
        }
        return hardlockByProductionSitesHistoryTable?.map((eachData: IHardlockProductionSites, index: number) => {
            let isAccordionOpened = false;
            if (openedAccordion && openedAccordion[customerType] && openedAccordion[customerType][accordionLevel3]) {
                const openedData = openedAccordion[customerType][accordionLevel3].map((id: any) => id.toString());
                if (openedData.includes(index.toString())) {
                    isAccordionOpened = true;
                }
            }
            const hasDownloadUpdateEnabled = eachData?.hasV2CFile;
            const hasGenerateUpdateEnabled = eachData?.customerProductIds?.length >= 1;

            return {
                key: index,
                serialNo: eachData?.lisecSerialNo,
                hardlockId: eachData?.hardlockId,
                hardlockType: eachData?.hardlockTypeName,
                assignedProducts:
                    eachData?.customerProductIds?.length >= 1 ? (
                        <>
                            {eachData?.customerProductIds?.length}{' '}
                            <>{getMultiTermText({ text: 'Product(s)', id: '99034289' })}</>
                        </>
                    ) : (
                        getMultiTermText({ text: 'None', id: '99000810' })
                    ),

                _option:
                    hasDownloadUpdateEnabled || hasGenerateUpdateEnabled ? (
                        <HardlockProductionSitesTableOptions
                            enableGenerateUpdate={hasGenerateUpdateEnabled}
                            onClickGenerateUpdate={() => {
                                dispatch(patchHardlockProductEntitlement({ hardlockId: eachData.hardlockId }));
                            }}
                            onClickDownload={() => {
                                setHardlockId(eachData?.hardlockId);
                                dispatch(setLatestFileDownloaded(true));
                                dispatch(getLatestV2C({ hardlockId: eachData.hardlockId }));
                            }}
                            enableDownloadUpdate={hasDownloadUpdateEnabled}
                        />
                    ) : null,
                _accordionContent: (
                    <HardlockProductionSitesAccordion
                        customerProducts={eachData?.customerProductIds}
                        allCustomerProducts={allCustomerProducts}
                        hardlockId={eachData?.hardlockId}
                    />
                )
            };
        });
    };

    return (
        <Table
            className="hardlockProductionSites"
            accordion
            isManualSortEnabled
            headerData={hardlockByProductionSitesHeaderData}
            tableHeaderClassNameForStyle="Table_inner_header"
            isSingleSelectable={false}
            rowsData={getHardlockByProductionSitesTableData(
                customerProductionSites?.[productSitesId]?.productionSites?.hardlock
            )}
            tableHeaderRowStyles={{
                position: 'sticky',
                top: 0,
                zIndex: 1
            }}
            isPrintable={false}
            isMultiSelectable={false}
            tableConfiguration={{
                pagination: false,
                pageOptions: [0],
                showTableSettings: false,
                showSearch: false,
                sortAllRows: false,
                showRowCount: false
            }}
            pagination={false}
            tableId="hardlockByProductionSitesHistoryTable"
        />
    );
};

export default HardlockProductionSites;
