import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IErrorState {
    statusCode: number;
    message: string | null;
}
const initialState: IErrorState = {
    statusCode: 0,
    message: null
};

const errorSlice = createSlice({
    name: '/error',
    initialState,
    reducers: {
        setError: (state, action: PayloadAction<IErrorState>) => {
            state.message = action.payload.message;
            state.statusCode = action.payload.statusCode;
        },
        clearError: (state) => {
            state.message = null;
            state.statusCode = 0;
        }
    }
});

export const { setError, clearError } = errorSlice.actions;

export default errorSlice.reducer;
