import React, { useEffect } from 'react';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import {
    FlexboxContainer,
    FlexboxItem,
    Typography,
    TextField,
    ToggleSwitch,
    MultiList,
    Scrollable
} from 'ui-components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { flattenMultiList, modalType } from '../../../../utils/commonUtils';
import { getDependentFeatures, getLatestCustomerId, setcustomerModalData } from '../../slice/index';

const CustomerModal = () => {
    const { latestCustomerId, customerModalData, customerModalType } = useAppSelector((state) => state.customers);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getDependentFeatures());
        dispatch(getLatestCustomerId());
    }, []);

    useEffect(() => {
        if (customerModalType === modalType.ADD) {
            const tempModalData = { ...customerModalData };
            tempModalData.id = latestCustomerId ? latestCustomerId + 1 : 1;
            dispatch(setcustomerModalData(tempModalData));
        }
    }, [latestCustomerId]);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempModalData = { ...customerModalData };
        tempModalData = {
            ...tempModalData,
            [event.target.name]: event.target.value
        };
        dispatch(setcustomerModalData(tempModalData));
    };

    const handleOnToggleChange = (name: string, value: boolean) => {
        let tempModalData = { ...customerModalData };
        tempModalData = {
            ...tempModalData,
            [name]: value
        };
        dispatch(setcustomerModalData(tempModalData));
    };

    return (
        <FlexboxContainer
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="0.5rem"
            className="Full_height"
        >
            <FlexboxItem>
                <Typography type="H1">
                    {customerModalType === modalType.ADD ? (
                        <LiMultiterm textId="99025143" textVal="Add Customer" />
                    ) : (
                        <LiMultiterm textId="99025142" textVal="Edit Customer" />
                    )}
                </Typography>
            </FlexboxItem>
            <FlexboxItem>
                <FlexboxContainer alignItems="start" justifyContent="start">
                    <FlexboxItem className="Modal_each_side_wrapper">
                        <FlexboxContainer flexDirection="column" gap="1.25rem" alignItems="start">
                            <FlexboxItem className="Full_width">
                                <TextField
                                    className="Id_input"
                                    title={<LiMultiterm textId="99001692" textVal="ID #" />}
                                    isRequired
                                    disabled={customerModalType === modalType.EDIT}
                                    onChange={handleOnChange}
                                    name="id"
                                    errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                                    value={customerModalData?.id}
                                />
                            </FlexboxItem>
                            <FlexboxItem className="Full_width">
                                <TextField
                                    title={<LiMultiterm textId="6001104" textVal="Name" />}
                                    onChange={handleOnChange}
                                    name="description"
                                    value={customerModalData?.description}
                                    errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                                />
                            </FlexboxItem>
                            <FlexboxItem className="Full_width">
                                <TextField
                                    className="Short_name_input"
                                    title={<LiMultiterm textId="99002657" textVal="Short name" />}
                                    isRequired
                                    onChange={handleOnChange}
                                    name="name"
                                    value={customerModalData?.name}
                                    errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                                />
                            </FlexboxItem>
                            <FlexboxItem className="Full_width">
                                <ToggleSwitch
                                    checked={customerModalData?.enabled}
                                    value={customerModalData?.enabled}
                                    className="Toggle_switch"
                                    onChange={(data: boolean) => handleOnToggleChange('enabled', data)}
                                    switchPrefix={<LiMultiterm textId="99000784" textVal="Enabled" />}
                                />
                            </FlexboxItem>
                        </FlexboxContainer>
                    </FlexboxItem>
                </FlexboxContainer>
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default CustomerModal;
