import React from 'react';
import { SubOptionListComponent } from 'ui-components';
import { ITableOptions } from '../../../@types/products';
import { ICustomerFeatues } from '../../../@types/customers';
import { flattenMultiList, getMultiTermText } from '../../../utils/commonUtils';

export default function extractFeatureIds(customerFeatures: ICustomerFeatues[]): number[] {
    return customerFeatures?.map((item) => item.id);
}

export const FeatureTableOptions = ({ onClickEdit }: ITableOptions) => {
    const optionList = {
        list: [
            {
                title: getMultiTermText({ text: 'Edit', id: '99006184' }),
                click: onClickEdit,
                key: 'edit'
            }
        ]
    };
    return <SubOptionListComponent optionList={optionList} />;
};

export const isAnyOfSubLevelChecked = (array: any) => {
    const flattenSubLevelArray = flattenMultiList(array);
    return flattenSubLevelArray?.filter((eachData) => eachData.checked).length > 0;
};
