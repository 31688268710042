/* eslint-disable camelcase */
import { createAsyncThunk, createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiBody, MimeTypes } from 'lisecutilityfunctions/lib/openapi';
import {
    IProductsData,
    IPostProductVersionData,
    IProductVersionData,
    IProductVersionIdDetails
} from '../../../@types/products';

import { IDependentFeatures } from '../../../@types/customers';
import { IErrorState, setError } from '../../../slice/ErrorHandling';
import OpenApiClient_customer_management_v2 from '../../../api/openapiclient_customer_management_v2';
import { modalType } from '../../../utils/commonUtils';

export interface IProductsInitialState {
    isLoading: boolean;
    productsData: Array<IProductsData>;
    productVersionModalType: string;
    dependentFeaturesData: Array<IDependentFeatures>;
    latestCustomerId: number;
    productionSitesModalData: any;
    postProductVersionData: IPostProductVersionData;
    currentProductVersionEnableStatus: boolean;
    productsModalData: IProductsData;
    latestProductId: number;
    productModalType: string;
    productVersionData: any;
    deleteProductId: number;
    openedProductVersionIds: IProductVersionIdDetails;
    editVersionProductId: number,
    postProductResponseData: any
}

export const initialProductVersionData = {
    id: 0,
    productId: 0,
    version: '',
    modified: '',
    enabled: true,
    productFeatures: []
};

const initialModalData = {
    description: '',
    enabled: true,
    id: 0,
    machineDependent: false,
    name: '',
    sapId: '',
    productVersions: [],
    isHardlockControlled: false,
    hardlockProductId: 0
};

const initialState: IProductsInitialState = {
    isLoading: false,
    latestCustomerId: 0,
    productsData: [],
    dependentFeaturesData: [],
    productionSitesModalData: [],
    productVersionModalType: modalType.CLOSED,
    postProductVersionData: initialProductVersionData,
    currentProductVersionEnableStatus: true,
    productsModalData: initialModalData,
    latestProductId: 0,
    productModalType: modalType.CLOSED,
    deleteProductId: 0,
    productVersionData: [],
    openedProductVersionIds: {},
    editVersionProductId: 0,
    postProductResponseData: {}
};

/**
 * @function getProducts
 * @description Function to fetch all getDependentFeatures list
 */
export const getProducts = createAsyncThunk('getProducts', async (_, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_Products();
        if (response?.status === 204) {
            return [];
        }
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data);
        return responseData;
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in getProducts '
        };
        return dispatch(setError(errorValue));
    }
});

/**
 * @function getProductVersionDetailId
 * @description Function to fetch product version detail using product id
 */
export const getProductVersionDetailId = createAsyncThunk(
    'getProductVersionDetailId',
    async (versionId: number, { dispatch }) => {
        try {
            const response =
                await OpenApiClient_customer_management_v2.getClient(null).GET_ProductVersions_id(versionId);
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            const responseData = conversionLib.convertToJSON(response.data);
            return responseData;
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'error in getProductVersionDetailId '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function putProduct
 * @description Function to put product
 */
export const putProduct = createAsyncThunk(
    'putProduct',
    async ({ body, productId }: { body: IProductsData; productId: number }, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).PUT_Products_id(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, body),
                productId
            );
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            return dispatch(getProducts());
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'Error in putProduct '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function putProductVersion
 * @description Function to put productVersion
 */
export const putProductVersion = createAsyncThunk(
    'putProductVersion',
    async ({ body, productVersionId }: { body: IPostProductVersionData; productVersionId: number }, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).PUT_ProductVersions_id(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, body),
                productVersionId
            );
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            dispatch(getProducts());
            return dispatch(getProductVersionDetailId(productVersionId));
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'Error in putProductVersion '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function getLatestProductId
 * @description Function to fetch latest product id
 */
export const getLatestProductId = createAsyncThunk('getLatestProductId', async (_, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_Products(null, 1, null, '-id', [
            'id'
        ]);
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data)[0].id;
        return responseData;
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in getLatestProductId '
        };
        return dispatch(setError(errorValue));
    }
});

/**
 * @function getDependentFeatures
 * @description Function to fetch all getDependentFeatures list
 */
export const getDependentFeatures = createAsyncThunk('getDependentFeatures', async (_, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_Features_DependentFeatures();
        if (response?.status === 204) {
            return [];
        }
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data);
        return responseData;
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in getDependentFeatures '
        };
        return dispatch(setError(errorValue));
    }
});

/**
 * @function getProductVersionById
 * @description Function to fetch individual ProductVersion data by id
 */
export const getProductVersionById = createAsyncThunk(
    'getProductVersionById',
    async (productVersionId: number, { dispatch }) => {
        try {
            const response =
                await OpenApiClient_customer_management_v2.getClient(null).GET_ProductVersions_id(productVersionId);
            if (response?.status === 204) {
                return [];
            }
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            const responseData = conversionLib.convertToJSON(response.data);
            return responseData;
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'error in getProductVersionById '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function deleteProduct
 * @description Function to delete product
 */
export const deleteProduct = createAsyncThunk('deleteProduct', async (productId: number, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).DELETE_Products_id(productId);
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        return dispatch(getProducts());
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'Error in deleteProduct '
        };
        return dispatch(setError(errorValue));
    }
});

/** @function postProductVersion
 * @description Function to do post Product version
 */
export const postProductVersion = createAsyncThunk(
    'postProductVersion',
    async (postProductVersionFunction: IPostProductVersionData, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).POST_ProductVersions(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, postProductVersionFunction)
            );
            if (response?.status !== 201) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            return dispatch(getProducts());
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'error in postProductVersion '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function postProduct
 * @description Function to do post products
 */
export const postProduct = createAsyncThunk('postProduct', async (postProductBody: IProductsData, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).POST_Products(
            new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, postProductBody)
        );
        if (response?.status !== 201) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data);
        const postProductVersionBody = {
            enabled: true,
            modified: new Date().toISOString(),
            productId: responseData.id,
            version: new Date().toISOString()
        };
        dispatch(postProductVersion(postProductVersionBody));
        return responseData
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in postProduct '
        };
        return dispatch(setError(errorValue));
    }
});

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setProductionSitesModalData: (state, action: PayloadAction<any>) => {
            state.productionSitesModalData = action.payload;
        },
        setDependentFeaturesData: (state, action: PayloadAction<any>) => {
            state.dependentFeaturesData = action.payload;
        },
        setProductVersionModalType: (state, action: PayloadAction<string>) => {
            state.productVersionModalType = action.payload;
        },
        setProductModalType: (state, action: PayloadAction<string>) => {
            state.productModalType = action.payload;
            if (action.payload === modalType.CLOSED) {
                state.productsModalData = initialModalData;
                state.postProductVersionData = initialProductVersionData;
                state.deleteProductId = 0;
            }
        },
        setPostProductVersionData: (state, action: PayloadAction<any>) => {
            state.postProductVersionData = action.payload;
        },
        setCurrentProductVersionEnableStatus: (state, action: PayloadAction<boolean>) => {
            state.currentProductVersionEnableStatus = action.payload;
        },
        setProductModalData: (state, action: PayloadAction<any>) => {
            state.productsModalData = action.payload;
        },
        setEditVersionProductId: (state, action: PayloadAction<number>) => {
            state.editVersionProductId = action.payload;
        },
        clearPostProductResponseData: (state, action: PayloadAction<any>) => {
            state.postProductResponseData = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProducts.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getProducts.fulfilled, (state, action: PayloadAction<Array<IProductsData>>) => {
            state.isLoading = false;
            state.productsData = action.payload;
        });
        builder.addCase(getProducts.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(putProduct.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(putProduct.fulfilled, (state) => {
            state.isLoading = false;
            state.dependentFeaturesData = [];
            state.productModalType = modalType.CLOSED;
        });
        builder.addCase(putProduct.rejected, (state) => {
            state.isLoading = false;
            state.productModalType = modalType.CLOSED;
        });

        builder.addCase(getDependentFeatures.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getDependentFeatures.fulfilled, (state, action: PayloadAction<Array<IDependentFeatures>>) => {
            state.isLoading = false;
            state.dependentFeaturesData = action.payload;
        });
        builder.addCase(getDependentFeatures.rejected, (state) => {
            state.isLoading = false;
        });

        builder.addCase(postProductVersion.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(postProductVersion.fulfilled, (state) => {
            state.isLoading = false;
            state.productVersionModalType = modalType.CLOSED;
            state.dependentFeaturesData = [];
            state.postProductVersionData = initialProductVersionData;
            state.currentProductVersionEnableStatus = true;
        });
        builder.addCase(postProductVersion.rejected, (state) => {
            state.isLoading = false;
        });

        builder.addCase(getLatestProductId.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getLatestProductId.fulfilled, (state, action: PayloadAction<number>) => {
            state.isLoading = false;
            state.latestProductId = action.payload;
        });
        builder.addCase(getLatestProductId.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(postProduct.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(postProduct.fulfilled, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.postProductResponseData =  action.payload;
            state.productModalType = modalType.CLOSED;
            state.productsModalData = initialModalData;
        });
        builder.addCase(postProduct.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getProductVersionById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getProductVersionById.fulfilled, (state, action: PayloadAction<Array<IProductVersionData>>) => {
            state.isLoading = false;
            state.productVersionData = action.payload;
        });
        builder.addCase(getProductVersionById.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(putProductVersion.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(putProductVersion.fulfilled, (state) => {
            state.isLoading = false;
            state.productVersionModalType = modalType.CLOSED;
            state.postProductVersionData = initialProductVersionData;
            state.currentProductVersionEnableStatus = true;
        });
        builder.addCase(putProductVersion.rejected, (state) => {
            state.isLoading = false;
        });

        builder.addCase(deleteProduct.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteProduct.fulfilled, (state) => {
            state.isLoading = false;
            state.productModalType = modalType.CLOSED;
            state.deleteProductId = 0;
        });

        builder.addCase(deleteProduct.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getProductVersionDetailId.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getProductVersionDetailId.fulfilled, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            const featureIds = action.payload.productFeatures.map((eachFeature: any) => eachFeature.featureId);
            const prodFeaturesIdsObj = {
                ...state.openedProductVersionIds,
                [action.payload.id]: featureIds
            };
            state.openedProductVersionIds = prodFeaturesIdsObj;
        });
        builder.addCase(getProductVersionDetailId.rejected, (state) => {
            state.isLoading = false;
        });
    }
});

export const {
    setProductionSitesModalData,
    setProductVersionModalType,
    setProductModalType,
    setDependentFeaturesData,
    setPostProductVersionData,
    setCurrentProductVersionEnableStatus,
    setProductModalData,
    setEditVersionProductId,
    clearPostProductResponseData
} = productsSlice.actions;
export default productsSlice.reducer;
