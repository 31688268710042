/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { FlexboxContainer, FlexboxItem, Typography, Table } from 'ui-components';
import { modalType } from '../../../../utils/commonUtils';
import apiTokenVersionHeaderData from '../../constants/apiTokenVersionHeaderData';
import { patchApiToken, setApiTokenModalData, setApiTokenModalType } from '../../slice/index';
import { IApiToken, IApiTokenVersion } from '../../../../@types/customers';
import IconVersions from '../../../../assets/Icons/IconVersions.svg';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import AddIconAccordion from '../../../../components/addIconAccordion/AddIconAccordion';

interface IApiTokenAccordion {
    apiTokenAccordianData: IApiToken;
    index: number;
}

const ApiTokenAccordion = ({ apiTokenAccordianData, index }: IApiTokenAccordion) => {
    const dispatch = useAppDispatch();
    const { apiTokenVersion, apiTokenModalType, apiTokenModalData } = useAppSelector((state) => state.customers);
    const [inRowPatchData, setInRowPatchData] = useState<any>({});

    const onHandlePatchRowData = (rowData: any) => {
        if (Object.keys(rowData).length > 0) {
            // const patchData = apiTokenVersion?.find((eachData: IApiTokenVersion) => eachData.id === inRowPatchData.id);
            const patchUpdatedData = {
                id: rowData.id,
                valid: rowData?.status,
                apiTokenType: apiTokenModalType,
                apiTokenData: apiTokenModalData
            };
            dispatch(patchApiToken({ body: patchUpdatedData }));
        }
    };

    useEffect(() => {
        onHandlePatchRowData(inRowPatchData);
    }, [inRowPatchData]);

    const onAddNewVersion = () => {
        const apiTokenModalStatus = {
            modalStatus: modalType.ADD_VERSION,
            type: ''
        };
        const apiTokenData = {
            ...apiTokenAccordianData,
            ApiTokenindex: index
        };
        dispatch(setApiTokenModalData(apiTokenData));
        dispatch(setApiTokenModalType(apiTokenModalStatus));
    };

    const getApiTokenVersionTableData = (apiTokenHistoryTable: Array<IApiTokenVersion>) =>
        apiTokenHistoryTable?.map((eachData: IApiTokenVersion) => ({
            _key: eachData.id,
            created: conversionLib.dateTimeFormatConversion(eachData.creationDate, 'datetime'),
            id: eachData.id,
            expiryDate: conversionLib.dateTimeFormatConversion(eachData.expires, 'datetime'),
            status: eachData.valid ? 1 : 2
        }));

    return (
        <FlexboxContainer
            flexDirection="column"
            gap="1rem"
            className="Accordion_wrapper Full_width"
            justifyContent="start"
            alignItems="stretch"
        >
            <FlexboxItem className="Full_width">
                <FlexboxContainer flexDirection="row" justifyContent="space-between">
                    <FlexboxItem>
                        <FlexboxContainer flexDirection="row" gap="0.313rem">
                            <FlexboxItem>
                                <img
                                    src={IconVersions}
                                    alt="icon-interface"
                                    className="Product_release_heading_icon"
                                    style={{ height: '30px', width: '30px' }}
                                />
                            </FlexboxItem>
                            <Typography type="B">
                                <LiMultiterm textId="03001657" textVal="Versions" />
                            </Typography>
                        </FlexboxContainer>
                    </FlexboxItem>
                    <FlexboxItem onClick={onAddNewVersion}>
                        <AddIconAccordion
                            isModalActive={apiTokenModalType === modalType.ADD_VERSION}
                            dataTestId="Product_version_add_icon"
                        />
                    </FlexboxItem>
                </FlexboxContainer>
            </FlexboxItem>

            <FlexboxItem className="Full_width Inner_level_one_table">
                <Table
                    className="product_table"
                    accordion={false}
                    isManualSortEnabled
                    headerData={apiTokenVersionHeaderData}
                    tableHeaderClassNameForStyle="Table_inner_header"
                    isSingleSelectable={false}
                    rowsData={getApiTokenVersionTableData(apiTokenVersion[index])}
                    tableHeaderRowStyles={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1
                    }}
                    isPrintable={false}
                    isMultiSelectable={false}
                    tableConfiguration={{
                        pagination: false,
                        pageOptions: [0],
                        showTableSettings: false,
                        showSearch: false,
                        sortAllRows: false,
                        showRowCount: false
                    }}
                    onDataChange={(rowData: any) => onHandlePatchRowData(rowData)}
                    pagination={false}
                    tableId="selectedProductsTable"
                />
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default ApiTokenAccordion;
