import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import {
    deleteFeature,
    getFeatures,
    patchFeature,
    postFeature,
    putFeature,
    setFeatureModalData,
    setFeatureModalType,
    clearPostFeatureResponseData
} from './slice/Feature';
import { featureTableHeaderData } from './constants/HeaderData';
import { isObjectEntriesSame, setAccordionOpenedId, modalType } from '../../utils/commonUtils';
import { IFeaturesData } from '../../@types/features';
import { useAppDispatch, useAppSelector } from '../../hooks';
import FeatureModal from './components/FeatureModal/FeatureModal';
import ActionModal from '../../components/actionModal/ActionModal';
import FeatureTableOptions from './components/FeatureTableOptions/FeatureTableOptions';
import OverviewScreen from '../../components/overviewScreen/OverviewScreen';
import DeleteModalContent from '../../components/deleteModalContent/DeleteModalContent';

import FeatureAccordion from './components/FeatureAccordion/FeatureAccordion';
import { accordionLevel1, featureType } from '../../utils/appConstants';
import { IAccordionObject } from '../../@types/common';
import './Features.css';

const Features = ({ globalContext }: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isLoading, featuresData, featureModalType, featureModalData, featureIdToDelete, postFeatureResponseData } = useAppSelector(
        (state) => state.features
    );
    const [inRowPatchData, setInRowPatchData] = useState<any>({});    
    const [showEmptyPatchWarningMessage, setShowEmptyPatchWarningMessage] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getFeatures());
    }, []);

    const { setLoader } = globalContext;

    useEffect(() => {
        setLoader({
            loading: isLoading
        });
    }, [isLoading]);

    const onHandlePatchRowData = () => {
        const patchData = featuresData.find((eachData) => eachData.id === inRowPatchData.id);
        if (inRowPatchData?.name === '') {
            setShowEmptyPatchWarningMessage(true);
            return;
        }
        const patchPreviousData = {
            name: patchData?.name,
            description: patchData?.description,
            sapId: patchData?.sapId,
            modified: patchData?.modified,
            enabled: patchData?.enabled
        };

        const patchUpdatedData = {
            name: inRowPatchData?.name,
            description: inRowPatchData?.description,
            sapId: inRowPatchData?.sapId,
            modified: patchData?.modified,
            enabled: inRowPatchData?.status === 1
        };
        if (!isObjectEntriesSame(patchUpdatedData, patchPreviousData) && patchData?.id) {
            dispatch(patchFeature({ body: patchUpdatedData, featureId: patchData?.id }));
        }
    };

    useEffect(() => {
        onHandlePatchRowData();
    }, [inRowPatchData]);

    const onClosePatchWarningMessage = () => {
        setShowEmptyPatchWarningMessage(false);
        dispatch(getFeatures());
    };

    const getFeatureTableData = (featureData: Array<IFeaturesData>) => {
        const accordionOpenedData = sessionStorage.getItem('accordionOpened');
        let openedAccordion: IAccordionObject = {};
        if (accordionOpenedData) {
            openedAccordion = JSON.parse(accordionOpenedData);
        }

        return featureData.map((eachData) => {
            let isAccordionOpened = false;
            if (openedAccordion && openedAccordion[featureType] && openedAccordion[featureType][accordionLevel1]) {
                const openedData = openedAccordion[featureType][accordionLevel1].map((id) => id.toString());
                if (openedData.includes(eachData.id.toString())) {
                    isAccordionOpened = true;
                }
            }
            return {
                _key: eachData.id,
                _option: (
                    <FeatureTableOptions
                        onClickEdit={() => {
                            dispatch(setFeatureModalType(modalType.EDIT));
                            dispatch(setFeatureModalData(eachData));
                        }}
                        onClickAssociatedProducts={() => {
                            navigate(`/features/associatedProducts/${eachData.id}`);
                        }}
                    />
                ),
                _accordionContent: <FeatureAccordion featureData={eachData} />,
                dependentOn: eachData.dependentOn?.toString(),
                id: eachData.id,
                description: eachData.description,
                name: eachData.name,
                sapId: eachData.sapId,
                isAccordionOpen: isAccordionOpened,
                status: eachData.enabled ? 1 : 2
            };
        });
    };

    const onAddFeatureButtonClick = () => {
        dispatch(setFeatureModalType(modalType.ADD));
    };

    const onCloseFeatureModal = () => {
        dispatch(setFeatureModalType(modalType.CLOSED));
    };

    const onClickFeatureModalAction = () => {
        switch (featureModalType) {
            case modalType.ADD: {
                const inputData = {
                    ...featureModalData,
                    dependentOn: [...new Set(featureModalData.dependentOn)]
                }
                dispatch(postFeature(inputData));
                break;
            }
            case modalType.EDIT: {
                const inputData = {
                    ...featureModalData,
                    dependentOn: [...new Set(featureModalData.dependentOn)]
                }
                dispatch(putFeature({ body: inputData, featureId: `${featureModalData.id}` }));
                break;
            }
            case modalType.DELETE: {
                dispatch(deleteFeature(featureIdToDelete));
                break;
            }
            default:
                break;
        }
    };

    const onHandleAccordionToggle = (data: any) => {
        const obj = JSON.stringify(setAccordionOpenedId(accordionLevel1, data.id, featureType, data));
        dispatch(clearPostFeatureResponseData({}))
        sessionStorage.setItem('accordionOpened', obj);
    };

    useEffect(()=>{
        if (postFeatureResponseData && postFeatureResponseData.id )
        onHandleAccordionToggle(postFeatureResponseData)
    },[postFeatureResponseData])

    return (
        <>
            <OverviewScreen
                pageHeaderMultiTermId="99032233"
                pageHeaderMultiTermText="Features"
                tableClassName="Features_table"
                headerData={featureTableHeaderData}
                rowsData={getFeatureTableData(featuresData)}
                initialColumnSortData={[
                    {
                        order: 'ascending',
                        columnKey: 'id'
                    }
                ]}
                tableId="featuresTable"
                addButtonMultiTermId="99032434"
                addButtonMultiTermText="Add feature"
                addButtonOnClick={onAddFeatureButtonClick}
                onPatchRowData={(rowData) => setInRowPatchData(rowData)}
                onAccordionToggle={onHandleAccordionToggle}
                showEmptyPatchWarningMessage={showEmptyPatchWarningMessage}
                onClosePatchWarningMessage={onClosePatchWarningMessage}
                onScrollToId={postFeatureResponseData?.id}
            />
            {featureModalType !== modalType.CLOSED && (
                <ActionModal
                    onClickAction={onClickFeatureModalAction}
                    onClickCancelClose={onCloseFeatureModal}
                    actionType={featureModalType}
                    modalColumnNumber={2}
                    ModalContent={
                        featureModalType !== modalType.DELETE ? (
                            <FeatureModal />
                        ) : (
                            <DeleteModalContent isDeletePermanentMessage={false} />
                        )
                    }
                    actionDisabled={
                        featureModalType !== modalType.DELETE
                            ? !featureModalData.id || !featureModalData.name
                            : false
                    }
                />
            )}
        </>
    );
};

export default Features;
