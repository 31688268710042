/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { FlexboxContainer, FlexboxItem, TextField, ToggleSwitch, Scrollable, MultiList } from 'ui-components';
import { getDependentFeatures, patchFeature } from '../../slice/Feature';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { IFeaturesData } from '../../../../@types/features';
import KeyValueInput from '../../../../components/keyValueInput/KeyValueInput';
import {
    flattenMultiList,
    getMultiTermText,
    getDataForDependentFeatures,
    getChildDependentData
} from '../../../../utils/commonUtils';

import './FeatureAccordion.css';
import { IMultiListArrayType } from '../../../../@types/customers';

interface IFeatureAccordion {
    featureData: IFeaturesData;
}

const FeatureAccordion = ({ featureData }: IFeatureAccordion) => {
    const dispatch = useAppDispatch();
    const { dependentFeaturesData } = useAppSelector((state) => state.features);
    const [accordionData, setAccordionData] = useState<IFeaturesData>({
        sapId: '',
        name: '',
        description: '',
        commercial: false,
        dependentOn: [],
        enabled: true,
        id: 0
    });
    const [childDependentIds, setChildDependentIds] = useState<Array<number>>([]);

    useEffect(() => {
        if (dependentFeaturesData.length === 0) {
            dispatch(getDependentFeatures());
        }
    }, []);

    useEffect(() => {
        if (featureData.id) {
            setAccordionData(featureData);
        }
    }, [featureData]);

    useEffect(() => {
        if (dependentFeaturesData.length > 0) {
            setChildDependentIds(getChildDependentData(dependentFeaturesData, featureData.id));
        }
    }, [dependentFeaturesData]);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'multitermId') {
            let tempModalData = { ...accordionData };
            tempModalData = {
                ...tempModalData,
                multitermId: +event.target.value.replace(/\D/g, '')
            };
            setAccordionData(tempModalData);
        } else {
            let tempModalData = { ...accordionData };
            tempModalData = {
                ...tempModalData,
                [event.target.name]: event.target.value
            };
            setAccordionData(tempModalData);
        }
    };

    const handleOnToggleChange = (name: string, value: boolean) => {
        dispatch(
            patchFeature({
                body: {
                    [name]: value,
                    modified: featureData?.modified
                },
                featureId: featureData.id
            })
        );
    };

    const onHandleMultiListChange = (data: Array<IMultiListArrayType>) => {
        if (accordionData.dependentOn === featureData.dependentOn) {
            const selectedDependentOnIds = flattenMultiList(data)
                .filter((eachData) => eachData.checked)
                .map((filteredData) => filteredData.id);
            dispatch(
                patchFeature({
                    body: {
                        dependentOn: [...new Set(selectedDependentOnIds)],
                        modified: featureData?.modified
                    },
                    featureId: featureData.id
                })
            );
        }
    };

    const handleOnBlur = (name: string, value: string | number | undefined) => {
        if (featureData[name as keyof IFeaturesData] !== value) {
            // This check added to check that previous value same as changed value
            if (name === 'name') {
                if (value === '') {
                    setAccordionData((prevData) => ({
                        ...prevData,
                        [name]: featureData[name]
                    }));
                    return;
                }
            }
            dispatch(
                patchFeature({
                    body: {
                        [name]: value,
                        modified: featureData?.modified
                    },
                    featureId: featureData.id
                })
            );
        }
    };

    return (
        <FlexboxContainer className="Accordion_wrapper Full_width" justifyContent="start" alignItems="stretch">
            <FlexboxItem className="Each_side_wrapper" flexGrow="1">
                <FlexboxContainer flexDirection="column" justifyContent="start" alignItems="start" gap="0.625rem">
                    <FlexboxItem className="Full_width">
                        <KeyValueInput
                            title={getMultiTermText({ id: '99008287', text: 'ID#' })}
                            isTitleRequired
                            value={<TextField disabled inPlaceEdit className="Full_width" value={accordionData?.id} />}
                        />
                    </FlexboxItem>
                    <FlexboxItem className="Full_width">
                        <KeyValueInput
                            title={getMultiTermText({ id: '99031088', text: 'SAP ID' })}
                            value={
                                <TextField
                                    inPlaceEdit
                                    className="Full_width SapId_input"
                                    value={accordionData?.sapId}
                                    onChange={handleOnChange}
                                    onBlur={() => handleOnBlur('sapId', accordionData?.sapId)}
                                    name="sapId"
                                />
                            }
                        />
                    </FlexboxItem>
                    <FlexboxItem className="Full_width">
                        <KeyValueInput
                            title={getMultiTermText({ id: '6001104', text: 'Name' })}
                            value={
                                <TextField
                                    inPlaceEdit
                                    className="Full_width Name_input"
                                    onChange={handleOnChange}
                                    onBlur={() => handleOnBlur('description', accordionData?.description)}
                                    name="description"
                                    value={accordionData?.description}
                                />
                            }
                        />
                    </FlexboxItem>
                    <FlexboxItem className="Full_width">
                        <KeyValueInput
                            title={getMultiTermText({ id: '99032757', text: 'Text number for name' })}
                            value={
                                <TextField
                                    inPlaceEdit
                                    className="Full_width Multi_term_input"
                                    onChange={handleOnChange}
                                    name="multitermId"
                                    onBlur={() => handleOnBlur('multitermId', accordionData?.multitermId)}
                                    value={accordionData?.multitermId || ''}
                                />
                            }
                        />
                    </FlexboxItem>
                    <FlexboxItem className="Full_width">
                        <KeyValueInput
                            title={getMultiTermText({ id: '99002657', text: 'Short name' })}
                            isTitleRequired
                            value={
                                <TextField
                                    inPlaceEdit
                                    onChange={handleOnChange}
                                    name="name"
                                    className="Full_width Short_name_input"
                                    onBlur={() => handleOnBlur('name', accordionData?.name)}
                                    value={accordionData?.name}
                                />
                            }
                        />
                    </FlexboxItem>
                    <FlexboxItem className="Full_width">
                        <KeyValueInput
                            title={getMultiTermText({ id: '99032432', text: 'Commercial feature' })}
                            value={
                                <ToggleSwitch
                                    className="Toggle_switch Commercial_toggle_switch"
                                    checked={featureData?.commercial}
                                    value={featureData?.commercial}
                                    onChange={(value: boolean) => handleOnToggleChange('commercial', value)}
                                />
                            }
                        />
                    </FlexboxItem>
                    <FlexboxItem className="Full_width">
                        <KeyValueInput
                            title={getMultiTermText({ id: '99000784', text: 'Enabled' })}
                            value={
                                <ToggleSwitch
                                    className="Toggle_switch Enabled_toggle_switch"
                                    checked={featureData?.enabled}
                                    value={featureData?.enabled}
                                    onChange={(value: boolean) => handleOnToggleChange('enabled', value)}
                                />
                            }
                        />
                    </FlexboxItem>
                </FlexboxContainer>
            </FlexboxItem>
            <FlexboxItem className="Vertical_separator" />
            <FlexboxItem className="Each_side_wrapper" flexGrow="1">
                {dependentFeaturesData.length > 0 && (
                    <Scrollable>
                        <MultiList
                            onChange={(data: Array<IMultiListArrayType>) => onHandleMultiListChange(data)}
                            title={getMultiTermText({ id: '99032433', text: 'Dependent on feature(s)' })}
                            showToggleForSelectedOnly
                            data={getDataForDependentFeatures(
                                dependentFeaturesData,
                                featureData?.dependentOn || [],
                                featureData?.id,
                                childDependentIds
                            )}
                        />
                    </Scrollable>
                )}
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default FeatureAccordion;
