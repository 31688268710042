import { axiosInstance, OpenApiClient, OpenApiClientArrayHelper, OpenApiException, OpenApiResponse, MimeTypes, directDownloadConfig } from 'lisecutilityfunctions/lib/api';

import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';

var Qs = require('qs');




class OpenApiClient_customer_management_v2 extends OpenApiClient {

    constructor(site, instance) {
        super(site, OpenApiClient_customer_management_v2.getServiceName(), instance, "v2");
        this.site = site;
    }

    static getClient(site, instance = null) {
        var key = site + ".customer_management";
        key += ".v2";
        if (instance !== null) {
            key += '.' + instance;
        }
        var service = OpenApiClient.serviceMap.get(key);

        if (service === undefined)
        {
            service = new OpenApiClient_customer_management_v2(site, instance);
            OpenApiClient.serviceMap.set(key, service);
        }
        return service;
    }

    static getServiceName() {
        if (window.config !== undefined && window.config.servicenameSubstitution.customer_management !== undefined ){
            return window.config.servicenameSubstitution.customer_management;
        }

        return 'customer_management';
    }


    async GET_Users(filterName, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsString(filterName, false, [])) {
                throw new OpenApiException("Parameter 'filterName' is not a valid string!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            if(filterName !== null) queryParams['filterName'] = filterName;
            const config = {
                url: self.getUrl(['Users'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Users'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Users', e);
            return e
        }
    }
    
    async GET_CustomerProducts(isHardlockControlled = null, Offset = null, Limit = null, Filter = null, SortBy = null, Select = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            
            if (!self.verifyParamIsInteger(Offset, true, [])) {
                throw new OpenApiException("Parameter 'Offset' is not a valid number!");
            }
            
            if (!self.verifyParamIsInteger(Limit, true, [])) {
                throw new OpenApiException("Parameter 'Limit' is not a valid number!");
            }
            
            if (!self.verifyParamIsString(Filter, true, [])) {
                throw new OpenApiException("Parameter 'Filter' is not a valid string!");
            }
            
            if (!self.verifyParamIsString(SortBy, true, [])) {
                throw new OpenApiException("Parameter 'SortBy' is not a valid string!");
            }
            
            if (!self.verifyParamIsStringArray(Select, true, [])) {
                throw new OpenApiException("Parameter 'Select' is not a valid string array!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            if(isHardlockControlled !== null) queryParams['isHardlockControlled'] = isHardlockControlled;
            if(Offset !== null) queryParams['Offset'] = Offset;
            if(Limit !== null) queryParams['Limit'] = Limit;
            if(Filter !== null) queryParams['Filter'] = Filter;
            if(SortBy !== null) queryParams['SortBy'] = SortBy;
            if(Select !== null) serializedParam['Select'] = OpenApiClientArrayHelper.GET_QUERY_serialized_array(Select, "STYLE_FORM", "Select", true);
            const config = {
                url: self.getUrl(['CustomerProducts'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerProducts'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_CustomerProducts', e);
            return e
        }
    }
    
    async POST_CustomerProducts(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['CustomerProducts'], serializedParam),
                method: 'post',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerProducts'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('POST_CustomerProducts', e);
            return e
        }
    }
    
    async GET_CustomerProducts_id(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['CustomerProducts', id], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerProducts', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_CustomerProducts_id', e);
            return e
        }
    }
    
    async PUT_CustomerProducts_id(body, id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['CustomerProducts', id], serializedParam),
                method: 'put',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerProducts', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_CustomerProducts_id', e);
            return e
        }
    }
    
    async DELETE_CustomerProducts_id(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['CustomerProducts', id], serializedParam),
                method: 'delete',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerProducts', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('DELETE_CustomerProducts_id', e);
            return e
        }
    }
    
    async PATCH_CustomerProducts_id(body, id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['CustomerProducts', id], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerProducts', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_CustomerProducts_id', e);
            return e
        }
    }
    
    async GET_CustomerProducts_audit(activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['CustomerProducts', 'audit'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerProducts', 'audit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_CustomerProducts_audit', e);
            return e
        }
    }
    
    async PATCH_CustomerProducts_filteredAudit(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['CustomerProducts', 'filteredAudit'], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerProducts', 'filteredAudit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_CustomerProducts_filteredAudit', e);
            return e
        }
    }
    
    async PATCH_CustomerProducts_partialFilteredAudit(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['CustomerProducts', 'partialFilteredAudit'], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerProducts', 'partialFilteredAudit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_CustomerProducts_partialFilteredAudit', e);
            return e
        }
    }
    
    async GET_CustomerProducts_audit_auditId(auditId, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(auditId, false, [])) {
                throw new OpenApiException("Parameter 'auditId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['CustomerProducts', 'audit', auditId], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'auditId': auditId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerProducts', 'audit', auditId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_CustomerProducts_audit_auditId', e);
            return e
        }
    }
    
    async PUT_CustomerProducts_restoreaudit_auditId(auditId, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(auditId, false, [])) {
                throw new OpenApiException("Parameter 'auditId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['CustomerProducts', 'restoreaudit', auditId], serializedParam),
                method: 'put',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'auditId': auditId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerProducts', 'restoreaudit', auditId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_CustomerProducts_restoreaudit_auditId', e);
            return e
        }
    }
    
    async POST_Features(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Features'], serializedParam),
                method: 'post',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Features'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('POST_Features', e);
            return e
        }
    }
    
    async GET_Features(Offset = null, Limit = null, Filter = null, SortBy = null, Select = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(Offset, true, [])) {
                throw new OpenApiException("Parameter 'Offset' is not a valid number!");
            }
            
            if (!self.verifyParamIsInteger(Limit, true, [])) {
                throw new OpenApiException("Parameter 'Limit' is not a valid number!");
            }
            
            if (!self.verifyParamIsString(Filter, true, [])) {
                throw new OpenApiException("Parameter 'Filter' is not a valid string!");
            }
            
            if (!self.verifyParamIsString(SortBy, true, [])) {
                throw new OpenApiException("Parameter 'SortBy' is not a valid string!");
            }
            
            if (!self.verifyParamIsStringArray(Select, true, [])) {
                throw new OpenApiException("Parameter 'Select' is not a valid string array!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            if(Offset !== null) queryParams['Offset'] = Offset;
            if(Limit !== null) queryParams['Limit'] = Limit;
            if(Filter !== null) queryParams['Filter'] = Filter;
            if(SortBy !== null) queryParams['SortBy'] = SortBy;
            if(Select !== null) serializedParam['Select'] = OpenApiClientArrayHelper.GET_QUERY_serialized_array(Select, "STYLE_FORM", "Select", true);
            const config = {
                url: self.getUrl(['Features'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Features'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Features', e);
            return e
        }
    }
    
    async GET_Features_LoadLicenseFeatures(activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Features', 'LoadLicenseFeatures'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Features', 'LoadLicenseFeatures'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Features_LoadLicenseFeatures', e);
            return e
        }
    }
    
    async GET_Features_LicenseFeature(activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Features', 'LicenseFeature'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Features', 'LicenseFeature'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Features_LicenseFeature', e);
            return e
        }
    }
    
    async GET_Features_DependentFeatures(activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Features', 'DependentFeatures'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Features', 'DependentFeatures'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Features_DependentFeatures', e);
            return e
        }
    }
    
    async GET_Features_id(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Features', id], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Features', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Features_id', e);
            return e
        }
    }
    
    async PUT_Features_id(body, id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Features', id], serializedParam),
                method: 'put',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Features', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_Features_id', e);
            return e
        }
    }
    
    async DELETE_Features_id(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Features', id], serializedParam),
                method: 'delete',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Features', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('DELETE_Features_id', e);
            return e
        }
    }
    
    async PATCH_Features_id(body, id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Features', id], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Features', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_Features_id', e);
            return e
        }
    }
    
    async GET_Features_id_AssociatedProducts(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Features', id, 'AssociatedProducts'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Features', id, 'AssociatedProducts'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Features_id_AssociatedProducts', e);
            return e
        }
    }
    
    async GET_Features_audit(activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Features', 'audit'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Features', 'audit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Features_audit', e);
            return e
        }
    }
    
    async PATCH_Features_filteredAudit(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Features', 'filteredAudit'], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Features', 'filteredAudit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_Features_filteredAudit', e);
            return e
        }
    }
    
    async PATCH_Features_partialFilteredAudit(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Features', 'partialFilteredAudit'], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Features', 'partialFilteredAudit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_Features_partialFilteredAudit', e);
            return e
        }
    }
    
    async GET_Features_audit_auditId(auditId, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(auditId, false, [])) {
                throw new OpenApiException("Parameter 'auditId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Features', 'audit', auditId], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'auditId': auditId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Features', 'audit', auditId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Features_audit_auditId', e);
            return e
        }
    }
    
    async PUT_Features_restoreaudit_auditId(auditId, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(auditId, false, [])) {
                throw new OpenApiException("Parameter 'auditId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Features', 'restoreaudit', auditId], serializedParam),
                method: 'put',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'auditId': auditId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Features', 'restoreaudit', auditId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_Features_restoreaudit_auditId', e);
            return e
        }
    }
    
    async GET_HardlockActionHistory(Offset = null, Limit = null, Filter = null, SortBy = null, Select = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(Offset, true, [])) {
                throw new OpenApiException("Parameter 'Offset' is not a valid number!");
            }
            
            if (!self.verifyParamIsInteger(Limit, true, [])) {
                throw new OpenApiException("Parameter 'Limit' is not a valid number!");
            }
            
            if (!self.verifyParamIsString(Filter, true, [])) {
                throw new OpenApiException("Parameter 'Filter' is not a valid string!");
            }
            
            if (!self.verifyParamIsString(SortBy, true, [])) {
                throw new OpenApiException("Parameter 'SortBy' is not a valid string!");
            }
            
            if (!self.verifyParamIsStringArray(Select, true, [])) {
                throw new OpenApiException("Parameter 'Select' is not a valid string array!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            if(Offset !== null) queryParams['Offset'] = Offset;
            if(Limit !== null) queryParams['Limit'] = Limit;
            if(Filter !== null) queryParams['Filter'] = Filter;
            if(SortBy !== null) queryParams['SortBy'] = SortBy;
            if(Select !== null) serializedParam['Select'] = OpenApiClientArrayHelper.GET_QUERY_serialized_array(Select, "STYLE_FORM", "Select", true);
            const config = {
                url: self.getUrl(['HardlockActionHistory'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['HardlockActionHistory'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_HardlockActionHistory', e);
            return e
        }
    }
    
    async POST_HardlockActionHistory(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['HardlockActionHistory'], serializedParam),
                method: 'post',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['HardlockActionHistory'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('POST_HardlockActionHistory', e);
            return e
        }
    }
    
    async GET_HardlockActionHistory_id_V2CFile(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['HardlockActionHistory', id, 'V2CFile'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['HardlockActionHistory', id, 'V2CFile'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_HardlockActionHistory_id_V2CFile', e);
            return e
        }
    }
    
    async GET_Hardlock(Offset = null, Limit = null, Filter = null, SortBy = null, Select = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(Offset, true, [])) {
                throw new OpenApiException("Parameter 'Offset' is not a valid number!");
            }
            
            if (!self.verifyParamIsInteger(Limit, true, [])) {
                throw new OpenApiException("Parameter 'Limit' is not a valid number!");
            }
            
            if (!self.verifyParamIsString(Filter, true, [])) {
                throw new OpenApiException("Parameter 'Filter' is not a valid string!");
            }
            
            if (!self.verifyParamIsString(SortBy, true, [])) {
                throw new OpenApiException("Parameter 'SortBy' is not a valid string!");
            }
            
            if (!self.verifyParamIsStringArray(Select, true, [])) {
                throw new OpenApiException("Parameter 'Select' is not a valid string array!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            if(Offset !== null) queryParams['Offset'] = Offset;
            if(Limit !== null) queryParams['Limit'] = Limit;
            if(Filter !== null) queryParams['Filter'] = Filter;
            if(SortBy !== null) queryParams['SortBy'] = SortBy;
            if(Select !== null) serializedParam['Select'] = OpenApiClientArrayHelper.GET_QUERY_serialized_array(Select, "STYLE_FORM", "Select", true);
            const config = {
                url: self.getUrl(['Hardlock'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Hardlock'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Hardlock', e);
            return e
        }
    }
    
    async POST_Hardlock(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Hardlock'], serializedParam),
                method: 'post',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Hardlock'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('POST_Hardlock', e);
            return e
        }
    }
    
    async GET_Hardlock_id(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Hardlock', id], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Hardlock', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Hardlock_id', e);
            return e
        }
    }
    
    async GET_Hardlock_id_LatestV2C(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Hardlock', id, 'LatestV2C'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Hardlock', id, 'LatestV2C'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Hardlock_id_LatestV2C', e);
            return e
        }
    }
    
    async PUT_Hardlock_id_C2VFile(body, id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Hardlock', id, 'C2VFile'], serializedParam),
                method: 'put',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Hardlock', id, 'C2VFile'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_Hardlock_id_C2VFile', e);
            return e
        }
    }
    
    async PATCH_Hardlock_id_ProduceEntitlement(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Hardlock', id, 'ProduceEntitlement'], serializedParam),
                method: 'patch',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Hardlock', id, 'ProduceEntitlement'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_Hardlock_id_ProduceEntitlement', e);
            return e
        }
    }
    
    async GET_HardlockType(Offset = null, Limit = null, Filter = null, SortBy = null, Select = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(Offset, true, [])) {
                throw new OpenApiException("Parameter 'Offset' is not a valid number!");
            }
            
            if (!self.verifyParamIsInteger(Limit, true, [])) {
                throw new OpenApiException("Parameter 'Limit' is not a valid number!");
            }
            
            if (!self.verifyParamIsString(Filter, true, [])) {
                throw new OpenApiException("Parameter 'Filter' is not a valid string!");
            }
            
            if (!self.verifyParamIsString(SortBy, true, [])) {
                throw new OpenApiException("Parameter 'SortBy' is not a valid string!");
            }
            
            if (!self.verifyParamIsStringArray(Select, true, [])) {
                throw new OpenApiException("Parameter 'Select' is not a valid string array!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            if(Offset !== null) queryParams['Offset'] = Offset;
            if(Limit !== null) queryParams['Limit'] = Limit;
            if(Filter !== null) queryParams['Filter'] = Filter;
            if(SortBy !== null) queryParams['SortBy'] = SortBy;
            if(Select !== null) serializedParam['Select'] = OpenApiClientArrayHelper.GET_QUERY_serialized_array(Select, "STYLE_FORM", "Select", true);
            const config = {
                url: self.getUrl(['HardlockType'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['HardlockType'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_HardlockType', e);
            return e
        }
    }
    
    async POST_HardlockType(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['HardlockType'], serializedParam),
                method: 'post',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['HardlockType'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('POST_HardlockType', e);
            return e
        }
    }
    
    async GET_HardlockType_id(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['HardlockType', id], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['HardlockType', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_HardlockType_id', e);
            return e
        }
    }
    
    async PUT_HardlockType_id(body, id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['HardlockType', id], serializedParam),
                method: 'put',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['HardlockType', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_HardlockType_id', e);
            return e
        }
    }
    
    async PATCH_HardlockType_id(body, id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['HardlockType', id], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['HardlockType', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_HardlockType_id', e);
            return e
        }
    }
    
    async GET_ProductionSites_id(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['ProductionSites', id], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductionSites', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_ProductionSites_id', e);
            return e
        }
    }
    
    async PUT_ProductionSites_id(body, id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['ProductionSites', id], serializedParam),
                method: 'put',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductionSites', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_ProductionSites_id', e);
            return e
        }
    }
    
    async PATCH_ProductionSites_id(body, id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['ProductionSites', id], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductionSites', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_ProductionSites_id', e);
            return e
        }
    }
    
    async DELETE_ProductionSites_id(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['ProductionSites', id], serializedParam),
                method: 'delete',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductionSites', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('DELETE_ProductionSites_id', e);
            return e
        }
    }
    
    async GET_ProductionSites_id_Hardlock(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['ProductionSites', id, 'Hardlock'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductionSites', id, 'Hardlock'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_ProductionSites_id_Hardlock', e);
            return e
        }
    }
    
    async POST_ProductionSites(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['ProductionSites'], serializedParam),
                method: 'post',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductionSites'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('POST_ProductionSites', e);
            return e
        }
    }
    
    async GET_ProductionSites(Offset = null, Limit = null, Filter = null, SortBy = null, Select = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(Offset, true, [])) {
                throw new OpenApiException("Parameter 'Offset' is not a valid number!");
            }
            
            if (!self.verifyParamIsInteger(Limit, true, [])) {
                throw new OpenApiException("Parameter 'Limit' is not a valid number!");
            }
            
            if (!self.verifyParamIsString(Filter, true, [])) {
                throw new OpenApiException("Parameter 'Filter' is not a valid string!");
            }
            
            if (!self.verifyParamIsString(SortBy, true, [])) {
                throw new OpenApiException("Parameter 'SortBy' is not a valid string!");
            }
            
            if (!self.verifyParamIsStringArray(Select, true, [])) {
                throw new OpenApiException("Parameter 'Select' is not a valid string array!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            if(Offset !== null) queryParams['Offset'] = Offset;
            if(Limit !== null) queryParams['Limit'] = Limit;
            if(Filter !== null) queryParams['Filter'] = Filter;
            if(SortBy !== null) queryParams['SortBy'] = SortBy;
            if(Select !== null) serializedParam['Select'] = OpenApiClientArrayHelper.GET_QUERY_serialized_array(Select, "STYLE_FORM", "Select", true);
            const config = {
                url: self.getUrl(['ProductionSites'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductionSites'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_ProductionSites', e);
            return e
        }
    }
    
    async GET_ProductionSites_audit(activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['ProductionSites', 'audit'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductionSites', 'audit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_ProductionSites_audit', e);
            return e
        }
    }
    
    async PATCH_ProductionSites_filteredAudit(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['ProductionSites', 'filteredAudit'], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductionSites', 'filteredAudit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_ProductionSites_filteredAudit', e);
            return e
        }
    }
    
    async PATCH_ProductionSites_partialFilteredAudit(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['ProductionSites', 'partialFilteredAudit'], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductionSites', 'partialFilteredAudit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_ProductionSites_partialFilteredAudit', e);
            return e
        }
    }
    
    async GET_ProductionSites_audit_auditId(auditId, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(auditId, false, [])) {
                throw new OpenApiException("Parameter 'auditId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['ProductionSites', 'audit', auditId], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'auditId': auditId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductionSites', 'audit', auditId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_ProductionSites_audit_auditId', e);
            return e
        }
    }
    
    async PUT_ProductionSites_restoreaudit_auditId(auditId, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(auditId, false, [])) {
                throw new OpenApiException("Parameter 'auditId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['ProductionSites', 'restoreaudit', auditId], serializedParam),
                method: 'put',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'auditId': auditId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductionSites', 'restoreaudit', auditId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_ProductionSites_restoreaudit_auditId', e);
            return e
        }
    }
    
    async GET_Products(Offset = null, Limit = null, Filter = null, SortBy = null, Select = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(Offset, true, [])) {
                throw new OpenApiException("Parameter 'Offset' is not a valid number!");
            }
            
            if (!self.verifyParamIsInteger(Limit, true, [])) {
                throw new OpenApiException("Parameter 'Limit' is not a valid number!");
            }
            
            if (!self.verifyParamIsString(Filter, true, [])) {
                throw new OpenApiException("Parameter 'Filter' is not a valid string!");
            }
            
            if (!self.verifyParamIsString(SortBy, true, [])) {
                throw new OpenApiException("Parameter 'SortBy' is not a valid string!");
            }
            
            if (!self.verifyParamIsStringArray(Select, true, [])) {
                throw new OpenApiException("Parameter 'Select' is not a valid string array!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            if(Offset !== null) queryParams['Offset'] = Offset;
            if(Limit !== null) queryParams['Limit'] = Limit;
            if(Filter !== null) queryParams['Filter'] = Filter;
            if(SortBy !== null) queryParams['SortBy'] = SortBy;
            if(Select !== null) serializedParam['Select'] = OpenApiClientArrayHelper.GET_QUERY_serialized_array(Select, "STYLE_FORM", "Select", true);
            const config = {
                url: self.getUrl(['Products'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Products'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Products', e);
            return e
        }
    }
    
    async POST_Products(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Products'], serializedParam),
                method: 'post',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Products'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('POST_Products', e);
            return e
        }
    }
    
    async GET_Products_id(id, commercial = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            if(commercial !== null) queryParams['commercial'] = commercial;
            const config = {
                url: self.getUrl(['Products', id], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Products', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Products_id', e);
            return e
        }
    }
    
    async PUT_Products_id(body, id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Products', id], serializedParam),
                method: 'put',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Products', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_Products_id', e);
            return e
        }
    }
    
    async DELETE_Products_id(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Products', id], serializedParam),
                method: 'delete',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Products', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('DELETE_Products_id', e);
            return e
        }
    }
    
    async GET_Products_audit(activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Products', 'audit'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Products', 'audit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Products_audit', e);
            return e
        }
    }
    
    async PATCH_Products_filteredAudit(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Products', 'filteredAudit'], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Products', 'filteredAudit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_Products_filteredAudit', e);
            return e
        }
    }
    
    async PATCH_Products_partialFilteredAudit(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Products', 'partialFilteredAudit'], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Products', 'partialFilteredAudit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_Products_partialFilteredAudit', e);
            return e
        }
    }
    
    async GET_Products_audit_auditId(auditId, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(auditId, false, [])) {
                throw new OpenApiException("Parameter 'auditId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Products', 'audit', auditId], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'auditId': auditId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Products', 'audit', auditId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Products_audit_auditId', e);
            return e
        }
    }
    
    async PUT_Products_restoreaudit_auditId(auditId, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(auditId, false, [])) {
                throw new OpenApiException("Parameter 'auditId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Products', 'restoreaudit', auditId], serializedParam),
                method: 'put',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'auditId': auditId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Products', 'restoreaudit', auditId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_Products_restoreaudit_auditId', e);
            return e
        }
    }
    
    async GET_AuthTokens(Offset = null, Limit = null, Filter = null, SortBy = null, Select = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(Offset, true, [])) {
                throw new OpenApiException("Parameter 'Offset' is not a valid number!");
            }
            
            if (!self.verifyParamIsInteger(Limit, true, [])) {
                throw new OpenApiException("Parameter 'Limit' is not a valid number!");
            }
            
            if (!self.verifyParamIsString(Filter, true, [])) {
                throw new OpenApiException("Parameter 'Filter' is not a valid string!");
            }
            
            if (!self.verifyParamIsString(SortBy, true, [])) {
                throw new OpenApiException("Parameter 'SortBy' is not a valid string!");
            }
            
            if (!self.verifyParamIsStringArray(Select, true, [])) {
                throw new OpenApiException("Parameter 'Select' is not a valid string array!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            if(Offset !== null) queryParams['Offset'] = Offset;
            if(Limit !== null) queryParams['Limit'] = Limit;
            if(Filter !== null) queryParams['Filter'] = Filter;
            if(SortBy !== null) queryParams['SortBy'] = SortBy;
            if(Select !== null) serializedParam['Select'] = OpenApiClientArrayHelper.GET_QUERY_serialized_array(Select, "STYLE_FORM", "Select", true);
            const config = {
                url: self.getUrl(['AuthTokens'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['AuthTokens'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_AuthTokens', e);
            return e
        }
    }
    
    async POST_AuthTokens(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['AuthTokens'], serializedParam),
                method: 'post',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['AuthTokens'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('POST_AuthTokens', e);
            return e
        }
    }
    
    async GET_AuthTokens_id(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['AuthTokens', id], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['AuthTokens', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_AuthTokens_id', e);
            return e
        }
    }
    
    async PUT_AuthTokens_id(body, id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['AuthTokens', id], serializedParam),
                method: 'put',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['AuthTokens', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_AuthTokens_id', e);
            return e
        }
    }
    
    async DELETE_AuthTokens_id(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['AuthTokens', id], serializedParam),
                method: 'delete',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['AuthTokens', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('DELETE_AuthTokens_id', e);
            return e
        }
    }
    
    async GET_AuthTokens_audit(activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['AuthTokens', 'audit'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['AuthTokens', 'audit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_AuthTokens_audit', e);
            return e
        }
    }
    
    async PATCH_AuthTokens_filteredAudit(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['AuthTokens', 'filteredAudit'], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['AuthTokens', 'filteredAudit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_AuthTokens_filteredAudit', e);
            return e
        }
    }
    
    async PATCH_AuthTokens_partialFilteredAudit(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['AuthTokens', 'partialFilteredAudit'], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['AuthTokens', 'partialFilteredAudit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_AuthTokens_partialFilteredAudit', e);
            return e
        }
    }
    
    async GET_AuthTokens_audit_auditId(auditId, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(auditId, false, [])) {
                throw new OpenApiException("Parameter 'auditId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['AuthTokens', 'audit', auditId], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'auditId': auditId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['AuthTokens', 'audit', auditId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_AuthTokens_audit_auditId', e);
            return e
        }
    }
    
    async PUT_AuthTokens_restoreaudit_auditId(auditId, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(auditId, false, [])) {
                throw new OpenApiException("Parameter 'auditId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['AuthTokens', 'restoreaudit', auditId], serializedParam),
                method: 'put',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'auditId': auditId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['AuthTokens', 'restoreaudit', auditId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_AuthTokens_restoreaudit_auditId', e);
            return e
        }
    }
    
    async GET_CustomerFeatures(Offset = null, Limit = null, Filter = null, SortBy = null, Select = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(Offset, true, [])) {
                throw new OpenApiException("Parameter 'Offset' is not a valid number!");
            }
            
            if (!self.verifyParamIsInteger(Limit, true, [])) {
                throw new OpenApiException("Parameter 'Limit' is not a valid number!");
            }
            
            if (!self.verifyParamIsString(Filter, true, [])) {
                throw new OpenApiException("Parameter 'Filter' is not a valid string!");
            }
            
            if (!self.verifyParamIsString(SortBy, true, [])) {
                throw new OpenApiException("Parameter 'SortBy' is not a valid string!");
            }
            
            if (!self.verifyParamIsStringArray(Select, true, [])) {
                throw new OpenApiException("Parameter 'Select' is not a valid string array!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            if(Offset !== null) queryParams['Offset'] = Offset;
            if(Limit !== null) queryParams['Limit'] = Limit;
            if(Filter !== null) queryParams['Filter'] = Filter;
            if(SortBy !== null) queryParams['SortBy'] = SortBy;
            if(Select !== null) serializedParam['Select'] = OpenApiClientArrayHelper.GET_QUERY_serialized_array(Select, "STYLE_FORM", "Select", true);
            const config = {
                url: self.getUrl(['CustomerFeatures'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerFeatures'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_CustomerFeatures', e);
            return e
        }
    }
    
    async POST_CustomerFeatures(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['CustomerFeatures'], serializedParam),
                method: 'post',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerFeatures'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('POST_CustomerFeatures', e);
            return e
        }
    }
    
    async GET_CustomerFeatures_id(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['CustomerFeatures', id], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerFeatures', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_CustomerFeatures_id', e);
            return e
        }
    }
    
    async PUT_CustomerFeatures_id(body, id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['CustomerFeatures', id], serializedParam),
                method: 'put',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerFeatures', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_CustomerFeatures_id', e);
            return e
        }
    }
    
    async DELETE_CustomerFeatures_id(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['CustomerFeatures', id], serializedParam),
                method: 'delete',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerFeatures', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('DELETE_CustomerFeatures_id', e);
            return e
        }
    }
    
    async PATCH_CustomerFeatures_id(body, id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['CustomerFeatures', id], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerFeatures', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_CustomerFeatures_id', e);
            return e
        }
    }
    
    async GET_CustomerFeatures_audit(activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['CustomerFeatures', 'audit'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerFeatures', 'audit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_CustomerFeatures_audit', e);
            return e
        }
    }
    
    async PATCH_CustomerFeatures_filteredAudit(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['CustomerFeatures', 'filteredAudit'], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerFeatures', 'filteredAudit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_CustomerFeatures_filteredAudit', e);
            return e
        }
    }
    
    async PATCH_CustomerFeatures_partialFilteredAudit(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['CustomerFeatures', 'partialFilteredAudit'], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerFeatures', 'partialFilteredAudit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_CustomerFeatures_partialFilteredAudit', e);
            return e
        }
    }
    
    async GET_CustomerFeatures_audit_auditId(auditId, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(auditId, false, [])) {
                throw new OpenApiException("Parameter 'auditId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['CustomerFeatures', 'audit', auditId], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'auditId': auditId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerFeatures', 'audit', auditId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_CustomerFeatures_audit_auditId', e);
            return e
        }
    }
    
    async PUT_CustomerFeatures_restoreaudit_auditId(auditId, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(auditId, false, [])) {
                throw new OpenApiException("Parameter 'auditId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['CustomerFeatures', 'restoreaudit', auditId], serializedParam),
                method: 'put',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'auditId': auditId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['CustomerFeatures', 'restoreaudit', auditId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_CustomerFeatures_restoreaudit_auditId', e);
            return e
        }
    }
    
    async GET_Customers(Offset = null, Limit = null, Filter = null, SortBy = null, Select = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(Offset, true, [])) {
                throw new OpenApiException("Parameter 'Offset' is not a valid number!");
            }
            
            if (!self.verifyParamIsInteger(Limit, true, [])) {
                throw new OpenApiException("Parameter 'Limit' is not a valid number!");
            }
            
            if (!self.verifyParamIsString(Filter, true, [])) {
                throw new OpenApiException("Parameter 'Filter' is not a valid string!");
            }
            
            if (!self.verifyParamIsString(SortBy, true, [])) {
                throw new OpenApiException("Parameter 'SortBy' is not a valid string!");
            }
            
            if (!self.verifyParamIsStringArray(Select, true, [])) {
                throw new OpenApiException("Parameter 'Select' is not a valid string array!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            if(Offset !== null) queryParams['Offset'] = Offset;
            if(Limit !== null) queryParams['Limit'] = Limit;
            if(Filter !== null) queryParams['Filter'] = Filter;
            if(SortBy !== null) queryParams['SortBy'] = SortBy;
            if(Select !== null) serializedParam['Select'] = OpenApiClientArrayHelper.GET_QUERY_serialized_array(Select, "STYLE_FORM", "Select", true);
            const config = {
                url: self.getUrl(['Customers'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Customers'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Customers', e);
            return e
        }
    }
    
    async POST_Customers(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Customers'], serializedParam),
                method: 'post',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Customers'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('POST_Customers', e);
            return e
        }
    }
    
    async GET_Customers_id(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Customers', id], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Customers', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Customers_id', e);
            return e
        }
    }
    
    async PUT_Customers_id(body, id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Customers', id], serializedParam),
                method: 'put',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Customers', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_Customers_id', e);
            return e
        }
    }
    
    async DELETE_Customers_id(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Customers', id], serializedParam),
                method: 'delete',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Customers', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('DELETE_Customers_id', e);
            return e
        }
    }
    
    async PATCH_Customers_id(body, id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Customers', id], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Customers', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_Customers_id', e);
            return e
        }
    }
    
    async GET_Customers_audit(activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Customers', 'audit'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Customers', 'audit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Customers_audit', e);
            return e
        }
    }
    
    async PATCH_Customers_filteredAudit(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Customers', 'filteredAudit'], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Customers', 'filteredAudit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_Customers_filteredAudit', e);
            return e
        }
    }
    
    async PATCH_Customers_partialFilteredAudit(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Customers', 'partialFilteredAudit'], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Customers', 'partialFilteredAudit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_Customers_partialFilteredAudit', e);
            return e
        }
    }
    
    async GET_Customers_audit_auditId(auditId, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(auditId, false, [])) {
                throw new OpenApiException("Parameter 'auditId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Customers', 'audit', auditId], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'auditId': auditId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Customers', 'audit', auditId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Customers_audit_auditId', e);
            return e
        }
    }
    
    async PUT_Customers_restoreaudit_auditId(auditId, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(auditId, false, [])) {
                throw new OpenApiException("Parameter 'auditId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Customers', 'restoreaudit', auditId], serializedParam),
                method: 'put',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'auditId': auditId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Customers', 'restoreaudit', auditId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_Customers_restoreaudit_auditId', e);
            return e
        }
    }
    
    async GET_Installations(Offset = null, Limit = null, Filter = null, SortBy = null, Select = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(Offset, true, [])) {
                throw new OpenApiException("Parameter 'Offset' is not a valid number!");
            }
            
            if (!self.verifyParamIsInteger(Limit, true, [])) {
                throw new OpenApiException("Parameter 'Limit' is not a valid number!");
            }
            
            if (!self.verifyParamIsString(Filter, true, [])) {
                throw new OpenApiException("Parameter 'Filter' is not a valid string!");
            }
            
            if (!self.verifyParamIsString(SortBy, true, [])) {
                throw new OpenApiException("Parameter 'SortBy' is not a valid string!");
            }
            
            if (!self.verifyParamIsStringArray(Select, true, [])) {
                throw new OpenApiException("Parameter 'Select' is not a valid string array!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            if(Offset !== null) queryParams['Offset'] = Offset;
            if(Limit !== null) queryParams['Limit'] = Limit;
            if(Filter !== null) queryParams['Filter'] = Filter;
            if(SortBy !== null) queryParams['SortBy'] = SortBy;
            if(Select !== null) serializedParam['Select'] = OpenApiClientArrayHelper.GET_QUERY_serialized_array(Select, "STYLE_FORM", "Select", true);
            const config = {
                url: self.getUrl(['Installations'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Installations'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Installations', e);
            return e
        }
    }
    
    async POST_Installations(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Installations'], serializedParam),
                method: 'post',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Installations'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('POST_Installations', e);
            return e
        }
    }
    
    async GET_Installations_id(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Installations', id], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Installations', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Installations_id', e);
            return e
        }
    }
    
    async PUT_Installations_id(body, id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Installations', id], serializedParam),
                method: 'put',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Installations', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_Installations_id', e);
            return e
        }
    }
    
    async DELETE_Installations_id(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Installations', id], serializedParam),
                method: 'delete',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Installations', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('DELETE_Installations_id', e);
            return e
        }
    }
    
    async GET_Installations_audit(activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Installations', 'audit'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Installations', 'audit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Installations_audit', e);
            return e
        }
    }
    
    async PATCH_Installations_filteredAudit(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Installations', 'filteredAudit'], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Installations', 'filteredAudit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_Installations_filteredAudit', e);
            return e
        }
    }
    
    async PATCH_Installations_partialFilteredAudit(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Installations', 'partialFilteredAudit'], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Installations', 'partialFilteredAudit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_Installations_partialFilteredAudit', e);
            return e
        }
    }
    
    async GET_Installations_audit_auditId(auditId, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(auditId, false, [])) {
                throw new OpenApiException("Parameter 'auditId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Installations', 'audit', auditId], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'auditId': auditId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Installations', 'audit', auditId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Installations_audit_auditId', e);
            return e
        }
    }
    
    async PUT_Installations_restoreaudit_auditId(auditId, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(auditId, false, [])) {
                throw new OpenApiException("Parameter 'auditId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Installations', 'restoreaudit', auditId], serializedParam),
                method: 'put',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'auditId': auditId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Installations', 'restoreaudit', auditId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_Installations_restoreaudit_auditId', e);
            return e
        }
    }
    
    async GET_Logins(Offset = null, Limit = null, Filter = null, SortBy = null, Select = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(Offset, true, [])) {
                throw new OpenApiException("Parameter 'Offset' is not a valid number!");
            }
            
            if (!self.verifyParamIsInteger(Limit, true, [])) {
                throw new OpenApiException("Parameter 'Limit' is not a valid number!");
            }
            
            if (!self.verifyParamIsString(Filter, true, [])) {
                throw new OpenApiException("Parameter 'Filter' is not a valid string!");
            }
            
            if (!self.verifyParamIsString(SortBy, true, [])) {
                throw new OpenApiException("Parameter 'SortBy' is not a valid string!");
            }
            
            if (!self.verifyParamIsStringArray(Select, true, [])) {
                throw new OpenApiException("Parameter 'Select' is not a valid string array!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            if(Offset !== null) queryParams['Offset'] = Offset;
            if(Limit !== null) queryParams['Limit'] = Limit;
            if(Filter !== null) queryParams['Filter'] = Filter;
            if(SortBy !== null) queryParams['SortBy'] = SortBy;
            if(Select !== null) serializedParam['Select'] = OpenApiClientArrayHelper.GET_QUERY_serialized_array(Select, "STYLE_FORM", "Select", true);
            const config = {
                url: self.getUrl(['Logins'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Logins'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Logins', e);
            return e
        }
    }
    
    async POST_Logins(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['Logins'], serializedParam),
                method: 'post',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Logins'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('POST_Logins', e);
            return e
        }
    }
    
    async GET_ProductVersions(Offset = null, Limit = null, Filter = null, SortBy = null, Select = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(Offset, true, [])) {
                throw new OpenApiException("Parameter 'Offset' is not a valid number!");
            }
            
            if (!self.verifyParamIsInteger(Limit, true, [])) {
                throw new OpenApiException("Parameter 'Limit' is not a valid number!");
            }
            
            if (!self.verifyParamIsString(Filter, true, [])) {
                throw new OpenApiException("Parameter 'Filter' is not a valid string!");
            }
            
            if (!self.verifyParamIsString(SortBy, true, [])) {
                throw new OpenApiException("Parameter 'SortBy' is not a valid string!");
            }
            
            if (!self.verifyParamIsStringArray(Select, true, [])) {
                throw new OpenApiException("Parameter 'Select' is not a valid string array!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            if(Offset !== null) queryParams['Offset'] = Offset;
            if(Limit !== null) queryParams['Limit'] = Limit;
            if(Filter !== null) queryParams['Filter'] = Filter;
            if(SortBy !== null) queryParams['SortBy'] = SortBy;
            if(Select !== null) serializedParam['Select'] = OpenApiClientArrayHelper.GET_QUERY_serialized_array(Select, "STYLE_FORM", "Select", true);
            const config = {
                url: self.getUrl(['ProductVersions'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductVersions'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_ProductVersions', e);
            return e
        }
    }
    
    async POST_ProductVersions(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['ProductVersions'], serializedParam),
                method: 'post',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductVersions'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('POST_ProductVersions', e);
            return e
        }
    }
    
    async GET_ProductVersions_id(id, commercial = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            if(commercial !== null) queryParams['commercial'] = commercial;
            const config = {
                url: self.getUrl(['ProductVersions', id], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductVersions', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_ProductVersions_id', e);
            return e
        }
    }
    
    async PUT_ProductVersions_id(body, id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['ProductVersions', id], serializedParam),
                method: 'put',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductVersions', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_ProductVersions_id', e);
            return e
        }
    }
    
    async DELETE_ProductVersions_id(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['ProductVersions', id], serializedParam),
                method: 'delete',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductVersions', id], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('DELETE_ProductVersions_id', e);
            return e
        }
    }
    
    async GET_ProductVersions_id_AssociatedProductionSites(id, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(id, false, [])) {
                throw new OpenApiException("Parameter 'id' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['ProductVersions', id, 'AssociatedProductionSites'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'id': id,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductVersions', id, 'AssociatedProductionSites'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_ProductVersions_id_AssociatedProductionSites', e);
            return e
        }
    }
    
    async GET_ProductVersions_audit(activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['ProductVersions', 'audit'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductVersions', 'audit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_ProductVersions_audit', e);
            return e
        }
    }
    
    async PATCH_ProductVersions_filteredAudit(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['ProductVersions', 'filteredAudit'], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductVersions', 'filteredAudit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_ProductVersions_filteredAudit', e);
            return e
        }
    }
    
    async PATCH_ProductVersions_partialFilteredAudit(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['ProductVersions', 'partialFilteredAudit'], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductVersions', 'partialFilteredAudit'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_ProductVersions_partialFilteredAudit', e);
            return e
        }
    }
    
    async GET_ProductVersions_audit_auditId(auditId, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(auditId, false, [])) {
                throw new OpenApiException("Parameter 'auditId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['ProductVersions', 'audit', auditId], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'auditId': auditId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductVersions', 'audit', auditId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_ProductVersions_audit_auditId', e);
            return e
        }
    }
    
    async PUT_ProductVersions_restoreaudit_auditId(auditId, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(auditId, false, [])) {
                throw new OpenApiException("Parameter 'auditId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['ProductVersions', 'restoreaudit', auditId], serializedParam),
                method: 'put',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'auditId': auditId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['ProductVersions', 'restoreaudit', auditId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_ProductVersions_restoreaudit_auditId', e);
            return e
        }
    }
    
    async GET_TrialPeriods(productionSiteId = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(productionSiteId, true, [])) {
                throw new OpenApiException("Parameter 'productionSiteId' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            if(productionSiteId !== null) queryParams['productionSiteId'] = productionSiteId;
            const config = {
                url: self.getUrl(['TrialPeriods'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['TrialPeriods'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_TrialPeriods', e);
            return e
        }
    }
    
    async POST_TrialPeriods(body, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            const config = {
                url: self.getUrl(['TrialPeriods'], serializedParam),
                method: 'post',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['TrialPeriods'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('POST_TrialPeriods', e);
            return e
        }
    }
    
    async PUT_TrialPeriods_productionSiteId(body, productionSiteId, machineSerialId = null, ValidTo = null, ValidFrom = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(productionSiteId, false, [])) {
                throw new OpenApiException("Parameter 'productionSiteId' is not a valid number!");
            }
            
            if (!self.verifyParamIsString(machineSerialId, true, [])) {
                throw new OpenApiException("Parameter 'machineSerialId' is not a valid string!");
            }
            
            if (!self.verifyParamIsString(ValidTo, true, [])) {
                throw new OpenApiException("Parameter 'ValidTo' is not a valid string!");
            }
            
            if (!self.verifyParamIsString(ValidFrom, true, [])) {
                throw new OpenApiException("Parameter 'ValidFrom' is not a valid string!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            if(machineSerialId !== null) queryParams['machineSerialId'] = machineSerialId;
            if(ValidTo !== null) queryParams['ValidTo'] = ValidTo;
            if(ValidFrom !== null) queryParams['ValidFrom'] = ValidFrom;
            const config = {
                url: self.getUrl(['TrialPeriods', productionSiteId], serializedParam),
                method: 'put',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'productionSiteId': productionSiteId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['TrialPeriods', productionSiteId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PUT_TrialPeriods_productionSiteId', e);
            return e
        }
    }
    
    async PATCH_TrialPeriods_productionSiteId(body, productionSiteId, machineSerialId = null, ValidFrom = null, ValidTo = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyBody(body, [MimeTypes.MIME_APPLICATION_JSON])) {
                throw new OpenApiException("Invalid MIME type");
            }
            if (!self.verifyParamIsInteger(productionSiteId, false, [])) {
                throw new OpenApiException("Parameter 'productionSiteId' is not a valid number!");
            }
            
            if (!self.verifyParamIsString(machineSerialId, true, [])) {
                throw new OpenApiException("Parameter 'machineSerialId' is not a valid string!");
            }
            
            if (!self.verifyParamIsString(ValidFrom, true, [])) {
                throw new OpenApiException("Parameter 'ValidFrom' is not a valid string!");
            }
            
            if (!self.verifyParamIsString(ValidTo, true, [])) {
                throw new OpenApiException("Parameter 'ValidTo' is not a valid string!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            headers['Content-Type'] = body.getMimeTypeAsString();
            if(machineSerialId !== null) queryParams['machineSerialId'] = machineSerialId;
            if(ValidFrom !== null) queryParams['ValidFrom'] = ValidFrom;
            if(ValidTo !== null) queryParams['ValidTo'] = ValidTo;
            const config = {
                url: self.getUrl(['TrialPeriods', productionSiteId], serializedParam),
                method: 'patch',
                data: body.getContent(),
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'productionSiteId': productionSiteId,
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['TrialPeriods', productionSiteId], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('PATCH_TrialPeriods_productionSiteId', e);
            return e
        }
    }
    
    async GET_Version(activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            const config = {
                url: self.getUrl(['Version'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Version'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Version', e);
            return e
        }
    }
    
    async GET_Statistics(recordsCount = null, activateDownloadManager = false, uniqueKey = null, isDownload = false, attachCookies = false) {
        
        var self = this;

        try {
            if (!self.verifyParamIsInteger(recordsCount, true, [])) {
                throw new OpenApiException("Parameter 'recordsCount' is not a valid number!");
            }
            
            var headers = {};
            var queryParams = {};
            var serializedParam = {};
            if(recordsCount !== null) queryParams['recordsCount'] = recordsCount;
            const config = {
                url: self.getUrl(['Statistics'], serializedParam),
                method: 'get',
                responseType: 'arraybuffer',
                headers: headers,
                params: queryParams,
                withCredentials: attachCookies,
                paramsSerializer: function(params) {
                    return Qs.stringify(params, {arrayFormat: 'brackets'})
                },
                onUploadProgress: function (progressEvent) {
                    // console.log(progressEvent,'in progress');
                },
                onDownloadProgress: function (progressEvent) {
                    // console.log(progressEvent, 'completed');
                }
            }
            if (isDownload != null && isDownload === true) {
                const downloadParamData = {
                    'headers': headers,
                    'queryParams': queryParams,
                }

                var url = self.getUrl(['Statistics'], queryParams);
                return (directDownloadConfig(downloadParamData, url))
            }
            else {
                return axiosInstance.request(config)
            }
        }
        catch (e) {
            self.logResponse('GET_Statistics', e);
            return e
        }
    }
    
}

export default OpenApiClient_customer_management_v2;