import React, { useState } from 'react';
import IconPlusActive from '../../assets/Icons/IconPlusActive.svg';
import IconPlus from '../../assets/Icons/IconPlus.svg';

interface IAddIconAccordion {
    isModalActive?: boolean;
    dataTestId?: string;
}

const AddIconAccordion = ({ isModalActive, dataTestId }: IAddIconAccordion) => {
    const [active, setActive] = useState(false);
    return (
        <img
            alt="plus-icon"
            data-testid={dataTestId}
            className={
                isModalActive || active
                    ? 'Anymodule_table_inner_add_icon_after'
                    : 'Anymodule_table_inner_add_icon_before'
            }
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            onMouseOver={() => setActive(true)}
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            onMouseOut={() => setActive(false)}
            src={isModalActive || active ? IconPlusActive : IconPlus}
        />
    );
};

AddIconAccordion.defaultProps = {
    dataTestId: '',
    isModalActive: false
};

export default AddIconAccordion;
