import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import OverviewScreen from '../../components/overviewScreen/OverviewScreen';
import { getMultiTermText, setAccordionOpenedId } from '../../utils/commonUtils';
import { hardlocksModalType } from './constants/HardlocksConstant';
import { IAccordionObject } from '../../@types/common';
import {headerData} from './constants/HeaderData';
import { accordionLevel1, hardlocks } from '../../utils/appConstants';
import { IHardlocks } from '../../@types/hardlocks';
import HardlocksAccordion from './components/hardlocksAccordion/HardlocksAccordion';
import ActionModal from '../../components/actionModal/ActionModal';
import HardlocksModal from './components/hardlocksModal/HardlocksModal';
import ChangeLocationModal from './components/hardlocksModal/ChangeLocationModal';
import { HardlocksTableOptions, HardlocksTableOptionsAdminRights} from './components/hardlockTableOption/HaradlocksTableOptions';
import {
    getHardlocks,
    setHardlocksModalType,
    setCurrentSelectedHardlock,
    postHardlocks,
    putHardlocks,
    setLocationChangeHardlock,
    postHardlockActionHistory,
    clearPostHardlocksResponseData,
    getLatestV2CDownload,
    clearV2cDownloadedData
} from './slice/index';
import './HardLocks.css';
import downloadFile,{ getLocationType, hardLockStatus } from './utils/HardlocksUtil';

const HardLocks = ({ globalContext, useAuth }: any) => {
    const dispatch = useAppDispatch();
    const [inRowPatchData, setInRowPatchData] = useState<any>({});
    const [hasAdminRights, setHasAdminRights] = useState<boolean>(false);
    const [selectedHardlockID, setSelectedHardlockID] = useState<number>();

    const {
        isLoading,
        hardlocksData,
        modalType,
        currentSelectedHardlock,
        addHardlocksModalData,
        putHardlocksModalData,
        selectedLocationId,
        selectedLocationName,
        selectedLocationType,
        locationChangeHardlockData,
        postHardlocksResponseData,
        latestV2cDownloadedData
    } = useAppSelector((state) => state.hardlocks);
    const authData = useAuth();
    const { setLoader } = globalContext;

    const onHandlePatchRowData = () => {
        const currentStatusId = hardlocksData.find((eachData) => eachData.id === inRowPatchData.id)?.currentStatus;
        if (currentStatusId !== inRowPatchData.status) {
            const postHardlockActionBody = {
                hardlockId: inRowPatchData.id,
                status: inRowPatchData.status,
                statusChange: true,
                remarks: `Status changed from ${
                    currentStatusId !== undefined ? hardLockStatus[+currentStatusId] : 'Undefined'
                } to ${hardLockStatus[inRowPatchData.status]}`
            };
            dispatch(postHardlockActionHistory(postHardlockActionBody));
        }
    };

    useEffect(() => {
        onHandlePatchRowData();
    }, [inRowPatchData]);

    useEffect(() => {
        dispatch(getHardlocks());
        setHasAdminRights(authData.isFeatureAuthorized(75) ?? false)
    }, []);

    useEffect(() => {
        setLoader({
            loading: isLoading
        });
    }, [isLoading]);

    useEffect(() => {
        if (latestV2cDownloadedData === ''){
            setSelectedHardlockID(undefined)
            return;
        }
        const xmlData = latestV2cDownloadedData;
        const fileName = `${selectedHardlockID}.v2c`;
        downloadFile(fileName,xmlData,'xml');
        dispatch(clearV2cDownloadedData());
    }, [latestV2cDownloadedData]);

    const onAddHardlocks = () => {
        dispatch(setHardlocksModalType(hardlocksModalType.ADD));
    };

    const onClickHardlocksModalAction = () => {
        switch (modalType) {
            case hardlocksModalType.ADD: {
                dispatch(postHardlocks(addHardlocksModalData));
                break;
            }
            case hardlocksModalType.SYNC: {
                dispatch(putHardlocks({ body: putHardlocksModalData, hardlockId: currentSelectedHardlock }));
                break;
            }
            case hardlocksModalType.EDIT: {
                const locationChangeBody = {
                    hardlockId: locationChangeHardlockData.id,
                    locationType: selectedLocationType,
                    location: selectedLocationId,
                    locationName: selectedLocationName,
                    statusChange: false,
                    remarks: `Location changed from ${getLocationType(locationChangeHardlockData.locationType)}: ${
                        locationChangeHardlockData.locationName ?? locationChangeHardlockData.location
                    } to ${getLocationType(selectedLocationType)}:  ${selectedLocationName}`,
                    isExpired: false
                };
                dispatch(postHardlockActionHistory(locationChangeBody));
                break;
            }
            default:
                break;
        }
    };

    const onCloseHardlocksModal = () => {
        dispatch(setHardlocksModalType(hardlocksModalType.CLOSED));
        dispatch(
            setLocationChangeHardlock({
                id: 0,
                location: '',
                locationType: 0
            })
        );
    };

    const getHardlocksTableData = (allHardlocksData: Array<IHardlocks>) => {
        const accordionOpenedData = sessionStorage.getItem('accordionOpened');
        let openedAccordion: IAccordionObject = {};
        if (accordionOpenedData) {
            openedAccordion = JSON.parse(accordionOpenedData);
        }
        return allHardlocksData.map((eachData) => {
            let isAccordionOpened = false;
            if (openedAccordion && openedAccordion[hardlocks] && openedAccordion[hardlocks][accordionLevel1]) {
                const openedData = openedAccordion[hardlocks][accordionLevel1].map((id) => id?.toString());
                if (openedData.includes(eachData.id.toString())) {
                    isAccordionOpened = true;
                }
            }

            return {
                _key: eachData.id,
                _accordionContent: <HardlocksAccordion hardlockId={eachData.id} />,
                _option: (
                    hasAdminRights ?
                    <HardlocksTableOptionsAdminRights
                        onLocationChangeClick={() => {
                            dispatch(
                                setLocationChangeHardlock({
                                    id: eachData.id,
                                    location: eachData.currentLocation,
                                    locationType: eachData.currentLocationType ?? 0,
                                    locationName: eachData.currentLocationName ?? ''
                                })
                            );
                            dispatch(setHardlocksModalType(hardlocksModalType.EDIT));
                        }}
                        onSyncHardlockClick={() => {
                            dispatch(setCurrentSelectedHardlock(eachData.id));
                            dispatch(setHardlocksModalType(hardlocksModalType.SYNC));
                        }}
                        onDownloadClick={() => {
                            setSelectedHardlockID(eachData.id)
                            dispatch(getLatestV2CDownload(eachData.id));
                        }}
                    />
                    :
                    <HardlocksTableOptions
                        onSyncHardlockClick={() => {
                            dispatch(setCurrentSelectedHardlock(eachData.id));
                            dispatch(setHardlocksModalType(hardlocksModalType.SYNC));
                        }}
                />
                ),
                id: eachData.id,
                lisecSerialNo: eachData.lisecSerialNo,
                typeName: eachData.typeName,
                status: eachData.currentStatus,
                location: eachData.currentLocationName ?? eachData.currentLocation,
                isAccordionOpen: isAccordionOpened
            };
        });
    };

    const onHandleAccordionToggle = (data: any) => {
        const accordionState = JSON.stringify(
            setAccordionOpenedId(
                accordionLevel1,
                data.id, // eslint-disable-line no-underscore-dangle
                hardlocks,
                data
            )
        );
        dispatch(clearPostHardlocksResponseData({}))
        sessionStorage.setItem('accordionOpened', accordionState);
    };

    useEffect(()=>{
        if (postHardlocksResponseData && postHardlocksResponseData.id )
        onHandleAccordionToggle(postHardlocksResponseData)
    },[postHardlocksResponseData])

    const isActionButtonDisabled = () => {
        let isDisabled = true;
        const addButtonActive =
            addHardlocksModalData.c2VFile &&
            addHardlocksModalData.lisecSerialNo &&
            addHardlocksModalData.lisecSerialNo !== 0 &&
            modalType === hardlocksModalType.ADD;
        const syncButtonActive = putHardlocksModalData.c2VFile && modalType === hardlocksModalType.SYNC;
        const editButtonActive =
            modalType === hardlocksModalType.EDIT &&
            (selectedLocationId !== locationChangeHardlockData?.location ||
                selectedLocationType !== locationChangeHardlockData?.locationType) &&
            selectedLocationType &&
            selectedLocationId;
        if (addButtonActive || syncButtonActive || editButtonActive) {
            isDisabled = false;
        }
        return isDisabled;
    };

    return (
        <>
            <OverviewScreen
                pageHeaderMultiTermId="99034161"
                pageHeaderMultiTermText="Hardlocks"
                tableClassName="Hardlocks_table"
                headerData={headerData(hasAdminRights)}
                rowsData={getHardlocksTableData(hardlocksData)}
                initialColumnSortData={[
                    {
                        order: 'ascending',
                        columnKey: 'id'
                    }
                ]}
                tableId="HardlocksTable"
                addButtonMultiTermId={hasAdminRights? "99034258" : ""}
                addButtonMultiTermText="Add Hardlock"
                addButtonOnClick={onAddHardlocks}
                onAccordionToggle={onHandleAccordionToggle}
                onScrollToId={postHardlocksResponseData?.id}
                onPatchRowData={(rowData) =>
                     setInRowPatchData(rowData)
                }
            />

            {modalType !== hardlocksModalType.CLOSED && (
                <ActionModal
                    onClickAction={onClickHardlocksModalAction}
                    onClickCancelClose={onCloseHardlocksModal}
                    actionType={modalType}
                    modalColumnNumber={1}
                    ModalContent={modalType === hardlocksModalType.EDIT ? <ChangeLocationModal /> : <HardlocksModal />}
                    actionDisabled={isActionButtonDisabled()}
                    customActionButtonArr={
                        modalType === hardlocksModalType.SYNC
                            ? [
                                  {
                                      text: getMultiTermText({ id: '99032460', text: 'Synchronise now' }),
                                      className: 'Primary_button',
                                      onClick: onClickHardlocksModalAction,
                                      disabled: isActionButtonDisabled()
                                  }
                              ]
                            : []
                    }
                    popupClassName={modalType === hardlocksModalType.EDIT ? 'Change_location_popup' : ' '}
                />
            )}
        </>
    );
};

export default HardLocks;
