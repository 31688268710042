/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import {
    FlexboxContainer,
    FlexboxItem,
    Typography,
    TextField,
    ToggleSwitch,
    MultiList,
    Scrollable
} from 'ui-components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { flattenMultiList, getChildDependentData, getDataForDependentFeatures } from '../../../../utils/commonUtils';
import { getDependentFeatures, getLatestFeatureId, setFeatureModalData } from '../../slice/Feature';
import modalType from '../../../../constants/appConstants';

import './FeatureModal.css';
import { IMultiListArrayType } from '../../../../@types/customers';

const FeatureModal = () => {
    const { featureModalData, latestFeatureId, dependentFeaturesData, featureModalType } = useAppSelector(
        (state) => state.features
    );

    const [childDependentIds, setChildDependentIds] = useState<Array<number>>([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getDependentFeatures());
        dispatch(getLatestFeatureId());
    }, []);

    useEffect(() => {
        if (featureModalType === modalType.ADD) {
            const tempModalData = { ...featureModalData };
            tempModalData.id = latestFeatureId ? latestFeatureId + 1 : 1;
            dispatch(setFeatureModalData(tempModalData));
        }
    }, [latestFeatureId]);

    useEffect(() => {
        if (dependentFeaturesData.length > 0) {
            setChildDependentIds(getChildDependentData(dependentFeaturesData, featureModalData?.id));
        }
    }, [dependentFeaturesData]);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempModalData = { ...featureModalData };
        if (event.target.name === 'multitermId' || event.target.name === 'id') {
            tempModalData = {
                ...tempModalData,
                [event.target.name]: event.target.value.replace(/\D/g, '')
            };
            dispatch(setFeatureModalData(tempModalData));
        } else {
            tempModalData = {
                ...tempModalData,
                [event.target.name]: event.target.value
            };
            dispatch(setFeatureModalData(tempModalData));
        }
    };

    const handleOnToggleChange = (name: string, value: boolean) => {
        let tempModalData = { ...featureModalData };
        tempModalData = {
            ...tempModalData,
            [name]: value
        };
        dispatch(setFeatureModalData(tempModalData));
    };

    const onHandleMultiListChange = (data: Array<IMultiListArrayType>) => {
        let tempModalData = { ...featureModalData };
        tempModalData = {
            ...tempModalData,
            dependentOn: flattenMultiList(data)
                .filter((eachData) => eachData.checked)
                .map((filteredData) => filteredData.id)
        };
        dispatch(setFeatureModalData(tempModalData));
    };    

    return (
        <FlexboxContainer
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="0.5rem"
            className="Full_height"
        >
            <FlexboxItem>
                <Typography type="H1">
                    {featureModalType === modalType.ADD ? (
                        <LiMultiterm textId="99032434" textVal="Add feature" />
                    ) : (
                        <LiMultiterm textId="99032436" textVal="Edit feature" />
                    )}
                </Typography>
            </FlexboxItem>
            <FlexboxItem>
                <FlexboxContainer alignItems="start" justifyContent="start">
                    <FlexboxItem className="Modal_each_side_wrapper">
                        <FlexboxContainer flexDirection="column" gap="1.25rem" alignItems="start">
                            <FlexboxItem className="Full_width">
                                <TextField
                                    className="Id_input"
                                    title={<LiMultiterm textId="99008287" textVal="ID#" />}
                                    isRequired
                                    disabled={featureModalType === modalType.EDIT}
                                    onChange={handleOnChange}
                                    name="id"
                                    errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                                    value={featureModalData?.id}
                                />
                            </FlexboxItem>
                            <FlexboxItem className="Full_width">
                                <TextField
                                    className="Sap_id_input"
                                    title={<LiMultiterm textId="99031088" textVal="SAP ID" />}
                                    onChange={handleOnChange}
                                    name="sapId"
                                    value={featureModalData?.sapId}
                                />
                            </FlexboxItem>
                            <FlexboxItem className="Full_width">
                                <TextField
                                    className="Name_input"
                                    title={<LiMultiterm textId="6001104" textVal="Name" />}
                                    onChange={handleOnChange}
                                    name="description"
                                    value={featureModalData?.description}
                                    errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                                />
                            </FlexboxItem>
                            <FlexboxItem className="Full_width">
                                <TextField
                                    title={
                                        <>
                                            <LiMultiterm textId="99032757" textVal="Text number for name" />#
                                        </>
                                    }
                                    className="Multiterm_input"
                                    onChange={handleOnChange}
                                    name="multitermId"
                                    value={featureModalData?.multitermId}
                                />
                            </FlexboxItem>
                            <FlexboxItem className="Full_width">
                                <TextField
                                    className="Short_name_input"
                                    title={<LiMultiterm textId="99002657" textVal="Short name" />}
                                    isRequired
                                    onChange={handleOnChange}
                                    name="name"
                                    value={featureModalData?.name}
                                    errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                                />
                            </FlexboxItem>
                            <FlexboxItem className="Full_width">
                                <ToggleSwitch
                                    checked={featureModalData?.commercial}
                                    value={featureModalData?.commercial}
                                    className="Toggle_switch Commercial_toggle_switch"
                                    onChange={(data: boolean) => handleOnToggleChange('commercial', data)}
                                    switchPrefix={<LiMultiterm textId="99032432" textVal="Commercial feature" />}
                                />
                            </FlexboxItem>
                            <FlexboxItem className="Full_width">
                                <ToggleSwitch
                                    checked={featureModalData?.enabled}
                                    value={featureModalData?.enabled}
                                    className="Toggle_switch Enabled_toggle_switch"
                                    onChange={(data: boolean) => handleOnToggleChange('enabled', data)}
                                    switchPrefix={<LiMultiterm textId="99000784" textVal="Enabled" />}
                                />
                            </FlexboxItem>
                        </FlexboxContainer>
                    </FlexboxItem>
                    <FlexboxItem className="Card_separator" />
                    <FlexboxItem className="Modal_each_side_wrapper">
                        <Scrollable>
                            {dependentFeaturesData?.length ? (
                                <MultiList
                                    onChange={(data: Array<IMultiListArrayType>) => onHandleMultiListChange(data)}
                                    title={<LiMultiterm textId="99032441" textVal="Assigned features" />}
                                    showToggleForSelectedOnly
                                    data={getDataForDependentFeatures(
                                        dependentFeaturesData,
                                        featureModalData?.dependentOn || [],
                                        featureModalData.id,
                                        childDependentIds
                                    )}
                                />
                            ) : null}
                        </Scrollable>
                    </FlexboxItem>
                </FlexboxContainer>
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default FeatureModal;
