import { getMultiTermText } from '../../../utils/commonUtils';

export default [
    {
        _key: 'name',
        label: getMultiTermText({ id: '99018122', text: 'Name' }),
        searching: true,
        sorting: true,
        type: 'string',
        width: '33.3%',
        isEditable: false,
    },
    {
        _key: 'audience',
        label: getMultiTermText({ id: '99030967', text: 'Audience' }),
        searching: true,
        sorting: true,
        type: 'string',
        width: '33.3%',
        isEditable: false,
    },
    {
        _key: 'subject',
        label: getMultiTermText({ id: '99032201', text: 'Subject' }),
        searching: true,
        sorting: true,
        type: 'string',
        width: '33.3%',
        isEditable: false,
    }
];
