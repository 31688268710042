/* eslint-disable camelcase */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { convertToJSON } from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiBody, MimeTypes } from 'lisecutilityfunctions/lib/openapi';
import { IErrorState, setError } from '../../../slice/ErrorHandling';
import OpenApiClient_customer_management_v2 from '../../../api/openapiclient_customer_management_v2';
import {
    IErrorMessage,
    ITrialPeriod,
    ITrialPeriodsModalData,
    ITrialPeriodsPayloadData,
    ITrialPeriodsPutData
} from '../../../@types/trialPeriods';
import { getMultiTermText, modalType } from '../../../utils/commonUtils';

export interface ITrialPeriodsInitialState {
    isLoading: boolean;
    trialPeriodsData: Array<ITrialPeriod>;
    trialPeriodModalType: string;
    trialPeriodsModalData: ITrialPeriodsModalData;
    selectedTrialPeriodsData: ITrialPeriodsModalData;
    isInvalid: boolean;
    errorMessages: IErrorMessage;
}

const initialState: ITrialPeriodsInitialState = {
    isLoading: false,
    trialPeriodsData: [],
    trialPeriodModalType: modalType.CLOSED,
    trialPeriodsModalData: {},
    selectedTrialPeriodsData: {},
    isInvalid: true,
    errorMessages: {}
};

/**
 * @function getTrialPeriods
 * @description Function to fetch all trial periods
 */
export const getTrialPeriods = createAsyncThunk(
    'getTrialPeriods',
    async (productionSiteId: number | null, { dispatch }) => {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_TrialPeriods(productionSiteId);
        if (response?.status === 204) {
            return [];
        }
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = convertToJSON(response.data);
        return responseData;
    }
);

/**
 * @function postTrialPeriod
 * @description Function to do post trial period
 */
export const postTrialPeriod = createAsyncThunk(
    'postTrialPeriod',
    async (postTrialPeriodBody: ITrialPeriodsPayloadData, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).POST_TrialPeriods(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, postTrialPeriodBody)
            );
            if (response?.status !== 201) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            return dispatch(getTrialPeriods(null));
        } catch (error: any) {
            const message = convertToJSON(error.response?.data ?? '');
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: message.detail
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function patchTrialPeriod
 * @description Function to do patch trial period
 */
export const patchTrialPeriod = createAsyncThunk(
    'putTrialPeriod',
    async (putTrialPeriodBody: ITrialPeriodsPutData, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(
                null
            ).PATCH_TrialPeriods_productionSiteId(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, putTrialPeriodBody),
                putTrialPeriodBody.productionSiteId,
                putTrialPeriodBody.machineSerial,
                putTrialPeriodBody.existingValidFrom,
                putTrialPeriodBody.existingValidTo
            );
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            dispatch(removeErrorMessages(['validFrom', 'validTo']));  // eslint-disable-line no-use-before-define
            return dispatch(getTrialPeriods(null));
        } catch (error: any) {
            const message = convertToJSON(error.response?.data ?? '');
            const validationMessage = getMultiTermText({
                id: '99034159',
                text: '"Available to" date cannot be before "Available from" date'
            });
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: message.detail === 'From date should not be after to date' ? validationMessage : message.detail
            };
            dispatch(appendErrorMessages({ validTo: validationMessage })); // eslint-disable-line no-use-before-define
            return dispatch(setError(errorValue));
        }
    }
);

const trialPeriodsSlice = createSlice({
    name: 'trialPeriods',
    initialState,
    reducers: {
        setTrialPeriodsData: (state, action: PayloadAction<Array<ITrialPeriod>>) => {
            state.trialPeriodsData = action.payload;
        },
        setTrialPeriodsModalType: (state, action: PayloadAction<string>) => {
            state.trialPeriodModalType = action.payload;
            if (action.payload === modalType.CLOSED) {
                state.trialPeriodsModalData = initialState.trialPeriodsModalData;
            }
        },
        setTrialPeriodsModalData: (state, action: PayloadAction<ITrialPeriodsModalData>) => {
            state.trialPeriodsModalData = action.payload;
        },
        setSelectedTrialPeriodsData: (state, action: PayloadAction<ITrialPeriodsModalData>) => {
            state.selectedTrialPeriodsData = action.payload;
        },
        setIsInvalid: (state, action: PayloadAction<boolean>) => {
            state.isInvalid = action.payload;
        },
        appendErrorMessages: (state, action: PayloadAction<IErrorMessage>) => {
            state.errorMessages = { ...state.errorMessages, ...action.payload };
        },
        removeErrorMessages: (state, action: PayloadAction<Array<string>>) => {
            action.payload.forEach((key) => {
                const { [key]: value, ...rest } = state.errorMessages;
                state.errorMessages = rest;
            });
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTrialPeriods.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getTrialPeriods.fulfilled, (state, action: PayloadAction<Array<ITrialPeriod>>) => {
            state.isLoading = false;
            state.trialPeriodsData = action.payload;
        });
        builder.addCase(getTrialPeriods.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(postTrialPeriod.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(postTrialPeriod.fulfilled, (state) => {
            state.isLoading = false;
            state.trialPeriodModalType = modalType.CLOSED;
            state.trialPeriodsModalData = initialState.trialPeriodsModalData;
        });
        builder.addCase(postTrialPeriod.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(patchTrialPeriod.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(patchTrialPeriod.fulfilled, (state) => {
            state.isLoading = false;
            state.trialPeriodModalType = modalType.CLOSED;
            state.trialPeriodsModalData = initialState.trialPeriodsModalData;
        });
        builder.addCase(patchTrialPeriod.rejected, (state) => {
            state.isLoading = false;
        });
    }
});

export const {
    setTrialPeriodsData,
    setTrialPeriodsModalType,
    setTrialPeriodsModalData,
    setIsInvalid,
    appendErrorMessages,
    removeErrorMessages,
    setSelectedTrialPeriodsData
} = trialPeriodsSlice.actions;
export default trialPeriodsSlice.reducer;
