/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo } from 'react';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import { FlexboxContainer, FlexboxItem, Typography, Scrollable } from 'ui-components';
import IconFeatures from '../../../../assets/Icons/IconFeatures.svg';
import IconHardlock from '../../../../assets/Icons/IconHardlock.svg';

import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setNonEscalationEmails, setApiTokenModalType, getCustomerProducts } from '../../slice/index';
import ProductListTree from '../productListTree/ProductListTree';
import MultiLevelTree from '../multiLevelTree/MultiLevelTree';
import {
    extractEmails,
    getDataForDependentFeatures,
    modalType,
    getDataForDependentProducts
} from '../../../../utils/commonUtils';

import APIToken from '../apiToken/APIToken';
import HardlockProductionSites from '../hardlockProductionSites/HardlockProductionSites';
import AddIconAccordion from '../../../../components/addIconAccordion/AddIconAccordion';
import './ProductionSitesAccordion.css';

interface ICustomerAccordion {
    productSitesId: number;
}

const ProductionSitesAccordion = ({ productSitesId }: ICustomerAccordion) => {
    const dispatch = useAppDispatch();
    const {
        allProducts,
        dependentFeaturesData,
        nonEscalationEmails,
        customerProductionSites,
        apiTokenModalType,
        allCustomerProducts
    } = useAppSelector((state) => state.customers);

    const getEscallationEmails = () => {
        const escalationEmails = extractEmails(
            customerProductionSites?.[productSitesId]?.productionSites.licenseSettings
        );
        dispatch(setNonEscalationEmails(escalationEmails));
    };

    const onAddAPItoken = () => {
        const apiTokenModalStatus = {
            modalStatus: modalType.ADD_TOKEN,
            type: ''
        };
        dispatch(setApiTokenModalType(apiTokenModalStatus));
    };

    useEffect(() => {
        if (customerProductionSites?.[productSitesId]?.productionSites.licenseSettings) {
            getEscallationEmails();
        }
    }, [customerProductionSites?.[productSitesId]?.productionSites.licenseSettings]);

    const memoizedDependentFeatures = useMemo(
        () =>
            getDataForDependentFeatures(
                dependentFeaturesData,
                customerProductionSites?.[productSitesId]?.selectedFeatureIds || [],
                customerProductionSites?.[productSitesId]?.productionSites?.id,
                []
            ),
        [dependentFeaturesData, customerProductionSites, productSitesId]
    );

    const memoizedDependentProducts = useMemo(
        () =>
            getDataForDependentProducts(
                allProducts,
                customerProductionSites?.[productSitesId]?.selectedProducts || [],
                customerProductionSites?.[productSitesId]?.productionSites?.id,
                []
            ),
        [allProducts, customerProductionSites, productSitesId]
    );
    return (
        <FlexboxContainer
            flexDirection="column"
            gap="1rem"
            className="Accordion_wrapper Full_width"
            justifyContent="start"
            alignItems="stretch"
        >
            <FlexboxItem className="Full_width">
                <FlexboxItem>
                    <FlexboxContainer flexDirection="row" alignItems="start" justifyContent="space-between">
                        <FlexboxItem flexDirection="column" className="Modal_each_side_wrapper">
                            <FlexboxContainer flexDirection="column" gap="1.25rem" alignItems="start">
                                <FlexboxItem>
                                    <FlexboxContainer flexDirection="row" gap="0.499rem">
                                        <span className="Inner_table_heading">
                                            <LiMultiterm
                                                textId="99033469"
                                                textVal="Synchronisation error escalation settings"
                                            />
                                        </span>
                                    </FlexboxContainer>
                                </FlexboxItem>

                                <FlexboxItem className="Full_width">
                                    <FlexboxContainer flexDirection="row" justifyContent="space-between">
                                        <FlexboxItem className="TextField_container">
                                            <Typography type="ST6">
                                                <LiMultiterm textId="99033472" textVal="Send e-mail notifications" />
                                            </Typography>
                                        </FlexboxItem>

                                        <FlexboxContainer className="TextField_container" flexDirection="row">
                                            <Typography type="B">
                                                {customerProductionSites?.[productSitesId]?.productionSites
                                                    .licenseSettings
                                                    ? customerProductionSites?.[productSitesId]?.productionSites
                                                          .licenseSettings?.noConnEscalation1Time
                                                    : ' - '}
                                            </Typography>
                                            &nbsp;
                                            <Typography type="B">
                                                <LiMultiterm textId="99033471" textVal="days after" />
                                            </Typography>
                                        </FlexboxContainer>
                                    </FlexboxContainer>
                                </FlexboxItem>

                                <FlexboxItem className="Full_width">
                                    <Typography type="L2">
                                        {customerProductionSites?.[
                                            productSitesId
                                        ]?.productionSites?.escalationEmail?.map((emails: any) => (
                                            <FlexboxItem key={emails.id} className="EmailList_container">
                                                {emails.email}
                                            </FlexboxItem>
                                        ))}
                                    </Typography>
                                </FlexboxItem>

                                <FlexboxItem className="Full_width">
                                    <FlexboxContainer flexDirection="row" justifyContent="space-between">
                                        <FlexboxItem className="Half_width">
                                            <Typography type="ST6">
                                                <LiMultiterm
                                                    textId="99033470"
                                                    textVal="Deactivate on-premises license delivery"
                                                />
                                            </Typography>
                                        </FlexboxItem>
                                        <FlexboxContainer className="TextField_container" flexDirection="row">
                                            <Typography type="B">
                                                {customerProductionSites?.[productSitesId]?.productionSites
                                                    .licenseSettings
                                                    ? customerProductionSites?.[productSitesId]?.productionSites
                                                          .licenseSettings?.noConnEscalation2Time
                                                    : ' - '}
                                            </Typography>
                                            &nbsp;
                                            <Typography type="B">
                                                <LiMultiterm textId="99033471" textVal="days after" />
                                            </Typography>
                                        </FlexboxContainer>
                                    </FlexboxContainer>
                                </FlexboxItem>

                                <FlexboxItem className="Full_width">
                                    <FlexboxContainer flexDirection="row" justifyContent="space-between">
                                        <Typography type="ST6">
                                            <LiMultiterm textId="99034102" textVal="Send reminder emails every" />
                                        </Typography>
                                        <FlexboxContainer className="TextField_container" flexDirection="row">
                                            <Typography type="B">
                                                {customerProductionSites?.[productSitesId]?.productionSites
                                                    .licenseSettings
                                                    ? customerProductionSites?.[productSitesId]?.productionSites
                                                          .licenseSettings?.noConnEscalationFrequency
                                                    : ' - '}
                                            </Typography>
                                            &nbsp;
                                            <Typography type="B">
                                                <LiMultiterm textId="99033471" textVal="days after" />
                                            </Typography>
                                        </FlexboxContainer>
                                    </FlexboxContainer>
                                </FlexboxItem>
                            </FlexboxContainer>
                        </FlexboxItem>

                        <FlexboxItem className="Card_separator" />
                        <FlexboxItem className="Each_side_wrapper_For_dependent_Features">
                            <FlexboxContainer
                                flexDirection="row"
                                justifyContent="start"
                                alignContent="center"
                                className="SubHeader_Image Full_width"
                            >
                                <img
                                    alt="features-icon"
                                    src={IconFeatures}
                                    style={{ height: '30px', width: '30px', paddingRight: '5px' }}
                                />
                                <Typography type="B">
                                    <LiMultiterm textId="99034264" textVal="Assigned products" />
                                </Typography>
                            </FlexboxContainer>
                            <div>
                                <ProductListTree data={memoizedDependentProducts} />
                            </div>
                        </FlexboxItem>
                        <FlexboxItem className="Card_separator" />

                        <FlexboxItem className="Each_side_wrapper_For_dependent_Features">
                            <FlexboxContainer
                                flexDirection="row"
                                justifyContent="start"
                                alignContent="start"
                                className="SubHeader_Image Full_width"
                            >
                                <img
                                    alt="features-icon"
                                    src={IconFeatures}
                                    style={{ height: '30px', width: '30px', paddingRight: '5px' }}
                                />
                                <Typography type="B">
                                    <LiMultiterm textId="99032441" textVal="Assigned features" />
                                </Typography>
                            </FlexboxContainer>

                            <div>
                                <Scrollable>
                                    {dependentFeaturesData?.length ? (
                                        <MultiLevelTree data={memoizedDependentFeatures} />
                                    ) : null}
                                </Scrollable>
                            </div>
                        </FlexboxItem>
                    </FlexboxContainer>
                    <FlexboxItem className="Component_Spacer">
                        <FlexboxContainer flexDirection="row" justifyContent="space-between">
                            <FlexboxItem>
                                <FlexboxContainer flexDirection="row" gap="0.313rem">
                                    <FlexboxItem>
                                        <img
                                            src={IconFeatures}
                                            alt="icon-interface"
                                            className="Product_release_heading_icon"
                                            style={{ height: '30px', width: '30px' }}
                                        />
                                    </FlexboxItem>
                                    <Typography type="B">
                                        <LiMultiterm textId="99030966" textVal="API tokens" />
                                    </Typography>
                                </FlexboxContainer>
                            </FlexboxItem>
                            <FlexboxItem onClick={onAddAPItoken}>
                                <AddIconAccordion
                                    isModalActive={apiTokenModalType === modalType.ADD_TOKEN}
                                    dataTestId="Product_release_add_icon"
                                />
                            </FlexboxItem>
                        </FlexboxContainer>
                        <APIToken productSitesId={productSitesId} />
                    </FlexboxItem>

                    <FlexboxItem className="Component_Spacer">
                        <FlexboxContainer
                            flexDirection="row"
                            gap="0.313rem"
                            justifyContent="start"
                            alignContent="start"
                        >
                            <FlexboxItem>
                                <img
                                    src={IconHardlock}
                                    alt="icon-interface"
                                    className="Product_release_heading_icon"
                                    style={{ height: '30px', width: '30px' }}
                                />
                            </FlexboxItem>
                            <Typography type="B">
                                <LiMultiterm textId="99034161" textVal="Hardlocks" />
                            </Typography>
                        </FlexboxContainer>
                        <HardlockProductionSites productSitesId={productSitesId} />
                    </FlexboxItem>
                </FlexboxItem>
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default ProductionSitesAccordion;
