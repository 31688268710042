/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import { FlexboxContainer, FlexboxItem, Typography } from 'ui-components';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import IconFeatures from '../../../../assets/Icons/IconFeatures.svg';
import { getDataForDependentFeatures } from '../../../../utils/commonUtils';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import AssignedFeatureList from '../AssignedFeatureList/AssignedFeatureList';
import { getDependentFeatures, getProductVersionDetailId, setDependentFeaturesData } from '../../slice';

interface IProductVersionAccordion {
    versionId: number;
}

const ProductVersionAccordion = ({ versionId }: IProductVersionAccordion) => {
    const { dependentFeaturesData, openedProductVersionIds } = useAppSelector((state) => state.products);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getDependentFeatures());
        dispatch(setDependentFeaturesData([]));
        dispatch(getProductVersionDetailId(versionId));
    }, []);

    return (
        <FlexboxContainer
            flexDirection="column"
            gap="1rem"
            className="Accordion_inside_wrapper Full_width"
            justifyContent="start"
            alignItems="stretch"
        >
            <FlexboxItem className="Full_width Accordion_wrapper">
                <FlexboxItem>
                    <FlexboxContainer flexDirection="row" alignItems="start" justifyContent="space-between">
                        <FlexboxItem className="Modal_each_side_wrapper">
                            <FlexboxContainer flexDirection="column" gap="1.25rem" alignItems="start">
                                <FlexboxItem className="Modal_each_side_wrapper">
                                    <FlexboxContainer
                                        flexDirection="row"
                                        justifyContent="start"
                                        alignContent="center"
                                        className="SubHeader_Image Full_width"
                                    >
                                        <img
                                            alt="features-icon"
                                            src={IconFeatures}
                                            style={{ height: '30px', width: '30px', paddingRight: '5px' }}
                                        />
                                        <Typography type="B">
                                            <LiMultiterm textId="99032441" textVal="Assigned features" />
                                        </Typography>
                                    </FlexboxContainer>
                                    <FlexboxContainer flexDirection="column" alignItems="start">
                                        {openedProductVersionIds?.[versionId] &&
                                            openedProductVersionIds?.[versionId].length && (
                                                <AssignedFeatureList
                                                    data={getDataForDependentFeatures(
                                                        dependentFeaturesData,
                                                        openedProductVersionIds[versionId],
                                                        0,
                                                        []
                                                    )}
                                                />
                                            )}
                                    </FlexboxContainer>
                                </FlexboxItem>
                            </FlexboxContainer>
                        </FlexboxItem>
                    </FlexboxContainer>
                </FlexboxItem>
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default ProductVersionAccordion;
