import React from 'react';
import { SubOptionListComponent } from 'ui-components';
import { getMultiTermText } from '../../../../utils/commonUtils';

interface ITableOptions {
    onClickEdit: () => void;
}

const HardLockTypeTableOptions = ({ onClickEdit }: ITableOptions) => {
    const optionList = {
        list: [
            {
                title: getMultiTermText({ text: 'Edit hardlock type', id: '99034175' }),
                click: onClickEdit,
                key: 'edit'
            }
        ]
    };
    return <SubOptionListComponent optionList={optionList} />;
};

export default HardLockTypeTableOptions;
