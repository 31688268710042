// eslint-disable-next-line import/no-extraneous-dependencies
import { configureStore, combineReducers } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import ErrorHandlingSlice from './slice/ErrorHandling';
import BreadCrumbsSlice from './slice/BreadCrumbs';
import FeaturesSlice from './pages/Features/slice/Feature';
import AssociatedProductsSlice from './pages/Features/slice/AssociatedProducts';
import AssociatedProductionSites from './pages/Products/slice/AssociatedProducts';
import CustomersSlice from './pages/Customers/slice';
import ProductsSlice from './pages/Products/slice';
import loginsHistorySlice from './pages/LoginHistory/slice/LoginSlice';
import TrialPeriodsSlice from './pages/TrialPeriods/slice';
import HardLocksSlice from './pages/HardLocks/slice';
import HardLockTypeSlice from './pages/HardLockTypes/slice';

const rootReducer = combineReducers({
    errorSlice: ErrorHandlingSlice,
    breadCrumbsSlice: BreadCrumbsSlice,
    features: FeaturesSlice,
    associatedProducts: AssociatedProductsSlice,
    associatedProductionSites: AssociatedProductionSites,
    customers: CustomersSlice,
    products: ProductsSlice,
    loginHistory: loginsHistorySlice,
    trialPeriods: TrialPeriodsSlice,
    hardlocks: HardLocksSlice,
    hardLockTypes: HardLockTypeSlice
});

// FYI: Passing preloaded state for unit testing
const store: any = (preloadedState?: any) =>
    configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false
            })
    });

export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
