import React from 'react';
// eslint-disable-next-line import/extensions
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import App from './App';
import store from './store';
import ErrorHandler from './components/errorHandler/ErrorHandler';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store()}>
        <ErrorHandler />
        <App />
    </Provider>
);
