/* eslint-disable import/no-extraneous-dependencies */
import React, { useState,useEffect } from 'react';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import {
    FlexboxContainer,
    FlexboxItem,
    Typography,
    MultiList,
    Scrollable,
    TextLabel
} from 'ui-components';

import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { flattenMultiList, getDataForDependentFeatures,modalType, transformProductVersionObj } from '../../../../utils/commonUtils';
import {
    setPostProductVersionData,
    setDependentFeaturesData,
    setCurrentProductVersionEnableStatus,
} from '../../slice/index';
import {IProductVersionData} from '../../../../@types/products'
import { IMultiListArrayType } from '../../../../@types/customers';
import './ProductVersionModal.css';

type IProductFeatures = {
    id: number;
    productVersionId: number;
    featureId: number;
    sequence: number;
    modified: string;
};
const ProductVersionModal = () => {
    const {   dependentFeaturesData, productVersionModalType, productVersionData } = useAppSelector(
        (state) => state.products
    );

    const [productVersionSelectedFeatures, setProductVersionSelectedFeatures] = useState<any>([])
    const [productVersionEnableStatus, setProductVersionEnableStatus] = useState<boolean>(true)
    const [productFeatureData, setProductFeatureData] = useState<IProductFeatures[]>([])

    const dispatch = useAppDispatch();
    
    useEffect(()=>{
        if(productVersionModalType === modalType.EDIT){
            if(Object.keys(productVersionData).length > 0){
                const selectedFeatureList =  (productVersionData.productFeatures).map((filteredData: { featureId: any; }) => filteredData.featureId);                
                setProductFeatureData(productVersionData.productFeatures);
                setProductVersionSelectedFeatures(selectedFeatureList);
                setProductVersionEnableStatus(productVersionData.enabled);
                dispatch(setCurrentProductVersionEnableStatus(productVersionData.enabled));                 

            }
        }
    },[productVersionData])

    /** @function transformProductFeatureObj
    * @description Function form a new object for every new feature with productId & productVersionId 
    */
    const transformProductFeatureObj = (data : IProductVersionData) => {
    const productFeatureArr: any[] = data.productFeatures.map(item => ({
            ...item,
            productVersionId: productVersionData.id
    }));
    const finalObj = {...data,
        id: productVersionData.id,
        productId: productVersionData.productId,
        enabled: productVersionData.enabled,
        modified: productVersionData.modified,
        version: productVersionData.version,
        productFeatures: productFeatureArr
    }
    return finalObj;

    }

    /** @function formatPutProductVersionData
    * @description Function to do merge exisiting features array & newly added features array without duplication. 
    */
    const formatPutProductVersionData = (result:any) => {
        const productFeaturesData = transformProductFeatureObj(result);
        const mergedFeatureListArr: IProductFeatures[] = productFeatureData.concat(productFeaturesData.productFeatures);        
        const filteredFeatureListArr: IProductFeatures[] = mergedFeatureListArr.filter((item, index, self) =>
        index === self.findIndex((t) => t.featureId === item.featureId)
        );
        const transformFeatureList: any[] = filteredFeatureListArr.filter((itemB) => itemB.id === 0 || productFeaturesData.productFeatures.some((itemA) => itemA.featureId === itemB.featureId));
        dispatch(setPostProductVersionData({...productVersionData, productFeatures : transformFeatureList}))
    }

    const onHandleMultiListChange = (data: Array<IMultiListArrayType>) => {
        let selectedFeatureList = [];
        setDependentFeaturesData(data)
        const result = transformProductVersionObj(flattenMultiList(data));
        dispatch(setPostProductVersionData(result))
        selectedFeatureList = flattenMultiList(data).filter((eachData) => eachData.checked)
        .map((filteredData) => filteredData.id);
        setProductVersionSelectedFeatures(selectedFeatureList);
        if(productVersionModalType === modalType.EDIT){
            formatPutProductVersionData(result)
        }
    };

    const productVersionStatusOptionList = [
        {
            id: 1,
            text: (
                <TextLabel
                    className="Status_green"
                    text={<LiMultiterm textId="99000784" textVal="Enabled" />}
                />
            ),
        },
        {
            id: 2,
            text: (
                <TextLabel
                    className="Status_gray"
                    text={<LiMultiterm textId="99000809" textVal="Disabled" />}
                />
            ),
        },
    ];

    
    return (
        <FlexboxContainer
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="0.5rem"
            className="Full_height"
        >
            <FlexboxItem>
                <Typography type="H1">
                    {productVersionModalType === modalType.ADD ? (
                        <LiMultiterm textId="99032220" textVal="Add version" />
                    ) : (
                        <LiMultiterm textId="99006184" textVal="Edit" />
                    )}
                </Typography>
            </FlexboxItem>
            <TextLabel
            	inPlaceEdit
				value={
                productVersionEnableStatus
                    ? productVersionStatusOptionList[0]
                    : productVersionStatusOptionList[1]
                }
                onChange={(statusId:any) => {
                    setProductVersionEnableStatus((prev) => !prev);
                    dispatch(setCurrentProductVersionEnableStatus(statusId === 1));                 
                }}
                optionList={
                    productVersionStatusOptionList
                }
			/>
            <FlexboxItem>
                <FlexboxContainer alignItems="start" justifyContent="start">
                    <FlexboxItem className="Modal_each_side_wrapper">
                        <Scrollable>
                            {dependentFeaturesData?.length ? (
                                <MultiList
                                    onChange={(data: Array<IMultiListArrayType>) => onHandleMultiListChange(data)}
                                    title={<LiMultiterm textId="99032441" textVal="Assigned features" />}
                                    showToggleForSelectedOnly
                                    data={getDataForDependentFeatures(
                                        dependentFeaturesData,
                                        productVersionSelectedFeatures ?? [],
                                        0,
                                        []
                                    )}
                                />
                            ) : null}
                        </Scrollable>
                    </FlexboxItem>
                </FlexboxContainer>
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default ProductVersionModal;
