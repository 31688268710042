import { getMultiTermText } from '../../../utils/commonUtils';

const hardLockTypeHeaderData = [
    {
        _key: 'id',
        label: getMultiTermText({ id: '9000439', text: 'ID' }),
        searching: true,
        sorting: true,
        width: '10%',
        isEditable: false,
        columnDataClassName: 'Font_bold'
    },
    {
        _key: 'name',
        label: getMultiTermText({ id: '99018122', text: 'Name' }),
        searching: true,
        sorting: true,
        width: '35%',
        isEditable: true
    },
    {
        _key: 'hasTimeFeature',
        label: getMultiTermText({ id: '99034172', text: 'Time feature' }),
        searching: true,
        sorting: true,
        width: '20%',
        isEditable: true,
        _component: 'checkbox'
    },
    {
        _key: 'hasNetFeature',
        label: getMultiTermText({ id: '99034173', text: 'Network feature' }),
        searching: true,
        sorting: true,
        width: '20%',
        isEditable: true,
        _component: 'checkbox'
    },
    {
        _key: 'maxConcurrentUsers',
        label: getMultiTermText({ id: '99034171', text: 'Max. concurrent users' }),
        searching: true,
        sorting: true,
        width: '20%',
        isEditable: true,
        onCustomTextFieldChange: (value: string) => (value.replace(/\D/g, '') !== '' ? +value.replace(/\D/g, '') : '')
    }
];

export default hardLockTypeHeaderData;
