/* eslint-disable camelcase */
import { createAsyncThunk, createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiBody, MimeTypes } from 'lisecutilityfunctions/lib/openapi';
import { OpenApiClient_Authentication_v2 } from 'lisecutilityfunctions/lib/api';

import {
    ICustomersData,
    IDependentFeatures,
    IApiToken,
    IApiTokenPayload,
    IApiTokenVersion,
    IPayLoadProductionSites,
    ICustomerProducts,
    IAllCustomerProducts
} from '../../../@types/customers';
import { IErrorState, setError } from '../../../slice/ErrorHandling';
import OpenApiClient_customer_management_v2 from '../../../api/openapiclient_customer_management_v2';
import { modalType, extractEmails } from '../../../utils/commonUtils';
import {
    getSelectedFeatures,
    getSelectedProducts,
    getProductionSitesWithLicenseSettings
} from '../utils/productionSitesUtils';

export interface ILicenseSettings {
    noConnEscalation1Time: number;
    noConnEscalation2Time: number;
    noConnEscalationEMail1: string;
    noConnEscalationFrequency: number;
    productionSiteId: number;
}

export interface ICustomersInitialState {
    isLoading: boolean;
    customersData: Array<ICustomersData>;
    selectedCustomerData: any;
    dependentFeaturesData: Array<IDependentFeatures>;
    allProducts: any;
    customerModalType: string;
    productionSitesModalType: string;
    latestCustomerId: number;
    customerModalData: ICustomersData;
    productionSitesModalData: any;
    allCustomersData: any;
    selectedCustomerProductionSites: any;
    nonEscalationEmails: any;
    customerIdToDelete: number;
    latestProductionSitesId: number;
    apiToken: Array<IApiToken>;
    hardlockProductionSites: Array<ICustomerProducts>;
    apiTokenVersion: any;
    apiTokenModalType: string;
    apiTokenModalData: any;
    apiTokenClipBoardPopup: boolean;
    generatedToken: string;
    customerProductionSites: any;
    showLicenseSettings: boolean;
    allApiTokenAccordianData: [];
    selectedApiToken: any;
    postCustomerResponseData: any;
    allCustomerProducts: Array<IAllCustomerProducts>;
    hardlockProducts: any;
    latestV2C: string;
    latestV2CFileDownloaded: boolean;
    defaultLicenseSettings: ILicenseSettings;
}

const initialModalData = {
    sapId: '',
    name: '',
    description: '',
    commercial: false,
    dependentOn: [],
    enabled: true,
    id: 0,
    allProducts: []
};

const defaultLicenseSettings = {
    productionSiteId: 0,
    noConnEscalation1Time: 1,
    noConnEscalationEMail1: '',
    noConnEscalation2Time: 30,
    noConnEscalationFrequency: 1
};

const initialProductionSitesData = {
    licenseSettings: defaultLicenseSettings,
    customerId: 0,
    name: '',
    sapId: '',
    description: '',
    enabled: true,
    lastSynchronisation: '',
    productVersionIds: [],
    featureIds: [],
    id: 0
};

const initialState: ICustomersInitialState = {
    isLoading: false,
    customersData: [],
    selectedCustomerData: {},
    customerModalType: modalType.CLOSED,
    dependentFeaturesData: [],
    latestCustomerId: 0,
    customerModalData: initialModalData,
    allCustomersData: {},
    allProducts: [],
    selectedCustomerProductionSites: initialProductionSitesData,
    nonEscalationEmails: [],
    productionSitesModalData: initialProductionSitesData,
    productionSitesModalType: modalType.CLOSED,
    customerIdToDelete: 0,
    latestProductionSitesId: 0,
    apiToken: [],
    apiTokenModalType: modalType.CLOSED,
    apiTokenModalData: [],
    apiTokenClipBoardPopup: false,
    generatedToken: '',
    customerProductionSites: {},
    showLicenseSettings: true,
    allApiTokenAccordianData: [],
    selectedApiToken: {},
    apiTokenVersion: {},
    hardlockProductionSites: [],
    postCustomerResponseData: {},
    allCustomerProducts: [],
    hardlockProducts: {},
    latestV2C: '',
    latestV2CFileDownloaded: false,
    defaultLicenseSettings
};

/**
 * @function getCustomers
 * @description Function to fetch all customers list
 */
export const getCustomers = createAsyncThunk('getCustomers', async (_, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_Customers();
        if (response?.status === 204) {
            return [];
        }
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data);
        return responseData;
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in getCustomers '
        };
        return dispatch(setError(errorValue));
    }
});

export const getCustomerBasedOnId = createAsyncThunk(
    'getCustomerBasedOnId',
    async (customerId: number, { dispatch }) => {
        const openedAccordion = sessionStorage.getItem('accordionOpened');
        let jsonData;
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).GET_Customers_id(customerId);
            if (response?.status === 204) {
                return [];
            }
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            jsonData = conversionLib.convertToJSON(response.data);
            return jsonData;
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'error in getFeatures '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function getDependentFeatures
 * @description Function to fetch all getDependentFeatures list
 */
export const getDependentFeatures = createAsyncThunk('getDependentFeatures', async (_, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_Features_DependentFeatures();
        if (response?.status === 204) {
            return [];
        }
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data);
        return responseData;
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in getFeatures '
        };
        return dispatch(setError(errorValue));
    }
});

/**
 * @function getProducts
 * @description Function to fetch all getDependentFeatures list
 */
export const getProducts = createAsyncThunk('getProducts', async (_, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_Products();
        if (response?.status === 204) {
            return [];
        }
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data);
        return responseData;
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in getProducts '
        };
        return dispatch(setError(errorValue));
    }
});

/**
 * @function getProductionSitesById
 * @description Function to fetch all getProductionSitesById list
 */
export const getProductionSitesById = createAsyncThunk(
    'getProductionSitesById',
    async (customerId: number, { dispatch }) => {
        try {
            const response =
                await OpenApiClient_customer_management_v2.getClient(null).GET_ProductionSites_id(customerId);
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            const responseData = conversionLib.convertToJSON(response.data);
            const defaultLicenseSettingsVal = responseData.licenseSettings ? defaultLicenseSettings : null;
            const productionSitesWithLicenseSettings = getProductionSitesWithLicenseSettings(
                responseData,
                defaultLicenseSettingsVal
            );
            return productionSitesWithLicenseSettings;
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'error in getProductionSitesById '
            };
            return dispatch(setError(errorValue));
        }
    }
);

//  * @function getAPItoken
//  * @description Function to fetch all API Token list
//  */
export const getAPItoken = createAsyncThunk('getAPItoken', async (productionSitesID: number, { dispatch }) => {
    const subject = `subject=='${productionSitesID}'`;
    try {
        const response = await OpenApiClient_Authentication_v2.getClient(null).GET_Token_summary(
            null,
            null,
            subject,
            null
        );
        if (response?.status === 204) {
            return [];
        }
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data);
        return responseData;
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in getAPItoken '
        };
        return dispatch(setError(errorValue));
    }
});

/**
 * @function getHardlockProductionSites
 * @description Function to fetch all ProductionSites with Hardlocks
 */
export const getHardlockProductionSites = createAsyncThunk(
    'getHardlockProductionSites',
    async (productionSiteId: number, { dispatch }) => {
        try {
            const response =
                await OpenApiClient_customer_management_v2.getClient(null).GET_ProductionSites_id_Hardlock(
                    productionSiteId
                );
            if (response.status === 204) {
                return [];
            }
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            const responseData = conversionLib.convertToJSON(response.data);
            return responseData;
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'error in getHardlockProductionSites '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * Fetching the combined data for the production sites and Api tokens and hardlocksPerProductionSite
 */
// Thunk for fetching combined data
export const fetchProductionSitesandApiToken = createAsyncThunk(
    'mySlice/fetchCombinedDataForProductionSites',
    async (productionSitesID: number, { dispatch }) => {
        try {
            const productionSitesPerCustomer = await dispatch(getProductionSitesById(productionSitesID)).unwrap();
            const apiTokens = await dispatch(getAPItoken(productionSitesID)).unwrap();
            const hardlockProductionSites = await dispatch(getHardlockProductionSites(productionSitesID)).unwrap();
            const escalationEmails = productionSitesPerCustomer?.licenseSettings
                ? extractEmails(productionSitesPerCustomer?.licenseSettings)
                : [];
            const apiTokensPerCustomer = apiTokens.length ? apiTokens : [];
            const hardlocksPerCustomer = hardlockProductionSites?.length ? hardlockProductionSites : [];
            const customerProductionSitesAndApiToken: any = {
                ...productionSitesPerCustomer,
                escalationEmail: escalationEmails,
                apiToken: apiTokensPerCustomer,
                hardlock: hardlocksPerCustomer
            };
            return customerProductionSitesAndApiToken;
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'Error in getting production sites details'
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function putCustomer
 * @description Function to put customer
 */
export const putCustomer = createAsyncThunk(
    'putFeature',
    async ({ body, customerId }: { body: ICustomersData; customerId: string }, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).PUT_Customers_id(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, body),
                customerId
            );
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            return dispatch(getCustomers());
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'Error in getCustomers '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function getLatestFeatureId
 * @description Function to fetch all features list
 */
export const getLatestCustomerId = createAsyncThunk('getLatestCustomerId', async (_, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_Customers(
            null,
            1,
            null,
            '-id',
            ['id']
        );
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data)[0].id;
        return responseData;
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in getFeatures '
        };
        return dispatch(setError(errorValue));
    }
});

/**
 * @function postCustomer
 * @description Function to do post Customer
 */
export const postCustomer = createAsyncThunk('postCustomer', async (postCustomerBody: ICustomersData, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).POST_Customers(
            new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, postCustomerBody)
        );
        if (response?.status !== 201) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data);
        dispatch(getCustomers());
        return responseData;
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in getFeatures '
        };
        return dispatch(setError(errorValue));
    }
});

/**
 * @function deleteCustomer
 * @description Function to delete feature
 */
export const deleteCustomer = createAsyncThunk('deleteCustomer', async (featureId: number, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).DELETE_Customers_id(featureId);
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        return dispatch(getCustomers());
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'Error in delete customer '
        };
        return dispatch(setError(errorValue));
    }
});

/**
 * @function postProductionSites
 * @description Function to do post Customer
 */
export const postProductionSites = createAsyncThunk(
    'postProductionSites',
    async (productionSitesPayload: IPayLoadProductionSites, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).POST_ProductionSites(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, productionSitesPayload)
            );
            if (response?.status !== 201) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            return dispatch(getCustomerBasedOnId(productionSitesPayload.customerId));
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'Error in adding production sites'
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function getAPItokenVersion
 * @description Function to fetch all API Token list
 */
export const getAPItokenVersion = createAsyncThunk(
    'getAPItokenVersion',
    async (object: any, { dispatch, rejectWithValue }) => {
        const name = object.name ?? '';
        const audience = object.audience ?? '';
        const subject = object.subject.toString() ?? null;
        try {
            const response = await OpenApiClient_Authentication_v2.getClient(null).GET_Token_name_audience(
                name,
                audience,
                subject
            );
            if (response.status === 204) {
                return [];
            }
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            const responseData = conversionLib.convertToJSON(response.data);
            return responseData;
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'error in getAPItokenVersion '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function patchCustomer
 * @description Function to patch customer
 */
export const patchCustomer = createAsyncThunk(
    'patchCustomer',
    async ({ body, customerId }: { body: any; customerId: number }, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).PATCH_Customers_id(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, body),
                customerId
            );
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            return dispatch(getCustomers());
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'Error in getCustomers '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function putProductionSites
 * @description Function to put customer
 */
export const putProductionSites = createAsyncThunk(
    'putProductionSites',
    async ({ body, productionSitesId }: { body: IPayLoadProductionSites; productionSitesId: string }, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).PUT_ProductionSites_id(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, body),
                productionSitesId
            );
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }

            return dispatch(getCustomerBasedOnId(body.customerId));
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'Error in update Production Sites'
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function patchProductionSites
 * @description Function to patch productionSites
 */
export const patchProductionSites = createAsyncThunk(
    'patchProductionSites',
    async ({ body, productionSitesId }: { body: IPayLoadProductionSites; productionSitesId: string }, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).PATCH_ProductionSites_id(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, body),
                productionSitesId
            );
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }

            return dispatch(getCustomerBasedOnId(body.customerId));
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'Error in update Production Sites'
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function getCustomerProducts
 * @description Function to fetch all getCustomerProducts list
 */
export const getCustomerProducts = createAsyncThunk(
    'getCustomerProducts',

    async (
        {
            ishardlockControlled,
            productionSiteId
        }: { ishardlockControlled: boolean; productionSiteId: string | number },
        { dispatch }
    ) => {
        const filter = `productionSiteId==${productionSiteId}`;
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).GET_CustomerProducts(
                ishardlockControlled,
                null,
                null,
                filter
            );
            if (response?.status === 204) {
                return [];
            }
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            const responseData = conversionLib.convertToJSON(response.data);
            return responseData;
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'error in getCustomerProducts '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function patchedGetHardlockProductionSites
 * @description Function to fetch all Patched ProductionSites with Hardlocks
 */
export const patchedGetHardlockProductionSites = createAsyncThunk(
    'patchedGetHardlockProductionSites',
    async (productionSiteId: number, { dispatch }) => {
        try {
            const response =
                await OpenApiClient_customer_management_v2.getClient(null).GET_ProductionSites_id_Hardlock(
                    productionSiteId
                );
            if (response.status === 204) {
                return [];
            }
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            const responseData = conversionLib.convertToJSON(response.data);
            return responseData;
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'error in patchedGetHardlockProductionSites '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function getLatestProductionSitesId
 * @description Function to fetch all features list
 */
export const getLatestProductionSitesId = createAsyncThunk('getLatestProductionSitesId', async (_, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_ProductionSites(
            null,
            1,
            null,
            '-id',
            ['id']
        );
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data)[0].id;
        return responseData;
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in getFeatures '
        };
        return dispatch(setError(errorValue));
    }
});

//  * @function patchApiToken
//  * @description Function to patch feature
//  */
export const patchApiToken = createAsyncThunk(
    'patchApiToken',
    async ({ body }: { body: any | undefined }, { dispatch }) => {
        try {
            const patchApiTokenPayload = {
                id: body.id,
                valid: body?.valid === 1
            };
            const response = await OpenApiClient_Authentication_v2.getClient(null).PATCH_Token(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, patchApiTokenPayload)
            );
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            return dispatch(getAPItokenVersion(body.apiTokenData));
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'Error in patchApi Token '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function patchCustomer
 * @description Function to patch customer
 */
export const patchCustomerProduct = createAsyncThunk(
    'patchCustomerProduct',
    async ({ body, customerProductId }: { body: any; customerProductId: number }, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).PATCH_CustomerProducts_id(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, body),
                customerProductId
            );
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            const customerProducts = await dispatch(
                getCustomerProducts({ ishardlockControlled: true, productionSiteId: body.productionSiteId })
            );
            const hardlockProductionSites = await dispatch(patchedGetHardlockProductionSites(body.productionSiteId));
            return { hardlockProductionSites, customerProducts };
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'Error in patchCustomerProduct '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function putCustomerProduct
 * @description Function to put customer products
 */
export const putCustomerProduct = createAsyncThunk(
    'putCustomerProduct',
    async ({ body, customerProductId }: { body: any; customerProductId: number }, { dispatch }) => {
        try {
            const response = await OpenApiClient_customer_management_v2.getClient(null).PUT_CustomerProducts_id(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, body),
                customerProductId
            );
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            const customerProducts = await dispatch(
                getCustomerProducts({ ishardlockControlled: true, productionSiteId: body.productionSiteId })
            );
            const hardlockProductionSites = await dispatch(patchedGetHardlockProductionSites(body.productionSiteId));
            return { hardlockProductionSites, customerProducts };
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'Error in updating the customer products'
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function patchHardlockProductEntitlement
 * @description Function to hardlock id with product entitlement
 */
export const patchHardlockProductEntitlement = createAsyncThunk(
    'patchCustomerProduct',
    async ({ hardlockId }: { hardlockId: number }, { dispatch }) => {
        try {
            const response =
                await OpenApiClient_customer_management_v2.getClient(null).PATCH_Hardlock_id_ProduceEntitlement(
                    hardlockId
                );
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            const responseData = conversionLib.convertToJSON(response.data);
            return responseData;
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'Error in patchHardlockProductEntitlement '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function getLatestV2C file
 * @description Function to latest v2C file using hardlocks
 */
export const getLatestV2C = createAsyncThunk(
    'getLatestV2C',
    async ({ hardlockId }: { hardlockId: number }, { dispatch }) => {
        try {
            const response =
                await OpenApiClient_customer_management_v2.getClient(null).GET_Hardlock_id_LatestV2C(hardlockId);
            if (response?.status !== 200) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'Error in getLatestV2C '
            };
            return dispatch(setError(errorValue));
        }
    }
);

/**
 * @function postApiToken
 * @description Function to do post Customer
 */

export const postApiToken = createAsyncThunk(
    'postApiToken',
    async (apiTokenPayload: IApiTokenPayload, { dispatch }) => {
        const postApiTokenPayload = {
            audience: apiTokenPayload.audience,
            name: apiTokenPayload.name,
            subject: apiTokenPayload.subject,
            idp: apiTokenPayload.idp,
            expires: apiTokenPayload.expires
        };
        try {
            const response = await OpenApiClient_Authentication_v2.getClient(null).POST_Token(
                new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, postApiTokenPayload)
            );
            let jsonData;

            if (response?.status !== 201) {
                const errorValue: IErrorState = {
                    statusCode: response?.status,
                    message: response?.statusText
                };
                return dispatch(setError(errorValue));
            }
            if (response.status === 200 || response.status === 201) {
                jsonData = conversionLib.convertToJSON(response.data);
            }
            return (
                apiTokenPayload.modalType === modalType.ADD_VERSION
                    ? dispatch(getAPItokenVersion(apiTokenPayload))
                    : dispatch(fetchProductionSitesandApiToken(apiTokenPayload.subject)),
                jsonData.token
            );
        } catch (error: any) {
            const errorValue: IErrorState = {
                statusCode: error.response?.status ?? 0,
                message: 'error in postApiToken '
            };
            return dispatch(setError(errorValue));
        }
    }
);

const customersSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        setcustomerModalType: (state, action: PayloadAction<string>) => {
            state.customerModalType = action.payload;
            if (action.payload === modalType.CLOSED) {
                state.customerModalData = initialModalData;
                state.dependentFeaturesData = [];
            }
        },
        setProductionSitesModalType: (state, action: PayloadAction<string>) => {
            state.productionSitesModalType = action.payload;
            if (action.payload === modalType.CLOSED) {
                state.selectedCustomerProductionSites = initialProductionSitesData;
            }
        },
        setSelectedCustomerProductionSites: (state, action: PayloadAction<string>) => {
            state.selectedCustomerProductionSites = action.payload;
        },
        setProductionSitesModalData: (state, action: PayloadAction<any>) => {
            state.productionSitesModalData = action.payload;
        },
        setcustomerModalData: (state, action: PayloadAction<any>) => {
            state.customerModalData = action.payload;
        },
        setCustomerIdToDelete: (state, action: PayloadAction<number>) => {
            state.customerIdToDelete = action.payload;
        },
        setAllCustomerAccordianData: (state, action) => {
            const currentState = current(state);
            state.allCustomersData = { ...currentState.allCustomersData, ...action.payload };
        },
        setAllProducts: (state, action) => {
            state.allProducts = action.payload;
        },
        setNonEscalationEmails: (state, action: PayloadAction<any>) => {
            state.nonEscalationEmails = action.payload;
        },
        setApiTokenModalType: (state, action: PayloadAction<any>) => {
            state.apiTokenModalType = action.payload.modalStatus;
            if (
                (action.payload.modalStatus === modalType.CLOSED && action.payload.type !== modalType.ADD_VERSION) ||
                action.payload.modalStatus === modalType.ADD_TOKEN
            ) {
                state.apiTokenModalData = initialModalData;
            }
        },
        setApiTokenModalData: (state, action: PayloadAction<any>) => {
            state.apiTokenModalData = action.payload;
        },
        setOpenApiTokenClipBoardPopup: (state, action: PayloadAction<any>) => {
            state.apiTokenClipBoardPopup = action.payload;
        },
        setShowLicenseSettings: (state, action: PayloadAction<boolean>) => {
            state.showLicenseSettings = action.payload;
        },
        setSelectedApiToken: (state, action: PayloadAction<boolean>) => {
            state.selectedApiToken = action.payload;
        },
        clearPostCustomerResponseData: (state, action: PayloadAction<any>) => {
            state.postCustomerResponseData = {};
        },
        setLatestFileDownloaded: (state, action: PayloadAction<any>) => {
            state.latestV2CFileDownloaded = action.payload;
        },
        clearV2cDownloadedData: (state) => {
            state.latestV2CFileDownloaded = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCustomers.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(
            getCustomers.fulfilled,
            (state: ICustomersInitialState, action: PayloadAction<Array<ICustomersData>>) => {
                state.isLoading = false;
                state.customersData = action.payload;
            }
        );
        builder.addCase(getCustomers.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(getDependentFeatures.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(
            getDependentFeatures.fulfilled,
            (state: ICustomersInitialState, action: PayloadAction<Array<IDependentFeatures>>) => {
                state.isLoading = false;
                state.dependentFeaturesData = action.payload;
            }
        );
        builder.addCase(getDependentFeatures.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(getProducts.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(
            getProducts.fulfilled,
            (state: ICustomersInitialState, action: PayloadAction<Array<IDependentFeatures>>) => {
                state.isLoading = false;
                state.allProducts = action.payload;
            }
        );
        builder.addCase(getProducts.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(getProductionSitesById.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(
            getProductionSitesById.fulfilled,
            (state: ICustomersInitialState, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.selectedCustomerProductionSites = action.payload;
            }
        );
        builder.addCase(getProductionSitesById.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });

        builder.addCase(getLatestCustomerId.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(
            getLatestCustomerId.fulfilled,
            (state: ICustomersInitialState, action: PayloadAction<number>) => {
                state.isLoading = false;
                state.latestCustomerId = action.payload;
            }
        );
        builder.addCase(getLatestCustomerId.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(getLatestProductionSitesId.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(
            getLatestProductionSitesId.fulfilled,
            (state: ICustomersInitialState, action: PayloadAction<number>) => {
                state.isLoading = false;
                state.latestProductionSitesId = action.payload;
            }
        );
        builder.addCase(getLatestProductionSitesId.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });

        builder.addCase(postCustomer.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(postCustomer.fulfilled, (state: ICustomersInitialState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.postCustomerResponseData = action.payload;
            state.customerModalType = modalType.CLOSED;
            state.customerModalData = initialModalData;
        });
        builder.addCase(postCustomer.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(putCustomer.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(putCustomer.fulfilled, (state: ICustomersInitialState) => {
            state.isLoading = false;
            state.customerModalType = modalType.CLOSED;
            state.customerModalData = initialModalData;
            state.dependentFeaturesData = [];
        });
        builder.addCase(putCustomer.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(putProductionSites.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(putProductionSites.fulfilled, (state: ICustomersInitialState) => {
            state.isLoading = false;
            state.productionSitesModalType = modalType.CLOSED;
            state.selectedCustomerProductionSites = initialModalData;
            state.dependentFeaturesData = [];
        });
        builder.addCase(putProductionSites.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(patchProductionSites.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(patchProductionSites.fulfilled, (state: ICustomersInitialState) => {
            state.isLoading = false;
            state.productionSitesModalType = modalType.CLOSED;
            state.selectedCustomerProductionSites = initialModalData;
            state.dependentFeaturesData = [];
        });
        builder.addCase(patchProductionSites.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(deleteCustomer.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(deleteCustomer.fulfilled, (state: ICustomersInitialState) => {
            state.isLoading = false;
            state.customerModalType = modalType.CLOSED;
            state.customerIdToDelete = 0;
        });

        builder.addCase(deleteCustomer.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(patchCustomer.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(patchCustomer.fulfilled, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(patchCustomer.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(postProductionSites.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(postProductionSites.fulfilled, (state: ICustomersInitialState) => {
            state.isLoading = false;
            state.productionSitesModalType = modalType.CLOSED;
        });
        builder.addCase(postProductionSites.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(getCustomerBasedOnId.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(getCustomerBasedOnId.fulfilled, (state: ICustomersInitialState, action: any) => {
            state.isLoading = false;
            state.selectedCustomerProductionSites = initialProductionSitesData;
            state.selectedCustomerData = action.payload;
            const currentState = current(state);
            state.allCustomersData = {
                ...currentState.allCustomersData,
                ...{ [action.payload.id]: action.payload }
            };
        });
        builder.addCase(getCustomerBasedOnId.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(getAPItoken.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(getAPItoken.fulfilled, (state: ICustomersInitialState, action: any) => {
            state.isLoading = false;
            state.apiToken = action.payload;
        });
        builder.addCase(getAPItoken.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(getHardlockProductionSites.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(getHardlockProductionSites.fulfilled, (state: ICustomersInitialState, action: any) => {
            state.isLoading = false;
            state.hardlockProductionSites = action.payload;
        });
        builder.addCase(getHardlockProductionSites.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(patchedGetHardlockProductionSites.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(patchedGetHardlockProductionSites.fulfilled, (state: ICustomersInitialState, action: any) => {
            state.isLoading = false;
            const currentState = current(state);

            const productionSitesObj = {
                ...currentState.customerProductionSites,
                [action.meta.arg]: {
                    ...currentState.customerProductionSites[action.meta.arg],
                    productionSites: {
                        ...currentState.customerProductionSites[action.meta.arg].productionSites,
                        hardlock: action.payload
                    }
                }
            };
            state.customerProductionSites = productionSitesObj;
            state.hardlockProductionSites = action.payload;
        });
        builder.addCase(patchedGetHardlockProductionSites.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(getAPItokenVersion.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(getAPItokenVersion.fulfilled, (state: ICustomersInitialState, action: any) => {
            const currentState = current(state);
            const apiTokenVersionObj = {
                ...state.apiTokenVersion,
                [currentState.apiTokenModalData.ApiTokenindex]: action.payload
            };
            state.apiTokenVersion = apiTokenVersionObj;
            state.isLoading = false;
        });
        builder.addCase(getAPItokenVersion.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(patchApiToken.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(patchApiToken.fulfilled, (state: ICustomersInitialState, action: any) => {
            state.isLoading = false;
            state.apiTokenVersion = action.payload;
        });
        builder.addCase(patchApiToken.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(postApiToken.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(postApiToken.fulfilled, (state: ICustomersInitialState, action: any) => {
            state.isLoading = false;
            state.apiTokenClipBoardPopup = true;
            state.apiTokenModalType = modalType.CLOSED;
            state.generatedToken = action.payload;
        });
        builder.addCase(postApiToken.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(fetchProductionSitesandApiToken.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(fetchProductionSitesandApiToken.fulfilled, (state: ICustomersInitialState, action: any) => {
            state.isLoading = false;
            const selectedFeatureId = getSelectedFeatures(action.payload);
            const selectedProduct = getSelectedProducts(action.payload);
            const productionSitesObj = {
                ...state.customerProductionSites,
                [action.payload.id]: {
                    selectedProducts: selectedProduct,
                    productionSites: action.payload,
                    selectedFeatureIds: selectedFeatureId
                }
            };
            state.customerProductionSites = productionSitesObj;
        });
        builder.addCase(fetchProductionSitesandApiToken.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(getCustomerProducts.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(getCustomerProducts.fulfilled, (state: ICustomersInitialState, action: any) => {
            state.isLoading = false;
            state.allCustomerProducts = action.payload;
        });
        builder.addCase(getCustomerProducts.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
        builder.addCase(patchHardlockProductEntitlement.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(patchHardlockProductEntitlement.fulfilled, (state: ICustomersInitialState, action: any) => {
            state.isLoading = false;
            state.hardlockProducts = action.payload;
        });
        builder.addCase(patchHardlockProductEntitlement.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });

        builder.addCase(getLatestV2C.pending, (state: ICustomersInitialState) => {
            state.isLoading = true;
        });
        builder.addCase(getLatestV2C.fulfilled, (state: ICustomersInitialState, action: any) => {
            state.isLoading = false;
            state.latestV2C = action.payload;
        });
        builder.addCase(getLatestV2C.rejected, (state: ICustomersInitialState) => {
            state.isLoading = false;
        });
    }
});

export const {
    setcustomerModalType,
    setcustomerModalData,
    setCustomerIdToDelete,
    setAllCustomerAccordianData,
    setNonEscalationEmails,
    setProductionSitesModalType,
    setProductionSitesModalData,
    setApiTokenModalType,
    setApiTokenModalData,
    setSelectedCustomerProductionSites,
    setOpenApiTokenClipBoardPopup,
    setAllProducts,
    setShowLicenseSettings,
    setSelectedApiToken,
    clearPostCustomerResponseData,
    setLatestFileDownloaded,
    clearV2cDownloadedData
} = customersSlice.actions;
export default customersSlice.reducer;
