/* eslint-disable camelcase */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';

import { ILoginData, ILoginHistoryTableData, ILoginsInitialState } from '../../../@types/login';
import { IErrorState, setError } from '../../../slice/ErrorHandling';
import OpenApiClient_customer_management_v2 from '../../../api/openapiclient_customer_management_v2';
import { hanndlingDataAsPerLogin, hanndlingDataAsPerAuthToken, combinedLoginHistory } from '../utils/authToken';

const initialModalData = {
    id: 0,
    user: '',
    authTokenId: 0,
    timestamp: '',
    type: '',
    machineSerial: '',
    customerId: 0,
    requestedAuthorizations: [],
    allAuthorizations: false
};

const initialState: ILoginsInitialState = {
    isLoading: false,
    loginsData: [],
    loginHistory: []
};

/**
 * @function getLoginHistory
 * @description Function to fetch all login history list
 */
export const getLogins = createAsyncThunk('getLogins', async (_, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_Logins();
        if (response?.status === 204) {
            return [];
        }
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data);
        return responseData;
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in getLogins '
        };
        return dispatch(setError(errorValue));
    }
});

/**
 * @function getAuthTokens
 * @description Function to fetch all auth token history list
 */
export const getAuthTokens = createAsyncThunk('getAuthTokens', async (_, { dispatch }) => {
    try {
        const response = await OpenApiClient_customer_management_v2.getClient(null).GET_AuthTokens();
        if (response?.status === 204) {
            return [];
        }
        if (response?.status !== 200) {
            const errorValue: IErrorState = {
                statusCode: response?.status,
                message: response?.statusText
            };
            return dispatch(setError(errorValue));
        }
        const responseData = conversionLib.convertToJSON(response.data);
        return responseData;
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in getAuthTokens '
        };
        return dispatch(setError(errorValue));
    }
});

// Thunk for fetching combined data
export const fetchCombinedData = createAsyncThunk('mySlice/fetchCombinedData', async (_, { dispatch }) => {
    try {
        const logins = await dispatch(getLogins()).unwrap();
        const authTokens = await dispatch(getAuthTokens()).unwrap();
        const allLogins = logins.length >= 1 ? logins.map(hanndlingDataAsPerLogin) : [];
        const allAuthTokens = authTokens.length >= 1 ? authTokens.map(hanndlingDataAsPerAuthToken) : [];
        const loginHistory: ILoginHistoryTableData[] = combinedLoginHistory(allLogins, allAuthTokens);        
        return loginHistory;
    } catch (error: any) {
        const errorValue: IErrorState = {
            statusCode: error.response?.status ?? 0,
            message: 'error in getAuthTokens '
        };
        return dispatch(setError(errorValue));
    }
});

const loginsHistorySlice = createSlice({
    name: 'loginHistory',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLogins.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getLogins.fulfilled, (state, action: PayloadAction<Array<ILoginData>>) => {
                state.isLoading = false;
                state.loginsData = action.payload;
            })
            .addCase(getLogins.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getAuthTokens.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAuthTokens.fulfilled, (state, action: PayloadAction<Array<ILoginData>>) => {
                state.isLoading = false;
                state.loginsData = action.payload;
            })
            .addCase(getAuthTokens.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(fetchCombinedData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchCombinedData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.loginHistory = action.payload;
            })
            .addCase(fetchCombinedData.rejected, (state, action) => {
                state.isLoading = false;
            });
    }
});

// export const {} = loginsSlice.actions;
export default loginsHistorySlice.reducer;
