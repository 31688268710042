import React from 'react';
import { Popup } from 'ui-components';
import { getMultiTermText, modalType } from '../../../../utils/commonUtils';

interface IActionModal {
    onClickCancelClose: () => void;
    actionType: string;
    modalColumnNumber: number;
    ModalContent: JSX.Element;
    onClickCopyAction: () => void;
}

const ApitokenActionModal = ({
    onClickCancelClose,
    actionType,
    modalColumnNumber,
    ModalContent,
    onClickCopyAction
}: IActionModal) => {
    const getActionButtonMultiTerm = () => {
        switch (actionType) {
            case modalType.ADD: {
                return getMultiTermText({ id: '06001696', text: 'Add' });
            }
            case modalType.EDIT: {
                return getMultiTermText({ id: '06007335', text: 'Save' });
            }
            case modalType.DELETE: {
                return getMultiTermText({ id: '99003381', text: 'Delete' });
            }
            case modalType.COPY: {
                return getMultiTermText({ id: '99003381', text: 'Delete' });
            }
            default:
                return getMultiTermText({ id: '06001699', text: 'Copy' });
        }
    };
    return (
        <Popup
            onClose={onClickCancelClose}
            mode="modal"
            className={modalColumnNumber === 1 ? 'Single_column_modal ' : 'App_Two_column_modal '}
            disableResize
            buttonArr={{
                rightSideButtons: [
                    {
                        text: getActionButtonMultiTerm(),
                        className: 'Primary_button',
                        onClick: onClickCopyAction
                    }
                ]
            }}
        >
            {ModalContent}
        </Popup>
    );
};

export default ApitokenActionModal;
