import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IBreadCrumbsData } from '../@types/common';

export interface IBreadCrumbsState {
    breadCrumbsData: Array<IBreadCrumbsData>;
}
const initialState: IBreadCrumbsState = {
    breadCrumbsData: []
};

const breadCrumbsSlice = createSlice({
    name: '/breadCrumbs',
    initialState,
    reducers: {
        setBreadCrumbsData: (state, action: PayloadAction<Array<IBreadCrumbsData>>) => {
            state.breadCrumbsData = action.payload;
        }
    }
});

export const { setBreadCrumbsData } = breadCrumbsSlice.actions;

export default breadCrumbsSlice.reducer;
