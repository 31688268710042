import { getMultiTermText, getLabelOptions } from '../../../utils/commonUtils';

export default [
    {
        _key: 'version',
        label: getMultiTermText({ id: '06000764', text: 'Date' }),
        searching: false,
        sorting: true,
        width: '65%',
        isEditable: false,
    },
    
    {
        _key: 'enabled',
        label: getMultiTermText({ id: '03000144', text: 'Status' }),
        searching: false,
        sorting: true,
        width: '35%',
        isEditable: false,
        _component: 'label',
        filterOptions: [
            {
                id: 1,
                name: getMultiTermText({ id: '99000784', text: 'Enabled' })
            },
            {
                id: 2,
                name: getMultiTermText({ id: '99000809', text: 'Disabled' })
            }
        ],
        labelOptions: [
            {
                id: 1,
                text: getLabelOptions({ id: '99000784', text: 'Enabled', className: 'Status_green' })
            },
            {
                id: 2,
                text: getLabelOptions({ id: '99000809', text: 'Disabled', className: 'Status_gray' })
            }
        ]
    },

];
