/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import { FlexboxContainer, FlexboxItem, Typography, RadioButton, Search } from 'ui-components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    departmentDropdownData,
    changeLocationRadioGroup,
    hardLockChangeLocationTypes
} from '../../constants/HardlocksConstant';
import {
    getCustomersData,
    getUsersData,
    setDropdownSearchLoading,
    setSelectedLocationId,
    setSelectedLocationName,
    setSelectedLocationType
} from '../../slice';
import './HardlocksModal.css';

const ChangeLocationModal = () => {
    const {
        usersListData,
        customersListData,
        dropdownSearchLoading,
        selectedLocationType,
        locationChangeHardlockData
    } = useAppSelector((state) => state.hardlocks);
    const dispatch = useAppDispatch();
    const [userDropDownItems, setUserDropDownItems] = useState<any[]>([]);
    const [customerDropdownItems, setCustomerDropdownItems] = useState<any[]>([]);
    const [departmentDropdownItems, setDepartmentDropdownItems] = useState<any[]>([]);
    const [deptSearch, setDeptSearch] = useState<string>('');
    const [userSearch, setUserSearch] = useState<string>('');
    const [customerSearch, setCustomerSearch] = useState<string>('');

    useEffect(() => {
        setDeptSearch('');
        setUserSearch('');
        setCustomerSearch('');
    }, [selectedLocationType]);

    useEffect(() => {
        switch (locationChangeHardlockData?.locationType) {
            case hardLockChangeLocationTypes.DEPARTMENT:
                setDeptSearch(
                    `${locationChangeHardlockData.locationName ? `${locationChangeHardlockData.locationName}` : ''}`
                );
                break;
            case hardLockChangeLocationTypes.USER:
                setUserSearch(
                    `${locationChangeHardlockData.locationName ? `${locationChangeHardlockData.locationName}` : ''}`
                );
                break;
            case hardLockChangeLocationTypes.CUSTOMER:
                setCustomerSearch(
                    `${locationChangeHardlockData.locationName ? `${locationChangeHardlockData.locationName}` : ''}`
                );
                break;
            default:
                break;
        }
    }, [locationChangeHardlockData]);

    useEffect(() => {
        const getSearchData = setTimeout(() => {
            if (userSearch) dispatch(getUsersData(userSearch));
        }, 1000);
        return () => clearTimeout(getSearchData);
    }, [userSearch]);

    useEffect(() => {
        const getSearchData = setTimeout(() => {
            if (customerSearch) dispatch(getCustomersData(customerSearch));
        }, 1000);
        return () => clearTimeout(getSearchData);
    }, [customerSearch]);

    useEffect(() => {
        if (deptSearch) {
            setDepartmentDropdownItems(
                departmentDropdownData.filter((eachData) =>
                    eachData.name?.toLowerCase().includes(deptSearch.toLowerCase())
                )
            );
        }
        dispatch(setDropdownSearchLoading(false));
    }, [deptSearch]);

    useEffect(() => {
        const userDropdownList = usersListData?.map((user) => ({
            id: user.email,
            name: `${user.email}, ${user.firstName} ${user.lastName}`,
            locationName: user.email
        }));
        setUserDropDownItems(userDropdownList);
    }, [usersListData]);

    useEffect(() => {
        const userDropdownList = customersListData?.map((customer) => ({
            id: customer.id,
            name: `${customer.id}, ${customer.name} - ${customer.description}`,
            locationName: customer?.name ? customer.name : ''
        }));
        setCustomerDropdownItems(userDropdownList);
    }, [customersListData]);

    const getDropdownItems = (type: number) => {
        switch (type) {
            case hardLockChangeLocationTypes.DEPARTMENT:
                return departmentDropdownItems;
            case hardLockChangeLocationTypes.USER:
                return userDropDownItems;
            case hardLockChangeLocationTypes.CUSTOMER:
                return customerDropdownItems;
            default:
                return [];
        }
    };

    const getControlledInputValue = (type: number) => {
        switch (type) {
            case hardLockChangeLocationTypes.DEPARTMENT:
                return deptSearch;
            case hardLockChangeLocationTypes.USER:
                return userSearch;
            case hardLockChangeLocationTypes.CUSTOMER:
                return customerSearch;
            default:
                return '';
        }
    };

    const handleSearch = (searchInput: string) => {
        dispatch(setDropdownSearchLoading(true));
        dispatch(setSelectedLocationId(0));
        dispatch(setSelectedLocationName(''));
        switch (selectedLocationType) {
            case hardLockChangeLocationTypes.DEPARTMENT:
                setDeptSearch(searchInput);
                break;
            case hardLockChangeLocationTypes.USER:
                setUserSearch(searchInput);
                break;
            case hardLockChangeLocationTypes.CUSTOMER:
                setCustomerSearch(searchInput);
                break;
            default:
                break;
        }
    };

    const handleOnSuggetionClick = (selectedData: any) => {
        dispatch(setSelectedLocationId(selectedData.id));
        switch (selectedLocationType) {
            case hardLockChangeLocationTypes.DEPARTMENT:
                dispatch(setSelectedLocationName(selectedData.name));
                setDeptSearch(selectedData.name);
                break;
            case hardLockChangeLocationTypes.USER:
                dispatch(setSelectedLocationName(selectedData.locationName));
                setUserSearch(selectedData.locationName);
                break;
            case hardLockChangeLocationTypes.CUSTOMER:
                dispatch(setSelectedLocationName(selectedData.locationName));
                setCustomerSearch(selectedData.locationName);
                break;
            default:
                break;
        }
    };

    return (
        <FlexboxContainer flexDirection="column" alignItems="start" justifyContent="start" gap="0.5rem">
            <FlexboxItem>
                <Typography type="H1">
                    <LiMultiterm textId="99034256" textVal="Change location" />
                </Typography>
            </FlexboxItem>
            <FlexboxItem>
                <FlexboxItem className="Modal_each_side_wrapper Modal_changeLocation">
                    <FlexboxContainer flexDirection="column" className="Change_location">
                        {changeLocationRadioGroup.map((locationType) => (
                            <FlexboxContainer
                                className="ChangeLocation_item_wrapper"
                                key={locationType.val}
                                flexDirection="row"
                                gap="1.25rem"
                                alignItems="start"
                            >
                                <FlexboxItem
                                    className="ChangeLocation_radio_item"
                                    key={locationType.val}
                                    style={{ width: '50%' }}
                                >
                                    <RadioButton
                                        checked={locationType.val === selectedLocationType}
                                        radioSuffix={locationType.radioSuffix}
                                        onChange={() => dispatch(setSelectedLocationType(locationType.val))}
                                    />
                                </FlexboxItem>
                                <FlexboxItem className="ChangeLocation_dropDown_item">
                                    <Search
                                        disabled={locationType.val !== selectedLocationType}
                                        searchList={getDropdownItems(locationType.val)}
                                        onSearch={handleSearch}
                                        onSuggestionClick={handleOnSuggetionClick}
                                        isLoading={dropdownSearchLoading}
                                        disableOnBlurSearchInput
                                        controlledValue={getControlledInputValue(locationType.val)}
                                        isNoDataMessageHidden={false}
                                    />
                                </FlexboxItem>
                            </FlexboxContainer>
                        ))}
                    </FlexboxContainer>
                </FlexboxItem>
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default ChangeLocationModal;
