import React, { useEffect } from 'react';
import { Table, FlexboxContainer } from 'ui-components';

import { IApiToken } from '../../../../@types/customers';
import apiTokenHeadersData from '../../constants/apiTokenHeadersData';
import { setAccordionOpenedId } from '../../../../utils/commonUtils';
import ApiTokenAccordion from '../apiTokenAccordion/ApiTokenAccordion';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getAPItoken, getAPItokenVersion, setApiTokenModalData, setSelectedApiToken } from '../../slice/index';
import { RootState } from '../../../../store';
import { accordionLevel3, customerType } from '../../../../utils/appConstants';

interface ICustomerAccordion {
    productSitesId: number;
}

const APIToken = ({ productSitesId }: ICustomerAccordion) => {
    const dispatch = useAppDispatch();
    const { customerProductionSites } = useAppSelector((state: RootState) => state.customers);

    const onHandleAccordionToggle = (data: any) => {
        const apiTokenData = {
            ...data,
            ApiTokenindex: data.key
        };
        dispatch(setApiTokenModalData(apiTokenData));
        dispatch(setSelectedApiToken(data));
        dispatch(getAPItokenVersion(data));
        const obj = JSON.stringify(setAccordionOpenedId(accordionLevel3, data.key, customerType, data));
        sessionStorage.setItem('accordionOpened', obj);
    };

    const getApiTokenTableData = (apiTokenHistoryTable: Array<IApiToken>) => {
        const accordionOpenedData = sessionStorage.getItem('accordionOpened');
        let openedAccordion: any = {};
        if (accordionOpenedData) {
            openedAccordion = JSON.parse(accordionOpenedData);
        }
        return apiTokenHistoryTable?.map((eachData: IApiToken, index: number) => {
            let isAccordionOpened = false;
            if (openedAccordion && openedAccordion[customerType] && openedAccordion[customerType][accordionLevel3]) {
                const openedData = openedAccordion[customerType][accordionLevel3].map((id: any) => id.toString());
                if (openedData.includes(index.toString())) {
                    isAccordionOpened = true;
                }
            }
            return {
                _accordionContent: <ApiTokenAccordion apiTokenAccordianData={eachData} index={index} />,
                key: index,
                name: eachData.name,
                audience: eachData.audience,
                subject: eachData.subject,
                isAccordionOpen: isAccordionOpened,
                idp: eachData.idp
            };
        });
    };

    return (
        <Table
            className="apiToken"
            accordion
            isManualSortEnabled
            headerData={apiTokenHeadersData}
            tableHeaderClassNameForStyle="Table_inner_header"
            isSingleSelectable={false}
            rowsData={getApiTokenTableData(customerProductionSites?.[productSitesId]?.productionSites?.apiToken)}
            tableHeaderRowStyles={{
                position: 'sticky',
                top: 0,
                zIndex: 1
            }}
            isPrintable={false}
            isMultiSelectable={false}
            tableConfiguration={{
                pagination: false,
                pageOptions: [0],
                showTableSettings: false,
                showSearch: false,
                sortAllRows: false,
                showRowCount: false
            }}
            pagination={false}
            tableId="apitokenHistoryTable"
            onAccordionToggle={onHandleAccordionToggle}
        />
    );
};

export default APIToken;
