import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import OverviewScreen from '../../../../components/overviewScreen/OverviewScreen';
import { associatedProductsHeaderData } from '../../constants/HeaderData';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getAssociatedProductionSites } from '../../slice/AssociatedProducts';
import { RootState } from '../../../../store';
import { IAssociatedProductionSites, IProductsAssociatedInitialState } from '../../../../@types/products';
import { getMultiTermText } from '../../../../utils/commonUtils';
import { setBreadCrumbsData } from '../../../../slice/BreadCrumbs';

const AssociatedProductionSites = ({ globalContext }: any) => {
    const { productVersionId } = useParams();
    const dispatch = useAppDispatch();
    const { setLoader } = globalContext;
    const { isLoading, associatedProductionSitesData } = useAppSelector(
        (state: RootState) => state.associatedProductionSites
    ) as IProductsAssociatedInitialState;

    useEffect(() => {
        dispatch(getAssociatedProductionSites(+productVersionId!));
        dispatch(
            setBreadCrumbsData([
                {
                    label: getMultiTermText({ id: '99034157', text: 'Sales products' }),
                    key: 1,
                    navigateTo: `/sales-products`
                },
                {
                    label: (
                        <>
                            {getMultiTermText({ id: '99032442', text: 'Associated production sites' })} : {productVersionId}
                        </>
                    ),
                    key: 2,
                    navigateTo: `/sales-products/associatedProductionSites/${productVersionId}`
                }
            ])
        );
        return () => {
            dispatch(setBreadCrumbsData([]));
        };
    }, []);

    useEffect(() => {
        setLoader({
            loading: isLoading
        });
    }, [isLoading]);

    let associatedProductionSites: any = [];

    if (associatedProductionSitesData?.length) {
        associatedProductionSites = [...associatedProductionSitesData];
    }
    const associatedProdcutionSitePerVersion = associatedProductionSites?.map(
        (eachAssociatedProductionsSites: IAssociatedProductionSites) => ({
            _key: eachAssociatedProductionsSites.productionSiteId,
            id: eachAssociatedProductionsSites.productionSiteId,
            productionSiteName: eachAssociatedProductionsSites.productionSiteName,
            customerName: eachAssociatedProductionsSites.customerName,
            enabled:
                eachAssociatedProductionsSites.enabled && eachAssociatedProductionsSites.productionSiteEnabled
                    ? getMultiTermText({ id: '99001613', text: 'Yes' })
                    : getMultiTermText({ id: '99000054', text: 'No' })
        })
    );

    return (
        <OverviewScreen
            pageHeaderMultiTermId="99032442"
            pageHeaderMultiTermText="Associated production sites"
            tableClassName="Associated_products_table"
            headerData={associatedProductsHeaderData}
            enableAccordion={false}
            rowsData={associatedProdcutionSitePerVersion}
            tableId="associatedProductsTable"
        />
    );
};

export default AssociatedProductionSites;
