import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SubOptionListComponent } from 'ui-components';
import { getMultiTermText } from '../../../../utils/commonUtils';

interface ITableOptions {
    onClickGenerateUpdate: () => void;
    onClickDownload: () => void;
    enableGenerateUpdate: boolean;
    enableDownloadUpdate: boolean;
}

const HardlockProductionSitesTableOptions = ({
    onClickGenerateUpdate,
    onClickDownload,
    enableGenerateUpdate,
    enableDownloadUpdate
}: ITableOptions) => {
    const optionsList = {
        list: [
            {
                title: getMultiTermText({ text: 'Generate update', id: '99034273' }),
                click: onClickGenerateUpdate,
                key: 'generateUpdate'
            },
            {
                title: getMultiTermText({ text: 'Download update', id: '99034274' }),
                click: onClickDownload,
                key: 'downloadUpdate'
            }
        ]
    };

    const productOnlySelected = {
        list: [
            {
                title: getMultiTermText({ text: 'Download update', id: '99034274' }),
                click: onClickDownload,
                key: 'downloadUpdate'
            }
        ]
    };

    const doesNothaveV2CList = {
        list: [
            {
                title: getMultiTermText({ text: 'Generate update', id: '99034273' }),
                click: onClickGenerateUpdate,
                key: 'generateUpdate'
            }
        ]
    };

    const conditions = [
        {
            condition: enableGenerateUpdate && enableDownloadUpdate,
            list: optionsList
        },
        {
            condition: enableGenerateUpdate,
            list: doesNothaveV2CList
        },
        {
            condition: enableDownloadUpdate,
            list: productOnlySelected
        }
    ];


    const list: any = conditions.find((condition) => condition.condition);

    return <SubOptionListComponent optionList={list.list} />;
};

export default HardlockProductionSitesTableOptions;
