import { flattenMultiList, getMultiTermText } from '../../../utils/commonUtils';
import { IProductionSitesData, ICustomerProducts, ILicenseSettings } from '../../../@types/customers';

export const getSelectedFeatures = (selectedCustomerProductionSites: IProductionSitesData): number[] | false => {
    const selectedFeatures =
        selectedCustomerProductionSites &&
        selectedCustomerProductionSites?.customerFeatures?.length > 0 &&
        selectedCustomerProductionSites?.customerFeatures
            .filter((feature) => feature.featureId > 0)
            .map((feature) => feature.featureId);
    return selectedFeatures;
};

export const getSelectedProducts = (selectedCustomerProductionSites: IProductionSitesData): number[] | false => {
    const selectedProducts =
        selectedCustomerProductionSites &&
        selectedCustomerProductionSites?.customerProducts?.length > 0 &&
        selectedCustomerProductionSites?.customerProducts
            .filter((product) => product.productVersionId > 0)
            .map((product) => product.productVersionId);
    return selectedProducts;
};

export const isAnyOfSubLevelChecked = (array: any) => {
    const flattenSubLevelArray = flattenMultiList(array);
    return flattenSubLevelArray?.filter((eachData) => eachData.checked)?.length > 0;
};

export const getProductionSitesWithLicenseSettings = (
    responseData: any,
    licenseSettingsParam: ILicenseSettings | null
) => {
    const productionSites = {
        ...responseData,
        licenseSettings: licenseSettingsParam
    };
    const productionSitesWithLicenseSettings = responseData?.licenseSettings ? responseData : productionSites;
    return productionSitesWithLicenseSettings;
};

function b64toBlob(b64Data: any, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export default function downloadFile(fileName: string, data: string, type: string) {
    const fileData = data;
    const blob =
        type && type === 'xml'
            ? new Blob([data], { type: 'application/xml' })
            : b64toBlob(fileData, 'application/octet-stream');
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
