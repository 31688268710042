import React, { useEffect, useState } from 'react';
import { dateTimeFormatConversion } from 'lisecutilityfunctions/lib/commonfunction';
import {
    deleteProduct,
    getProducts,
    initialProductVersionData,
    postProduct,
    postProductVersion,
    putProduct,
    putProductVersion,
    setPostProductVersionData,
    setProductModalData,
    setProductModalType,
    setProductVersionModalType,
    clearPostProductResponseData
} from './slice/index';
import { modalType, setAccordionOpenedId, compareObjects } from '../../utils/commonUtils';
import { IAccordionObject } from '../../@types/common';
import { IProductsData } from '../../@types/products';

import { useAppDispatch, useAppSelector } from '../../hooks';
import OverviewScreen from '../../components/overviewScreen/OverviewScreen';
import { productsHeaderData } from './constants/HeaderData';
import { accordionLevel1, productType } from '../../utils/appConstants';
import ProductAccordion from './components/ProductAccordion/ProductAccordion';
import ActionModal from '../../components/actionModal/ActionModal';
import ProductModal from './components/ProductModal/ProductModal';
import DeleteModalContent from '../../components/deleteModalContent/DeleteModalContent';
import ProductTableOptions from './components/ProductTableOptions/ProductTableOptions';
import ProductVersionModal from './components/ProductVersionModal/ProductVersionModal';

const Products = ({ globalContext }: any) => {
    const dispatch = useAppDispatch();
    const {
        isLoading,
        productsData,
        productModalType,
        currentProductVersionEnableStatus,
        editVersionProductId,
        productsModalData,
        postProductVersionData,
        deleteProductId,
        productVersionModalType,
        postProductResponseData
    } = useAppSelector((state) => state.products);

    const { setLoader } = globalContext;
    const [inRowPatchData, setInRowPatchData] = useState<any>({});
    const [showEmptyPatchWarningMessage, setShowEmptyPatchWarningMessage] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getProducts());
    }, []);

    const onCloseProductModal = () => {
        dispatch(setProductModalType(modalType.CLOSED));
    };

    const onClickProductModalAction = () => {
        switch (productModalType) {
            case modalType.ADD: {
                dispatch(postProduct(productsModalData));
                break;
            }
            case modalType.EDIT: {
                dispatch(putProduct({ body: productsModalData, productId: productsModalData?.id }));
                break;
            }
            case modalType.DELETE: {
                dispatch(deleteProduct(deleteProductId));
                break;
            }
            default:
                break;
        }
    };

    const onCloseProductVersionModal = () => {
        dispatch(setPostProductVersionData(initialProductVersionData));
        dispatch(setProductVersionModalType(modalType.CLOSED));
    };

    const onClickProductVersionModalAction = () => {
        switch (productVersionModalType) {
            case modalType.ADD: {
                const filteredData = {
                    ...postProductVersionData,
                    enabled: currentProductVersionEnableStatus,
                    productId: editVersionProductId
                };
                dispatch(postProductVersion(filteredData));
                break;
            }
            case modalType.EDIT: {
                const filteredData = {
                    ...postProductVersionData,
                    enabled: currentProductVersionEnableStatus,
                    productId: editVersionProductId
                };
                dispatch(putProductVersion({ body: filteredData, productVersionId: postProductVersionData.id! }));
                break;
            }
            default:
                break;
        }
    };

    const onHandlePatchRowData = () => {
        const patchData = productsData.find((eachData) => eachData.id === inRowPatchData.id);
        if (inRowPatchData?.name === '') {
            setShowEmptyPatchWarningMessage(true);
            return;
        }
        const patchPreviousData = {
            id: patchData?.id,
            name: patchData?.name,
            description: patchData?.description,
            sapId: patchData?.sapId,
            modified: patchData?.modified,
            enabled: patchData?.enabled ? 1 : 2,
            machineDependent: patchData?.machineDependent,
            productVersions: patchData?.productVersions[0]?.version,
            isHardlockControlled: patchData?.isHardlockControlled
            // hardlockProductId: patchData?.hardlockProductId
        };
        const patchUpdatedData = {
            id: inRowPatchData?.id,
            name: inRowPatchData?.name,
            description: inRowPatchData?.description,
            sapId: inRowPatchData?.sapId,
            modified: patchData?.modified,
            enabled: inRowPatchData?.enabled === 1,
            machineDependent: inRowPatchData?.machineDependent,
            productVersions: inRowPatchData?.productVersions,
            isHardlockControlled: inRowPatchData.isHardlockControlled
            // hardlockProductId: inRowPatchData.hardlockProductId
        };

        let isEqual = true;

        isEqual = compareObjects(patchPreviousData, inRowPatchData, [
            '_accordionContent',
            '_key',
            '_option',
            'isSelected',
            'isAccordionOpen',
            'modified',
            'productVersions'
        ]);

        if (inRowPatchData.name && !isEqual && patchData?.id) {
            dispatch(putProduct({ body: patchUpdatedData, productId: patchData?.id }));
        }
    };

    useEffect(() => {
        onHandlePatchRowData();
    }, [inRowPatchData]);

    useEffect(() => {
        setLoader({
            loading: isLoading
        });
    }, [isLoading]);

    const onAddProduct = () => {
        dispatch(setProductModalType(modalType.ADD));
    };

    const onClosePatchWarningMessage = () => {
        setShowEmptyPatchWarningMessage(false);
        dispatch(getProducts());
    };

    const getProductsTableData = (allProductsData: Array<IProductsData>) => {
        const accordionOpenedData = sessionStorage.getItem('accordionOpened');
        let openedAccordion: IAccordionObject = {};
        if (accordionOpenedData) {
            openedAccordion = JSON.parse(accordionOpenedData);
        }
        return allProductsData?.map((eachData) => {
            let isAccordionOpened = false;
            if (openedAccordion && openedAccordion[productType] && openedAccordion[productType][accordionLevel1]) {
                const openedData = openedAccordion[productType][accordionLevel1].map((id) => id.toString());
                if (openedData.includes(eachData.id.toString())) {
                    isAccordionOpened = true;
                }
            }
            return {
                _key: eachData.id,
                _accordionContent: <ProductAccordion productsData={eachData} />,
                isHardlockControlled: eachData?.isHardlockControlled,
                id: eachData.id,
                description: eachData.description,
                name: eachData.name,
                sapId: eachData.sapId,
                enabled: eachData.enabled ? 1 : 2,
                productVersions: eachData.productVersions
                    ? dateTimeFormatConversion(eachData.productVersions[0]?.version, 'datetime')
                    : '',
                isAccordionOpen: isAccordionOpened,
                _option: (
                    <ProductTableOptions
                        onClickEdit={() => {
                            dispatch(setProductModalType(modalType.EDIT));
                            dispatch(setProductModalData(eachData));
                        }}
                    />
                )
            };
        });
    };

    const onHandleAccordionToggle = (data: any) => {
        const obj = JSON.stringify(setAccordionOpenedId(accordionLevel1, data.id, productType, data));
        dispatch(clearPostProductResponseData({}))
        sessionStorage.setItem('accordionOpened', obj);
    };

    useEffect(()=>{
        if (postProductResponseData && postProductResponseData.id )
        onHandleAccordionToggle(postProductResponseData)
    },[postProductResponseData])

    return (
        <>
            <OverviewScreen
                pageHeaderMultiTermId="99034157"
                pageHeaderMultiTermText="Sales products"
                tableClassName="products_table"
                headerData={productsHeaderData}
                rowsData={getProductsTableData(productsData)}
                initialColumnSortData={[
                    {
                        order: 'ascending',
                        columnKey: 'name'
                    }
                ]}
                tableId="ProductsTable"
                addButtonMultiTermId="99032583"
                addButtonMultiTermText="Add Product"
                addButtonOnClick={onAddProduct}
                onPatchRowData={(rowData) => setInRowPatchData(rowData)}
                onAccordionToggle={onHandleAccordionToggle}
                showEmptyPatchWarningMessage={showEmptyPatchWarningMessage}
                onClosePatchWarningMessage={onClosePatchWarningMessage}
                onScrollToId={postProductResponseData?.id}

            />
            {productModalType !== modalType.CLOSED && (
                <ActionModal
                    onClickAction={onClickProductModalAction}
                    onClickCancelClose={onCloseProductModal}
                    actionType={productModalType}
                    modalColumnNumber={2}
                    ModalContent={
                        productModalType !== modalType.DELETE ? (
                            <ProductModal />
                        ) : (
                            <DeleteModalContent isDeletePermanentMessage={false} />
                        )
                    }
                    actionDisabled={
                        productModalType !== modalType.DELETE
                            ? !productsModalData?.id || !productsModalData.name
                            : false
                    }
                />
            )}
            {productVersionModalType !== modalType.CLOSED && (
                <ActionModal
                    onClickAction={onClickProductVersionModalAction}
                    onClickCancelClose={onCloseProductVersionModal}
                    actionType={productVersionModalType}
                    modalColumnNumber={1}
                    ModalContent={<ProductVersionModal />}
                    actionDisabled={
                        !(
                            postProductVersionData &&
                            postProductVersionData.productFeatures &&
                            postProductVersionData.productFeatures.length > 0
                        )
                    }
                />
            )}
        </>
    );
};

export default Products;
