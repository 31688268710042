import { getMultiTermText, getLabelOptions } from '../../../utils/commonUtils';

export default [
    {
        _key: 'created',
        label: getMultiTermText({ id: '99001796', text: 'Creation date' }),
        searching: true,
        sorting: true,
        width: '15%',
        isEditable: false,
    },
    {
        _key: 'id',
        label: getMultiTermText({ id: '99008287', text: 'ID#' }),
        searching: true,
        sorting: true,
        width: '25%',
        isEditable: false,
    },
    {
        _key: 'expiryDate',
        label: getMultiTermText({ id: '99018614', text: 'Expiry date' }),
        searching: true,
        sorting: true,
        type: 'string',
        width: '25%',
        isEditable: false,
    },
    {
        _key: 'status',
        label: getMultiTermText({ id: '3000144', text: 'Status' }),
        searching: false,
        sorting: true,
        width: '15%',
        isEditable: true,
        _component: 'label',
        filterOptions: [
            {
                id: 1,
                name: getMultiTermText({ id: '99000784', text: 'Enabled' })
            },
            {
                id: 2,
                name: getMultiTermText({ id: '99000809', text: 'Disabled' })
            }
        ],
        labelOptions: [
            {
                id: 1,
                text: getLabelOptions({ id: '99000784', text: 'Enabled', className: 'Status_green' })
            },
            {
                id: 2,
                text: getLabelOptions({ id: '99000809', text: 'Disabled', className: 'Status_gray' })
            }
        ]
    }
];
