/* eslint-disable react/require-default-props */
import React, { useEffect, useState, FC } from 'react';
import { FlexboxItem, Dropdown } from 'ui-components';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getCustomers } from '../../slice';
import { IDropdownData } from '../../../../@types/common';
import { ICustomersData } from '../../../../@types/customers';
import { modalType } from '../../../../utils/commonUtils';

export interface ICustomerProdSiteData {
    customerId?: number;
    productionSiteId?: number;
}

interface ICustomerProductionSiteMapInputProps {
    onProductionSiteChange: (customerProdSiteData: ICustomerProdSiteData) => void;
    selectedValue?: ICustomerProdSiteData;
}

const CustomerProductionSiteMapInput: FC<ICustomerProductionSiteMapInputProps> = ({
    onProductionSiteChange,
    selectedValue
}) => {
    const { customersData } = useAppSelector((state) => state.customers);
    const { trialPeriodModalType } = useAppSelector((state) => state.trialPeriods);
    const dispatch = useAppDispatch();
    const [dropdownItems, setDropdownItems] = useState<Array<IDropdownData>>([]);
    useEffect(() => {
        dispatch(getCustomers());
    }, []);

    const generateCustomersDropdown = (customers: Array<ICustomersData>) =>
        customers.map<IDropdownData>((customer) => ({
            id: customer.id,
            name: customer.name,
            checked: false,
            disabled: false
        }));

    useEffect(() => {
        setDropdownItems(generateCustomersDropdown(customersData));
    }, [customersData]);

    const productionSites =
        customersData
            .find((i) => i.id === selectedValue?.customerId)
            ?.productionSites?.map<IDropdownData>((prodSite) => ({
                id: prodSite.id,
                checked: false,
                disabled: false,
                name: prodSite.name
            })) ?? [];

    return (
        <>
            <FlexboxItem className="Full_width">
                <Dropdown
                    changeSelected={(selected: Array<IDropdownData>) => {
                        onProductionSiteChange({
                            customerId: selected[0].id
                        });
                    }}
                    className="Customer_input"
                    dropdownItems={dropdownItems}
                    required
                    title={<LiMultiterm textId="9000326" textVal="Customer" />}
                    singleSelectedValue={dropdownItems.filter((i) => i.id === selectedValue?.customerId)}
                    errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                    disabled={trialPeriodModalType === modalType.EDIT}
                />
            </FlexboxItem>
            <FlexboxItem className="Full_width">
                <Dropdown
                    changeSelected={(selected: Array<IDropdownData>) => {
                        onProductionSiteChange({
                            productionSiteId: selected[0].id
                        });
                    }}
                    className="ProductionSite_input"
                    dropdownItems={productionSites}
                    required
                    title={<LiMultiterm textId="99025706" textVal="Production site" />}
                    singleSelectedValue={productionSites.filter((i) => i.id === selectedValue?.productionSiteId)}
                    errorText={<LiMultiterm textId="99033565" textVal="This is a mandatory field" />}
                    disabled={trialPeriodModalType === modalType.EDIT}
                />
            </FlexboxItem>
        </>
    );
};

export default CustomerProductionSiteMapInput;
