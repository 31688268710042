/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import { dateTimeFormatConversion } from 'lisecutilityfunctions/lib/commonfunction';
import { FlexboxContainer, FlexboxItem, Typography, TextField, MultiList, Scrollable, DatePicker } from 'ui-components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { flattenMultiList, getDataForDependentFeatures, getToday } from '../../../../utils/commonUtils';
import modalType from '../../../../constants/appConstants';
import { IMultiListArrayType } from '../../../../@types/customers';
import { getDependentFeatures } from '../../../Customers/slice';
import { setTrialPeriodsModalData } from '../../slice';
import { IFeaturesData } from '../../../../@types/features';
import CustomerProductionSiteMapInput, {
    ICustomerProdSiteData
} from '../../../Customers/components/customerProductionSiteMapping/CustomerProductionSiteMapInput';
import { ITrialPeriodsModalData } from '../../../../@types/trialPeriods';

const TrialPeriodsModal = () => {
    const { trialPeriodModalType, trialPeriodsModalData, errorMessages } = useAppSelector(
        (state) => state.trialPeriods
    );

    const { dependentFeaturesData } = useAppSelector((state) => state.features);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!dependentFeaturesData.length) {
            dispatch(getDependentFeatures());
        }
    }, []);

    useEffect(() => {
        if (trialPeriodModalType === modalType.ADD) {
            const tempModalData: ITrialPeriodsModalData = {
                ...trialPeriodsModalData
            };
            dispatch(setTrialPeriodsModalData(tempModalData));
        }
    }, [trialPeriodsModalData?.productionSiteId]);

    const updateModalData = <T,>(value: T, name: string) => {
        let tempModalData: ITrialPeriodsModalData = { ...trialPeriodsModalData };
        if (tempModalData) {
            tempModalData = {
                ...tempModalData,
                [name]: value
            };

            dispatch(setTrialPeriodsModalData(tempModalData));
        }
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        updateModalData(event.target.value, event.target.name);

    const handleDateChange = (selectedDate: string, name: string) => updateModalData(selectedDate, name);

    const handleProductionSiteChange = (customerProdSiteData: ICustomerProdSiteData) => {
        if (customerProdSiteData.customerId) {
            updateModalData(customerProdSiteData.customerId, 'customerId');
        } else {
            updateModalData(customerProdSiteData.productionSiteId, 'productionSiteId');
        }
    };

    const onHandleMultiListChange = (data: Array<IMultiListArrayType>) => {
        const selectedFeatures = flattenMultiList(data)
            .filter((eachData) => eachData.checked)
            .map<IFeaturesData>((filteredData) => ({
                id: filteredData.id, // Only id is relevant here
                sapId: '',
                name: '',
                dependentOn: [],
                description: '',
                enabled: true
            }));
        updateModalData(selectedFeatures, 'features');
    };

    return (
        <FlexboxContainer
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="0.5rem"
            className="Full_height"
        >
            <FlexboxItem>
                <Typography type="H1">
                    {trialPeriodModalType === modalType.ADD ? (
                        <LiMultiterm textId="99032448" textVal="Add trial period" />
                    ) : (
                        <LiMultiterm textId="99032453" textVal="Edit trial period" />
                    )}
                </Typography>
            </FlexboxItem>
            <FlexboxItem>
                <FlexboxContainer alignItems="start" justifyContent="start">
                    <FlexboxItem className="Modal_each_side_wrapper">
                        <FlexboxContainer flexDirection="column" gap="1.25rem" alignItems="start">
                            <CustomerProductionSiteMapInput
                                onProductionSiteChange={handleProductionSiteChange}
                                selectedValue={{
                                    customerId: trialPeriodsModalData?.customerId,
                                    productionSiteId: trialPeriodsModalData?.productionSiteId
                                }}
                            />

                            <FlexboxItem className="Full_width">
                                <TextField
                                    title={<LiMultiterm textId="99032450" textVal="Machine serial number" />}
                                    className="MachineSerial_input"
                                    onChange={handleOnChange}
                                    name="machineSerial"
                                    value={trialPeriodsModalData?.machineSerial}
                                    disabled={trialPeriodModalType === modalType.EDIT}
                                />
                            </FlexboxItem>
                            <FlexboxItem className="Full_width">
                                <DatePicker
                                    firstDayOfWeek={0}
                                    isPreviousDatesDisabled={trialPeriodModalType === modalType.ADD}
                                    title={<LiMultiterm textId="99032451" textVal="Available from" />}
                                    onChange={(selectedDate: string) => {
                                        handleDateChange(selectedDate, 'validFrom');
                                    }}
                                    className="ValidFrom_input"
                                    userpassedDate={trialPeriodsModalData?.validFrom}
                                    errorText="Invalid date"
                                />
                                <span className="Error_Text">{errorMessages.validFrom}</span>
                            </FlexboxItem>
                            <FlexboxItem className="Full_width">
                                <DatePicker
                                    firstDayOfWeek={0}
                                    isPreviousDatesDisabled
                                    title={<LiMultiterm textId="99032452" textVal="Available to" />}
                                    onChange={(selectedDate: string) => {
                                        handleDateChange(selectedDate, 'validTo');
                                    }}
                                    className="ValidTo_input"
                                    userpassedDate={trialPeriodsModalData?.validTo}
                                />
                                <span className="Error_Text">{errorMessages.validTo}</span>
                            </FlexboxItem>
                        </FlexboxContainer>
                    </FlexboxItem>
                    <FlexboxItem className="Card_separator" />
                    <FlexboxItem className="Modal_each_side_wrapper">
                        <Scrollable>
                            {dependentFeaturesData?.length ? (
                                <MultiList
                                    onChange={(data: Array<IMultiListArrayType>) => onHandleMultiListChange(data)}
                                    title={<LiMultiterm textId="99032441" textVal="Assigned features" />}
                                    showToggleForSelectedOnly
                                    data={
                                        trialPeriodsModalData
                                            ? getDataForDependentFeatures(
                                                  dependentFeaturesData,
                                                  trialPeriodsModalData?.features?.map((feature) => feature.id) ?? [],
                                                  trialPeriodsModalData?.productionSiteId ?? 0,
                                                  []
                                              )
                                            : []
                                    }
                                />
                            ) : null}
                        </Scrollable>
                    </FlexboxItem>
                </FlexboxContainer>
            </FlexboxItem>
        </FlexboxContainer>
    );
};

export default TrialPeriodsModal;
